/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Search from '@ingka/search';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/search/dist/style.css';
import '@ingka/focus/dist/style.css';
import './RetailUnits.css';
import Button from '@ingka/button';
import IcRU from '@ingka/ssr-icon/paths/location-pin';
import {
  Checkbox, ConfigProvider, Input, Popover, Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import Pill from '@ingka/pill';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcUpArrow from '@ingka/ssr-icon/paths/chevron-up';
import { useTranslate } from '../../../../common/services/translationService.js';
import styles from './RetailUnits.module.css';
import useCallApi from '../../../../common/services/apiService.js';
import flags from '../../../../common/services/flags';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { createProj, setRU } from '../../../../store/reducers/create-proj-reducer';
import { projStatus } from '../../../../common/constants/constant';
import PillsToolbar from './pillsToolbar/PillsToolbar';

export interface RetailUnit {
  key: React.Key;
  ruName: string;
  ruCode: string;
  ruImage?: string;
  clusterCode?: string;
  clusterName?: string;
  ruVal?: number;
}

interface Cluster {
  clusterCode: string;
  clusterName: string;
  selected: boolean;
}

const RetailUnits = (props: any) => {
  const [retailUnits, setRetailUnits] = useState<RetailUnit[]>([]);
  const [retailUnitsDisplay, setRetailUnitsDisplay] = useState<RetailUnit[]>([]);
  const [searchedRu, setSearchedRu] = useState<RetailUnit[]>([]);
  const [selectedRu, setSelectedRu] = useState<RetailUnit[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isClusterOpened, setIsClusterOpened] = useState(false);
  const ruDetails:any = props?.ruDetails;
  const itemDetails: any = props?.itemDetails;
  const projDetails: any = props?.projDetails;

  const projectStore = useAppSelector((state: any) => state.createProjReducer.createProjData);
  const dispatch = useAppDispatch();
  const { get } = useCallApi();
  const selectedRuInStore: RetailUnit[] = projectStore?.ruTab?.selectedRu;
  const isDraftProj = () => (props.projtType === projStatus.draft);
  const isEditProject = props?.isEditProject;

  const columns: ColumnsType<RetailUnit> = [
    {
      key: 1,
      title: useTranslate('tab.ru.retail_unit'),
      dataIndex: 'ruName',
      width: 60,
      className: 'table-header-padding',
      render: (text: any, value: any) => (
        <span>
          <img className="table-image-left" src={flags[value.ruCode]} alt="" />
          {text}
        </span>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: useTranslate('tab.ru.cluster'),
      dataIndex: 'clusterName',
      width: 100,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  useEffect(() => {
    if (selectedRuInStore?.length !== selectedRu?.length) {
      const newSelectedKeys: React.Key[] = [];
      selectedRuInStore.forEach((item) => newSelectedKeys.push(item.key));
      setSelectedRowKeys(newSelectedKeys);
      setSelectedRu(selectedRuInStore);
    }
  }, [JSON.parse(JSON.stringify(selectedRuInStore))]);

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows?: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRu(selectedRows);
    const projData = ({ ...projectStore, ruTab: { selectedRu: selectedRows } });
    dispatch(createProj(projData));
  };

  const onAddAllClick = () => {
    const selectedKeys: React.Key[] = [...selectedRowKeys];
    const selectedData: any = [...selectedRu];
    retailUnitsDisplay.forEach((item) => {
      if (!selectedKeys.includes(item.key)) {
        selectedKeys.push(item.key);
        selectedData.push(item);
      }
    });
    onSelectChange(selectedKeys, selectedData);
  };

  const disableAddAll = () => (selectedRowKeys.length === retailUnitsDisplay.length);

  const setRetailUnit = (data: RetailUnit[]) => {
    const updatedData = data.map((item: RetailUnit, index) => ({ ...item, key: index }));
    setRetailUnits(updatedData);
    dispatch(setRU(updatedData));
    setRetailUnitsDisplay(updatedData);
    if (isDraftProj() || isEditProject === true) {
      const oldSelectedKeys: React.Key[] = [];
      const oldSelectedRu: any = [];
      updatedData?.forEach((ruList: any) => {
        ruDetails.forEach((oldRU: any) => {
          if (ruList?.ruCode === oldRU?.ruCode) {
            oldSelectedKeys.push(ruList.key);
            oldSelectedRu.push(ruList);
          }
        });
      });
      setSelectedRowKeys(oldSelectedKeys);
      setSelectedRu(oldSelectedRu);
      const projname = projDetails?.GProjectName.replace('IoS-', '');
      const projData = ({
        ...projectStore,
        detailsTab: {
          Name: projname,
          StartDate: projDetails?.projectStartDate,
          EndDate: projDetails?.projectEndDate || null,
          Message: projDetails?.message || null,
          AdditionalMsg: '',
          ProjectId: projDetails?.GProjectId,
        },
        itemsTab: { selectedItems: itemDetails },
        ruTab: { selectedRu: oldSelectedRu },
      });
      dispatch(createProj(projData));
    }
  };

  const getRetailUnits = () => {
    get('retail-units', setRetailUnit);
  };

  const getClusters = () => {
    get('clusters-info', setClusters);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
    columnTitle: useTranslate('tab.items.select'),
    columnWidth: 8,
    onChange: onSelectChange,
    scrollToFirstRowOnChange: true,
  };

  const onSearch = (e:any, data:any, filteredRu?: RetailUnit[]) => {
    setSearchKey(data.value);
    const ruList = filteredRu || retailUnitsDisplay;
    const searchFilterValues = ruList.filter((retailUnit: RetailUnit) => (
      retailUnit.ruName.toUpperCase().includes(data.value.toUpperCase())
    ));
    setRetailUnitsDisplay(searchFilterValues);
    setSearchedRu(searchFilterValues);
  };

  const onSearchClear = () => {
    setSearchKey('');
  };

  useEffect(() => {
    getClusters();
    getRetailUnits();
  }, []);

  const filterRuList = (updatedClusters?: Cluster[]) => {
    let updatedRetailUnits: any = [];
    let searchedClusterRU: any = [];
    const clusterData = updatedClusters || clusters;
    const selectedClusterCodes: string[] = [];
    clusterData.forEach((item) => {
      if (item.selected) {
        selectedClusterCodes.push(item.clusterCode);
      }
    });
    if (searchKey.length === 0 && selectedClusterCodes.length === 0) {
      setRetailUnitsDisplay(retailUnits);
    } else if (searchKey.length !== 0 && selectedClusterCodes.length === 0) {
      onSearch('', { value: searchKey }, retailUnits);
    } else if (searchKey.length !== 0 && selectedClusterCodes.length !== 0) {
      searchedClusterRU = retailUnits.filter((retailUnit: RetailUnit) => (
        retailUnit.clusterCode && selectedClusterCodes.includes(retailUnit.clusterCode)
      ));
      onSearch('', { value: searchKey }, searchedClusterRU);
    } else {
      updatedRetailUnits = (
        searchKey.length > 0 ? searchedRu : retailUnits
      ).filter((retailUnit: RetailUnit) => (
        retailUnit.clusterCode && selectedClusterCodes.includes(retailUnit.clusterCode)
      ));
      setRetailUnitsDisplay(updatedRetailUnits);
    }
  };

  const onClusterSelect = (selectedClusterCode: string) => {
    const selectedIndex = clusters.findIndex((item) => item.clusterCode === selectedClusterCode);
    const clusterData = clusters;
    clusterData[selectedIndex] = {
      ...clusters[selectedIndex], selected: !clusters[selectedIndex].selected,
    };
    setClusters(clusterData);
    filterRuList(clusterData);
  };

  useEffect(() => {
    filterRuList();
  }, [searchKey]);

  const clusterMenu = (
    <>
      {clusters.map((cluster, index) => (
        <Checkbox
          id={index.toString()}
          value={cluster.clusterCode}
          onChange={() => onClusterSelect(cluster.clusterCode)}
          defaultChecked={!!cluster.selected}
          checked={!!cluster.selected}
        >
          {cluster.clusterName}
        </Checkbox>
      ))}
    </>
  );

  return (
    <div>
      <div>
        <div className={styles.topContainer}>
          <div className={styles.leftWrapper}>
            <Popover
              overlayClassName="retailUnitsPopover"
              content={<Input.Group compact>{clusterMenu}</Input.Group>}
              trigger="click"
              placement="bottomLeft"
              onOpenChange={(val) => setIsClusterOpened(val)}
            >
              <Pill
                iconPosition="trailing"
                label="Clusters"
                small
                ssrIcon={isClusterOpened ? IcUpArrow : IcDownArrow}
              />
            </Popover>
            <Search
              className={styles.search}
              id="search"
              placeholder={useTranslate('tab.ru.search_ru')}
              onSearch={onSearch}
              onChange={(e, data) => !data.value && onSearchClear()}
              onClear={onSearchClear}
            />
          </div>
          <Button
            className={styles.btAdAll}
            href=""
            text={`${useTranslate('tab.ru.add_all')} (${retailUnitsDisplay.length})`}
            type="emphasised"
            onClick={onAddAllClick}
            disabled={disableAddAll()}
            small
          />
        </div>
        <hr className="items-demo-divider__hr-horizontal" />
        {clusters.some((cluster: any) => cluster.selected === true) ? (
          <PillsToolbar
            data={clusters.filter((cluster: any) => cluster.selected === true)}
            onRemoveFilter={(newCluster: any) => {
              onClusterSelect(newCluster.clusterCode);
            }}
            onClearAll={() => {
              clusters.forEach((cluster: any) => {
                if (cluster.selected === true) onClusterSelect(cluster.clusterCode);
              });
            }}
          />
        ) : null}
      </div>
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText="Connect Items"
          icon={IcRU}
        />
      )}
      >
        <Table
          className="table-striped-rows"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={retailUnitsDisplay}
          pagination={false}
          scroll={{ y: 'calc(100vh - 214px)' }}
          size="small"
          showHeader={retailUnitsDisplay.length > 0}
        />
      </ConfigProvider>
    </div>
  );
};

export default RetailUnits;
