/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Cards from '../../../../common/components/card/Card';
import './Drafts.css';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { ProjectType, commonColumns } from '../Dashboard';
import DashboardList from '../../../../common/components/list/List';
import { useAppSelector } from '../../../../store/hooks';

interface DraftsProps {
    draftProjects: any[],
    handleProjectClick: (value: any, type: any) => void;
}

const Drafts = ({
  draftProjects, handleProjectClick,
}: DraftsProps) => {
  const gridListFlag = useAppSelector((state) => state?.dashboardReducer?.gridListFlag);

  return (
    <div>
      {draftProjects?.length > 0
        ? gridListFlag === true
          ? (
            <div className="projectsContentWrapper">
              {(draftProjects?.map((project) => (
                <Cards
                  key={project.projectId}
                  cardKey={project.projectId}
                  name={project.projectName}
                  images={project?.itemImageUrl}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={false}
                  reduceSize
                  hfb={isNullEmptyOrUndefined(project.hfbNos) ? false : project.hfbNos}
                  items={project.itemCount}
                  suAccepted={false}
                  onClick={() => (handleProjectClick
                    && handleProjectClick(project, ProjectType.DRAFT))}
                />
              )))}
            </div>
          ) : (
            <DashboardList
              projectData={draftProjects}
              columnData={commonColumns}
              onRowClick={handleProjectClick}
            />
          ) : null}
    </div>
  );
};

Drafts.defaultProps = {
};

export default Drafts;
