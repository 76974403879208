/* eslint-disable no-unused-expressions */
import { isMobile } from 'react-device-detect';
import {
  setCurrencyList, setHfbList, setLoader, setMetricList, setRU, setToast,
} from '../../store/reducers/create-proj-reducer';
import { setProgress, setQcCount } from '../../store/reducers/dashboard-reducer';
import store from '../../store/store';
import { offlineData } from '../configs/offlineData';
import {
  fetchSelectedHfbOffline,
  fetchSelectedRuOffline,
  isIos,
  isSu,
  projectType,
  userData, userRu, userType,
} from './commonService.js';
import { useTranslate } from './translationService.js';

const saveCurrencyListStore = (data) => {
  const currencyList = data.filter((item) => item.groupName === 'Currency');
  store.dispatch(setCurrencyList(currencyList));
};

const saveCurrencyListOffline = (data) => {
  const currencyList = data.filter((item) => item.groupName === 'Currency');
  offlineData.setItem('currencyList', currencyList);
  store.dispatch(setProgress());
};

const saveFoodMetricData = (data, ruCode) => {
  const metricList = data.filter((item) => (item.groupName === 'IMP' || item.groupName === 'MET'));
  const metricUnitforRu = data.filter((item) => item.groupName === 'RUMeasUnits' && item.key === ruCode);
  offlineData.setItem('metricUnitOfRu', metricUnitforRu?.length > 1 ? 'IMP' : metricUnitforRu[0]?.value);

  const measureUnits = [];
  metricUnitforRu.forEach((sys) => measureUnits.push(sys.value));
  const filteredList = metricList.filter((item) => measureUnits.includes(item.groupName));
  offlineData.setItem('metricList', filteredList);
  localStorage.setItem('metricList', JSON.stringify(metricList));
  store.dispatch(setMetricList(metricList));
  store.dispatch(setProgress());
};

const saveRuCodeForSuUser = (data) => {
  if (isSu()) {
    const selRU = localStorage.getItem('selectedRetailUnit') ? JSON.parse(localStorage.getItem('selectedRetailUnit')).ruCode : '';
    const RuObject = data.find((item) => item.groupName === 'SUCode' && item.key === selRU);
    RuObject && localStorage.setItem('RuCodeForSu', RuObject.value);
    saveFoodMetricData(data, RuObject.value);
  } else {
    saveFoodMetricData(data, userRu());
  }
};

const saveEnumData = (data) => {
  offlineData.setItem('enumData', data);
  const highlightDays = data.find((item) => (item.key === 'EndDateDaysLimit')).value;
  localStorage.setItem('highlightDays', highlightDays);
  window.dispatchEvent(new Event('storage'));
  const refRuDataList = data.filter((item) => (item.groupName === 'RefRuCode'));
  localStorage.setItem('refRuList', JSON.stringify(refRuDataList));
};

export const fetchLookupEnums = (get) => {
  get(
    'enum-lookups',
    (data) => {
      saveEnumData(data);
      if (isMobile) {
        saveCurrencyListOffline(data);
      } else {
        saveCurrencyListStore(data);
      }
      saveRuCodeForSuUser(data);
    },
    () => isMobile && store.dispatch(setToast({ openToast: true, toastLabel: useTranslate('login.currency.error') })),
  );
};

export const getSellingUnitsData = (get) => {
  get('selling-units', (data) => {
    offlineData.setItem('userSellingUnits', data);
  });
};

export const fetchHFBData = (get) => {
  get('business-structure', (hfbList) => {
    if (isMobile) {
      offlineData.setItem('hfbData', hfbList);
      store.dispatch(setProgress());
    } else { store.dispatch(setHfbList(hfbList)); }
  }, () => isMobile && store.dispatch(setToast({ openToast: true, toastLabel: useTranslate('login.hfb.error') })));
};

export const fetchRetailUnits = (get) => {
  get('retail-units', (ruList) => {
    offlineData.setItem('retailUnits', ruList);
    store.dispatch(setRU(ruList));
    store.dispatch(setProgress());
  }, () => isMobile && store.dispatch(setToast({ openToast: true, toastLabel: useTranslate('login.retailUnits.error') })));
};

export const fetchMobileProjects = async (post, successCallback, type) => {
  type && store.dispatch(setLoader(true));
  const projPayload = {
    ProjectType: userType(),
    StatusNo: [],
    UserId: '',
    HfbNo: await fetchSelectedHfbOffline(),
    SortFilter: null,
    UnitCode: await fetchSelectedRuOffline(),
  };
  post('sync-projects', projPayload, (projList) => {
    type && store.dispatch(setLoader(false));
    offlineData.setItem('projectData', projList?.dashboardSyncProjects);
    successCallback(projList?.dashboardSyncProjects);
    store.dispatch(setProgress());
  }, () => {
    type && store.dispatch(setLoader(false));
    store.dispatch(setToast({ openToast: true, toastLabel: useTranslate('login.projects.error') }));
  });
};

export const getQualityCheckCount = async (post) => {
  const qcCountPayload = {
    UserId: userData()?.userId,
    UnitCode: isIos() ? null : await fetchSelectedRuOffline(),
    ProjectType: projectType(),
    CurrentUserId: userData()?.userId,
  };
  post('qualitycheck-count', qcCountPayload, (res) => {
    store.dispatch(setQcCount(res?.projectCount || 0));
  });
};

export const fetchCompetitorData = async (post) => {
  const competitorsPayload = {
    UnitCode: isIos() ? null : await fetchSelectedRuOffline(),
    ProjectType: projectType(),
  };
  post(
    'competitors',
    competitorsPayload,
    (data) => offlineData.setItem('competitorsList', data.competitorInfoViewDto),
  );
};
