/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import InputField from '@ingka/input-field';
import React, { useEffect, useRef } from 'react';

interface QuantityInputProps {
    id: string;
    label: string;
    value: number | undefined;
    className: string;
    charLimit: number;
    disabled?: boolean;
    pieceFocus?: boolean;
    zeroValidate?: boolean;
    unitSuffix?: string;
    onChange: (value: number) => void;
    [x:string]: any;
}

const QuantityInput = ({
  id,
  label,
  value,
  className,
  charLimit,
  disabled,
  pieceFocus,
  zeroValidate,
  unitSuffix,
  onChange,
  ...rest
}: QuantityInputProps) => {
  const inputRef: any = useRef(null);

  useEffect(() => {
    if (pieceFocus === true) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 600);
    }
  }, [pieceFocus]);

  const onValueChange = (e: any) => {
    const val = e.target.value;
    const leftSide = val.split('.')[0];
    if (leftSide.length <= charLimit && (!val || val.match(/^\d{0,}(\.\d{0,3})?$/))) {
      onChange(zeroValidate ? (val > 0) ? val : '' : val);
    }
  };

  return (
    <InputField
      className={className}
      id={id}
      label={label}
      autoComplete="off"
      type="number"
      value={value}
      onChange={onValueChange}
      disabled={disabled}
      ref={inputRef}
      suffixLabel={unitSuffix}
      {...rest}
    />
  );
};

QuantityInput.defaultProps = {
  disabled: false,
  pieceFocus: false,
  zeroValidate: false,
  unitSuffix: false,
};

export default QuantityInput;
