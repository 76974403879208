/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Text from '@ingka/text';
import './CsvInvalidSidePanel.css';
import { Drawer } from 'antd';
import Button from '@ingka/button';
import IcCross from '@ingka/ssr-icon/paths/cross';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { useTranslate } from '../../../../../common/services/translationService.js';

const CsvInvalidSidePanel = ({
  isVisible,
  invalidList,
  csvDuplicateItems,
  csvInvalidFormats,
  onClose,
}: {
    isVisible: boolean,
    invalidList: any[],
    csvDuplicateItems: any[],
    csvInvalidFormats: any[],
    onClose: () => void,
}) => {
  const titleText = useTranslate('csv.sidePanel.title');
  return (
    <div className="csvInavidPanelWrapper">
      <Drawer
        title={titleText}
        placement="right"
        onClose={onClose}
        open={isVisible}
        forceRender
        closeIcon={<Button type="tertiary" small iconOnly ssrIcon={IcCross} />}
      >
        <Accordion collapsible={false}>
          <AccordionItem
            className="csvAccordionItem"
            caption=""
            id="csvItemNotFound"
            subtitle=""
            disabled={invalidList.length <= 0}
            title={`Item not found (${invalidList.length > 0 ? invalidList.length : 0})`}
          >
            {invalidList.map((invalidItem) => (
              <Text>{invalidItem}</Text>
            ))}
          </AccordionItem>
          <AccordionItem
            className="csvAccordionItem"
            caption=""
            id="csvInvalidFormats"
            subtitle=""
            disabled={csvInvalidFormats.length <= 0}
            title={`Invalid item no. (${csvInvalidFormats.length > 0 ? csvInvalidFormats.length : 0})`}
          >
            {csvInvalidFormats.map((invalidItem) => (
              <Text>{invalidItem}</Text>
            ))}
          </AccordionItem>
          <AccordionItem
            className="csvAccordionItem"
            caption=""
            id="csvDuplicateItems"
            subtitle=""
            disabled={csvDuplicateItems.length <= 0}
            title={`Duplicates in excel file (${csvDuplicateItems.length > 0 ? csvDuplicateItems.length : 0})`}
          >
            {csvDuplicateItems.map((invalidItem) => (
              <Text>{invalidItem}</Text>
            ))}
          </AccordionItem>
        </Accordion>
      </Drawer>
    </div>
  );
};

export default CsvInvalidSidePanel;
