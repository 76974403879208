/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import Button from '@ingka/button';
import Modal, {
  ModalBody, ModalFooter, ModalHeader, Sheets,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/accordion/dist/style.css';
import IcTrashCan from '@ingka/ssr-icon/paths/trash-can';
import IcPlus from '@ingka/ssr-icon/paths/plus';
import SSRIcon from '@ingka/ssr-icon';
import Accordion, { AccordionItem } from '@ingka/accordion';
import React, { useState } from 'react';
import IcPublish from '@ingka/ssr-icon/paths/arrow-up-from-base';
import IcSave from '@ingka/ssr-icon/paths/document-checkmark';
import Text from '@ingka/text';
import { useLocation } from 'react-router-dom';
import useCallApi from '../../../../common/services/apiService.js';
import styles from './SummaryPanel.module.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Item } from '../items/Items';
import {
  createProj, prevItems, prevProj, setPreviewOpen,
} from '../../../../store/reducers/create-proj-reducer';
import flags from '../../../../common/services/flags';
import { RetailUnit } from '../retailUnitsTab/RetailUnits';
import RemoveAllPromptModal from './RemoveAllPromptModal';
import { saveDraftProject, saveRuProject } from '../topNavigation/TopNavigation';
import { projStatus } from '../../../../common/constants/constant';
import { SellingUnit } from '../sellingUnitsTab/SellingUnits.js';
import { isIos, isRu } from '../../../../common/services/commonService.js';

const DefaultSection = (
  { sectionTitle, sectionMessage }:{ sectionTitle: string, sectionMessage: string },
) => (
  <>
    <hr className={`demo-divider__hr-horizontal ${styles.dividerDefault}`} />
    <p className={styles.messageTitle}>{sectionTitle}</p>
    <p className={styles.message}>{sectionMessage}</p>
  </>
);

export enum RemoveItem {
  ITEM = 'ITEM',
  RU = 'RU',
  SU = 'SU',
  RUITEM = 'RUITEM',
}

const SummaryPanel = ({
  isVisible, onClose, projDetails, isEditProject, isCopy,
}:
  {isVisible: boolean, onClose: () => void, projDetails: any, isEditProject: any, isCopy:boolean}) => {
  const projectStore = useAppSelector((state) => state?.createProjReducer?.createProjData);
  const prevProjectStore = useAppSelector((state) => state.createProjReducer.prevProjData);
  const saveValid = useAppSelector((state) => state?.createProjReducer?.saveValid);
  const submitValid = useAppSelector((state) => state?.createProjReducer?.submitValid);
  const previewOpen = useAppSelector((state) => state.createProjReducer.previewOpen);
  const detailsTab = projectStore?.detailsTab;
  const selectedItems: Item[] = projectStore?.itemsTab?.selectedItems;
  const ruSelectedItems: Item[] = projectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isRuItem === 1);
  const iosSelectedItems: Item[] = prevProjectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isRuItem !== 1);
  const prevSelectedItems: Item[] = prevProjectStore?.itemsTab?.selectedItems;
  const activeAcceptedItems = projectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isActive === true);
  const selectedRu: RetailUnit[] = projectStore?.ruTab?.selectedRu;
  const selectedSu: SellingUnit[] = projectStore?.suTab?.selectedSu;
  const [isRemovePromptOpen, setIsRemovePromptOpen] = useState(false);
  const [removeItemCount, setRemoveItemCount] = useState(0);
  const [removeType, setRemoveType] = useState<RemoveItem | undefined>();
  const dispatch = useAppDispatch();
  const summaryPanelTitle = useTranslate('create.proj.summaryPanel');
  const messageTitle = useTranslate('create.proj.messageTitle');
  const setMessage = useTranslate('create.proj.setMessage');
  const additionalMsgTitle = useTranslate('create.proj.additionalMsgTitle');
  const setAdditionalMsg = useTranslate('create.proj.setAdditionalMsg');
  const add = useTranslate('create.proj.add');
  const remove = useTranslate('create.proj.remove');
  const removeAll = useTranslate('create.proj.removeAll');
  const itemsTitle = useTranslate('create.proj.itemsTitle');
  const itemsMessage = useTranslate('create.proj.itemsMessage');
  const retailTitle = useTranslate('create.proj.retailTitle');
  const retailMessage = useTranslate('create.proj.retailMessage');
  const sellingTitle = useTranslate('create.proj.sellingTitle');
  const sellingMessage = useTranslate('create.proj.sellingMessage');
  const { post } = useCallApi();
  const location: any = useLocation();

  const projtType = location?.state?.type;
  const isAcceptedProj = () => (projtType === projStatus.accepted);

  const onRemoveItem = (removedItem: Item) => {
    let projData;
    const updatedItems = selectedItems.filter((item) => item !== removedItem);
    if (isRu()) {
      let updatedSuItemList = JSON.parse(JSON.stringify(projectStore.selectedSuItems));
      let updatedSuList = JSON.parse(JSON.stringify(projectStore.suTab.selectedSu));
      updatedSuItemList.forEach((o :any) => {
        o.SuItemList = o.SuItemList.filter((s: any) => s.ItemNo !== removedItem.itemNo);
      });
      updatedSuItemList = updatedSuItemList?.filter((s: any) => s?.SuItemList?.length !== 0);
      updatedSuList = updatedSuList?.filter((item: any) => updatedSuItemList?.some((itemKey: any) => itemKey?.SuCode === item?.unitCode));
      projData = ({
        ...projectStore,
        itemsTab: { selectedItems: updatedItems },
        selectedSuItems: updatedSuItemList,
        suTab: { selectedSu: updatedSuList },
      });
    } else projData = ({ ...projectStore, itemsTab: { selectedItems: updatedItems } });
    dispatch(prevItems(updatedItems));
    dispatch(createProj(projData));
  };

  const onRemoveAcceptedItem = (removedItem: any, index: any, isText: any) => {
    const titleStyle: any = document?.getElementById(`itemTitle${index}`);
    const titleContent: any = document?.getElementById(`itemContent${index}`);
    let updatedItems: any = [];
    let newItems: any = [];
    let updatedAccceptedItems: any = [];
    let updatedSuItemList = JSON.parse(JSON.stringify(projectStore.selectedSuItems));
    let updatedSuList = JSON.parse(JSON.stringify(projectStore.suTab.selectedSu));
    if (isText === 'remove') {
      titleStyle.style.textDecoration = 'line-through';
      titleContent.style.textDecoration = 'line-through';
      const itemsList = prevSelectedItems.map((obj: any) => {
        if (obj.itemNo === removedItem.itemNo) {
          return { ...obj, isActive: false };
        }
        return obj;
      });
      updatedItems = itemsList?.filter((item: any) => item.isActive === true && item.isRuItem !== 1);
      updatedAccceptedItems = itemsList;
      if (isRu()) {
        updatedSuItemList?.forEach((o :any) => {
          o.SuItemList = o?.SuItemList?.filter((s: any) => s.ItemNo !== removedItem.itemNo);
        });
        updatedSuItemList = updatedSuItemList?.filter((s: any) => s?.SuItemList?.length !== 0);
        updatedSuList = updatedSuList?.filter((item: any) => updatedSuItemList?.some((itemKey: any) => itemKey?.SuCode === item?.unitCode));
      }
    } else {
      titleStyle.style.textDecoration = 'none';
      titleContent.style.textDecoration = 'none';
      const itemsList = prevSelectedItems.map((obj: any) => {
        if (obj.itemNo === removedItem.itemNo) {
          return { ...obj, isActive: true };
        }
        return obj;
      });
      updatedAccceptedItems = itemsList;
      updatedItems = itemsList?.filter((item: any) => item.isActive === true && item.isRuItem !== 1);
    }
    newItems = selectedItems?.filter((item: any) => item.isRuItem === 1);
    const projData = ({
      ...projectStore,
      itemsTab: { selectedItems: [...updatedItems, ...newItems] },
      selectedSuItems: updatedSuItemList,
      suTab: { selectedSu: updatedSuList },
    });
    const prevProjData = ({
      ...projectStore,
      itemsTab: { selectedItems: updatedAccceptedItems },
      selectedSuItems: updatedSuItemList,
      suTab: { selectedSu: updatedSuList },
    });
    dispatch(prevItems(updatedItems));
    dispatch(createProj(projData));
    dispatch(prevProj(prevProjData));
  };

  const onRemoveRuItem = (removedItem: any) => {
    const allItems = [...selectedItems];
    const itemIndex = allItems?.findIndex((item: any) => item?.itemNo === removedItem?.itemNo);
    allItems.splice(itemIndex, 1);
    let updatedSuItemList = JSON.parse(JSON.stringify(projectStore.selectedSuItems));
    let updatedSuList = JSON.parse(JSON.stringify(projectStore.suTab.selectedSu));
    updatedSuItemList?.forEach((o :any) => {
      o.SuItemList = o?.SuItemList?.filter((s: any) => s.ItemNo !== removedItem.itemNo);
    });
    updatedSuItemList = updatedSuItemList?.filter((s: any) => s?.SuItemList?.length !== 0);
    updatedSuList = updatedSuList?.filter((item: any) => updatedSuItemList?.some((itemKey: any) => itemKey?.SuCode === item?.unitCode));
    const projData = ({
      ...projectStore,
      itemsTab: { selectedItems: allItems },
      selectedSuItems: updatedSuItemList,
      suTab: { selectedSu: updatedSuList },
    });
    dispatch(createProj(projData));
  };

  const onRemoveRU = (removedRu: RetailUnit) => {
    const updatedRu = selectedRu?.filter((ru) => ru !== removedRu);
    const projData = ({ ...projectStore, ruTab: { selectedRu: updatedRu } });
    dispatch(createProj(projData));
  };

  const onRemoveSU = (removedSu: SellingUnit) => {
    const updatedSu = selectedSu?.filter((su) => su !== removedSu);
    const projData = ({ ...projectStore, suTab: { selectedSu: updatedSu } });
    dispatch(createProj(projData));
  };

  const onRemoveAll = (type: RemoveItem | undefined) => {
    let projData = null;
    if (type === RemoveItem.ITEM) {
      projData = ({
        ...projectStore, itemsTab: { selectedItems: [] }, suTab: { selectedSu: [] }, selectedSuItems: [],
      });
    } else if (type === RemoveItem.RU) {
      projData = ({ ...projectStore, ruTab: { selectedRu: [] } });
    } else if (type === RemoveItem.RUITEM) {
      const notRuSelItem = selectedItems?.filter((item: any) => item?.isRuItem !== 1);
      if (isRu()) {
        const ruSelItem = selectedItems?.filter((item: any) => item?.isRuItem === 1);
        let updatedSuItemListNew = JSON.parse(JSON.stringify(projectStore.selectedSuItems));
        let updatedSuList = JSON.parse(JSON.stringify(projectStore.suTab.selectedSu));
        updatedSuItemListNew?.forEach((o :any) => {
          o.SuItemList = o?.SuItemList?.filter((item: any) => !ruSelItem?.some((itemKey: any) => itemKey?.itemNo === item?.ItemNo));
        });
        updatedSuItemListNew = updatedSuItemListNew?.filter((s: any) => s?.SuItemList?.length !== 0);
        updatedSuList = updatedSuList?.filter((item: any) => updatedSuItemListNew?.some((itemKey: any) => itemKey?.SuCode === item?.unitCode));
        projData = ({
          ...projectStore, itemsTab: { selectedItems: notRuSelItem }, selectedSuItems: updatedSuItemListNew, suTab: { selectedSu: updatedSuList },
        });
      } else projData = ({ ...projectStore, itemsTab: { selectedItems: notRuSelItem } });
    } else {
      projData = ({ ...projectStore, suTab: { selectedSu: [] } });
    }
    dispatch(createProj(projData));
    setIsRemovePromptOpen(false);
  };

  const openRemovePrompt = (type: RemoveItem) => {
    if (type === RemoveItem.ITEM) {
      setRemoveItemCount(selectedItems.length || 0);
    } else if (type === RemoveItem.RU) {
      setRemoveItemCount(selectedRu.length || 0);
    } else if (type === RemoveItem.RUITEM) {
      setRemoveItemCount(ruSelectedItems.length || 0);
    } else {
      setRemoveItemCount(selectedSu.length || 0);
    }
    setRemoveType(type);
    setIsRemovePromptOpen(true);
  };

  const saveProject = () => {
    if (isRu()) {
      let projDataSubmit = [];
      if (isCopy && !isIos()) {
        projDataSubmit = ({ ...projectStore, itemsTab: { selectedItems: activeAcceptedItems } });
        dispatch(createProj(projDataSubmit));
      }
      const prjSubmit = (isCopy && !isIos()) ? projDataSubmit : projectStore;
      saveRuProject(projtType, projDetails, prjSubmit, dispatch, post, 'save');
    } else saveDraftProject(projectStore, dispatch, post);
  };

  const removeItemStyles = () => {
    if (isAcceptedProj()) {
      if (activeAcceptedItems?.length > 1) {
        return styles.removeItem;
      }
      return styles.none;
    }
    if (isEditProject === true) {
      if (selectedItems?.length > 1) {
        return styles.removeItem;
      }
      return styles.none;
    }
    return styles.removeItem;
  };

  const removeIosStyles = () => {
    const activeIosItems = iosSelectedItems.filter((val) => val.isActive === true);
    if (activeIosItems?.length > 1) {
      return styles.removeItem;
    }
    return styles.none;
  };

  const removeRuStyles = () => {
    if (isEditProject === true) {
      if (selectedRu?.length > 1) {
        return styles.removeItem;
      }
      return styles.none;
    }
    return styles.removeItem;
  };

  const getItemsCount = () => {
    if ((isRu() && ((isAcceptedProj() && projDetails?.statusNo === 20) || projDetails?.hasParentProject))) {
      return iosSelectedItems?.length || 0;
    } if (isCopy && !isIos()) return activeAcceptedItems?.length || 0;
    return selectedItems?.length || 0;
  };

  return (
    <div>
      <Modal
        visible={isVisible}
        focusLockProps={{
          disabled: false,
        }}
        handleCloseBtn={onClose}
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          footer={(
            <ModalFooter>
              <div className={styles.buttonsWrapper}>
                <Button
                  className={styles.actionBtn}
                  text={useTranslate('create.proj.saveTitle')}
                  ssrIcon={IcSave}
                  small
                  disabled={!saveValid}
                  onClick={() => saveProject()}
                />
                <Button
                  className={styles.actionBtn}
                  text={useTranslate('create.proj.submitTitle')}
                  type="primary"
                  ssrIcon={IcPublish}
                  disabled={!submitValid}
                  onClick={() => dispatch(setPreviewOpen(!previewOpen))}
                  small
                />
              </div>
            </ModalFooter>
          )}
          header={
            <ModalHeader ariaCloseTxt="Close button text" title={summaryPanelTitle} />
          }
        >
          <ModalBody>
            <Text className={styles.projectTitle}>
              {detailsTab?.Name || 'Untitled'}
            </Text>
            <div className={styles.dateTitleWrapper}>
              <Text className={styles.dateTitle}>
                {useTranslate('create.proj.startdate')}
              </Text>
              <Text className={styles.dateTitle}>
                {useTranslate('create.proj.enddate')}
              </Text>
            </div>
            <div className={styles.dateWrapper}>
              <Text className={styles.date}>
                {detailsTab?.StartDate || 'Not Set'}
              </Text>
              <Text className={styles.date}>
                {detailsTab?.EndDate || 'Not Set'}
              </Text>
            </div>
            {detailsTab?.Message ? (
              <Accordion className={styles.messageAccordion}>
                <AccordionItem
                  caption=""
                  id="messageAccrordion"
                  subtitle=""
                  title={messageTitle}
                >
                  <p>
                    {detailsTab?.Message}
                  </p>
                </AccordionItem>
              </Accordion>
            ) : (
              <DefaultSection sectionTitle={messageTitle} sectionMessage={setMessage} />
            )}

            {projtType === projStatus.accepted && ((detailsTab?.AdditionalMsg) ? (
              <Accordion className={styles.messageAccordion}>
                <AccordionItem
                  caption=""
                  id="messageAccrordion"
                  subtitle=""
                  title={additionalMsgTitle}
                >
                  <p>
                    {detailsTab?.AdditionalMsg}
                  </p>
                </AccordionItem>
              </Accordion>
            ) : (
              <DefaultSection sectionTitle={additionalMsgTitle} sectionMessage={setAdditionalMsg} />
            ))}

            {selectedItems?.length > 0 ? (
              <div className={styles.accordionContainer}>
                <Accordion>
                  <AccordionItem
                    caption=""
                    id="id#selectedItems"
                    subtitle=""
                    title={`${(isRu() && projDetails?.hasParentProject) ? 'IoS added items' : 'Items'} (${getItemsCount()})`}
                  >
                    <div className={styles.accordionItems}>
                      {(isAcceptedProj() || projDetails?.hasParentProject === true) && iosSelectedItems?.length > 0 && !isCopy ? (
                        <>
                          {iosSelectedItems.map((item: any, index: number) => (
                            <div className={styles.itemWrapper}>
                              <p
                                className={item?.isActive === false ? styles.removedItemTitle
                                  : styles.itemTitle}
                                id={`itemTitle${index}`}
                              >
                                {item?.itemName}
                              </p>
                              <p
                                className={item?.isActive === false ? styles.removedItemContent
                                  : styles.itemContent}
                                id={`itemContent${index}`}
                              >
                                {`${item?.paNo}  •  ${item?.itemNo}  •  ${item?.itemType}`}
                              </p>
                              {item?.isActive === false ? (
                                <div
                                  className={styles.addItem}
                                  onClick={() => (onRemoveAcceptedItem(item, index, 'add'))}
                                  onKeyDown={() => (onRemoveAcceptedItem(item, index, 'add'))}
                                  tabIndex={index}
                                  role="button"
                                  id={`addBtn${index}`}
                                >
                                  <SSRIcon paths={IcPlus} />
                                  <p className={styles.removeText}>{add}</p>
                                </div>
                              ) : (
                                <div
                                  className={removeIosStyles()}
                                  onClick={() => (onRemoveAcceptedItem(item, index, 'remove'))}
                                  onKeyDown={() => (onRemoveAcceptedItem(item, index, 'remove'))}
                                  tabIndex={index}
                                  role="button"
                                  id={`removeBtn${index}`}
                                >
                                  <SSRIcon paths={IcTrashCan} />
                                  <p className={styles.removeText}>{remove}</p>
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )
                        : (
                          <>
                            {((isCopy && !isIos()) ? activeAcceptedItems : selectedItems).map((item: Item, index: number) => (
                              <div className={styles.itemWrapper}>
                                <p
                                  className={styles.itemTitle}
                                  id={`itemTitle${index}`}
                                >
                                  {item?.itemName}
                                </p>
                                <p
                                  className={styles.itemContent}
                                  id={`itemContent${index}`}
                                >
                                  {`${item?.paNo}  •  ${item?.itemNo}  •  ${item?.itemType}`}
                                </p>
                                <div
                                  className={removeItemStyles()}
                                  onClick={() => (onRemoveItem(item))}
                                  onKeyDown={() => (onRemoveItem(item))}
                                  tabIndex={index}
                                  role="button"
                                >
                                  <SSRIcon paths={IcTrashCan} />
                                  <p className={styles.removeText}>{remove}</p>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                    </div>
                  </AccordionItem>
                </Accordion>
                <div
                  className={(projtType === projStatus.accepted || isEditProject === true)
                    ? styles.none : styles.removeAllItems}
                  onClick={() => openRemovePrompt(RemoveItem.ITEM)}
                  onKeyDown={() => openRemovePrompt(RemoveItem.ITEM)}
                  tabIndex={-1}
                  role="button"
                >
                  <SSRIcon paths={IcTrashCan} />
                  <p className={styles.removeAllText}>{removeAll}</p>
                </div>
              </div>
            ) : (
              <DefaultSection sectionTitle={itemsTitle} sectionMessage={itemsMessage} />
            )}
            {isRu() && projDetails?.hasParentProject && ruSelectedItems?.length > 0 ? (
              <div className={styles.accordionContainer}>
                <Accordion>
                  <AccordionItem
                    caption=""
                    id="id#ruSelectedItems"
                    subtitle=""
                    title={`RU added items (${ruSelectedItems?.length})`}
                  >
                    {ruSelectedItems.map((item: any, index: number) => (
                      <div className={styles.itemWrapper}>
                        <p
                          className={item?.isActive === false ? styles.removedItemTitle
                            : styles.itemTitle}
                          id={`itemTitle${index}`}
                        >
                          {item?.itemName}
                        </p>
                        <p
                          className={item?.isActive === false ? styles.removedItemContent
                            : styles.itemContent}
                          id={`itemContent${index}`}
                        >
                          {`${item?.paNo}  •  ${item?.itemNo}  •  ${item?.itemType}`}
                        </p>
                        <div
                          className={styles.removeItem}
                          onClick={() => (onRemoveRuItem(item))}
                          onKeyDown={() => (onRemoveRuItem(item))}
                          tabIndex={index}
                          role="button"
                          id={`removeBtn${index}`}
                        >
                          <SSRIcon paths={IcTrashCan} />
                          <p className={styles.removeText}>{remove}</p>
                        </div>
                      </div>
                    ))}
                  </AccordionItem>
                </Accordion>
                <div
                  className={styles.removeAllItems}
                  onClick={() => openRemovePrompt(RemoveItem.RUITEM)}
                  onKeyDown={() => openRemovePrompt(RemoveItem.RUITEM)}
                  tabIndex={-1}
                  role="button"
                >
                  <SSRIcon paths={IcTrashCan} />
                  <p className={styles.removeAllText}>{removeAll}</p>
                </div>
              </div>
            ) : null}
            {(isIos() && projtType !== projStatus.accepted) ? selectedRu?.length > 0 ? (
              <div className={styles.accordionContainer}>
                <Accordion>
                  <AccordionItem
                    caption=""
                    id="unique-id_2"
                    subtitle=""
                    title={`${retailTitle} (${selectedRu?.length || 0})`}
                  >
                    <>
                      {selectedRu.map((ruItem: RetailUnit, index: number) => (
                        <div className={styles.itemWrapper}>
                          <span>
                            <img className="table-image-left" src={flags[ruItem.ruCode]} alt="" />
                            {ruItem.ruName}
                          </span>
                          <div
                            className={removeRuStyles()}
                            onClick={() => onRemoveRU(ruItem)}
                            onKeyDown={() => onRemoveRU(ruItem)}
                            tabIndex={index}
                            role="button"
                          >
                            <SSRIcon paths={IcTrashCan} />
                            <p className={styles.removeText}>{remove}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  </AccordionItem>
                </Accordion>
                <div
                  className={(projtType === projStatus.accepted || isEditProject === true)
                    ? styles.none : styles.removeAllItems}
                  onClick={() => openRemovePrompt(RemoveItem.RU)}
                  onKeyDown={() => openRemovePrompt(RemoveItem.RU)}
                  tabIndex={-1}
                  role="button"
                >
                  <SSRIcon paths={IcTrashCan} />
                  <p className={styles.removeAllText}>{removeAll}</p>
                </div>
              </div>
            ) : (
              <DefaultSection sectionTitle={retailTitle} sectionMessage={retailMessage} />
            ) : selectedSu?.length > 0 ? (
              <div className={styles.accordionContainer}>
                <Accordion>
                  <AccordionItem
                    caption=""
                    id="unique-id_2"
                    subtitle=""
                    title={`${sellingTitle} (${selectedSu?.length || 0})`}
                  >
                    <>
                      {selectedSu.map((suItem: SellingUnit, index: number) => (
                        <div className={styles.itemWrapper}>
                          <span>
                            {suItem.unitCode}
                            {' '}
                            -
                            {' '}
                            {suItem.unitName}
                          </span>
                          <div
                            className={styles.removeItem}
                            onClick={() => onRemoveSU(suItem)}
                            onKeyDown={() => onRemoveSU(suItem)}
                            tabIndex={index}
                            role="button"
                          >
                            <SSRIcon paths={IcTrashCan} />
                            <p className={styles.removeText}>{remove}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  </AccordionItem>
                </Accordion>
                <div
                  className={styles.removeAllItems}
                  onClick={() => openRemovePrompt(RemoveItem.SU)}
                  onKeyDown={() => openRemovePrompt(RemoveItem.SU)}
                  tabIndex={-1}
                  role="button"
                >
                  <SSRIcon paths={IcTrashCan} />
                  <p className={styles.removeAllText}>{removeAll}</p>
                </div>
              </div>
            ) : (
              <DefaultSection sectionTitle={sellingTitle} sectionMessage={sellingMessage} />
            ) }
          </ModalBody>
        </Sheets>
      </Modal>
      <RemoveAllPromptModal
        modalOpen={isRemovePromptOpen}
        numberOfItems={removeItemCount}
        itemType={removeType || RemoveItem.ITEM}
        onModalClose={() => setIsRemovePromptOpen(false)}
        onRemove={() => onRemoveAll(removeType)}
      />
    </div>
  );
};

export default SummaryPanel;
