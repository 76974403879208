/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Card, Divider } from 'antd';
import './NotificationCard.css';
import dayjs from 'dayjs';
import SSRIcon from '@ingka/ssr-icon';
import IcNoImage from '@ingka/ssr-icon/paths/image-strikethrough';
import DocumentIcon from '@ingka/ssr-icon/paths/document';
import DownloadIcon from '@ingka/ssr-icon/paths/arrow-down-to-base';
import Button from '@ingka/button';
import parse from 'html-react-parser';
import { isNullEmptyOrUndefined } from '../../services/commonService.js';
import useCallApi from '../../services/apiService.js';

interface NotifCardsProps {
  mediaUrl: string;
  title: string;
  description: string;
  date: any;
  cardKey: React.Key | null | undefined;
  mediaToken: string;
  mediaType: string;
  onClearClick: () => void;
}

const NotifCards = ({
  title, mediaUrl, description, date, cardKey, mediaToken, mediaType,
  onClearClick,
}: NotifCardsProps) => {
  const { mediaRender } = useCallApi();
  const [media, setMedia] = useState<any>();

  const getMedia = () => {
    mediaRender(mediaUrl, (res:any) => setMedia(res), () => setMedia(null), mediaToken);
  };

  useEffect(() => {
    getMedia();
  }, []);

  const downloadPdf = (pdfData: any, name:string) => {
    const a = document.createElement('a');
    a.href = pdfData;
    a.download = `Doc_${name}.pdf`;
    a.click();
    setTimeout(() => {
      a?.parentNode?.removeChild(a);
    }, 10);
  };

  return (
    <Card
      key={cardKey}
      className="notifCardStyle"
    >
      <div className="dashboardImagesContainer">
        {!isNullEmptyOrUndefined(mediaUrl) ? (
          <div className="dashboardDefaultImageWrapper">
            {!isNullEmptyOrUndefined(media) ? mediaType === 'Image' ? <img src={URL.createObjectURL(media)} alt="dbImgOne" className="dbImage" />
              : mediaType === 'Video' ? <video controls src={URL.createObjectURL(media)} className="dbImage" />
                : (
                  <div style={{ width: '100%' }}>
                    <SSRIcon className="dbImage dbIcNoImage noImage" paths={DocumentIcon} />
                    <Button
                      small
                      type="primary"
                      onClick={() => downloadPdf(URL.createObjectURL(media), title)}
                      iconOnly
                      className="pdfDownloadButton"
                      ssrIcon={DownloadIcon}
                    />
                  </div>
                )
              : <SSRIcon className="dbImage dbIcNoImage" paths={IcNoImage} />}
          </div>
        ) : null}
      </div>
      <div className="dbItemName dbCardRow">{title}</div>

      <div className="notifItemContent">{parse(description?.toString() || '')}</div>

      <div className="dbCardRow">
        <span className="notifItemTitle">
          {`${dayjs(date).format('DD MMM YYYY')}`}
        </span>
      </div>
      <div className="notifButtonWrap">
        <Button
          href=""
          text="Clear"
          type="secondary"
          className="notifClear"
          onClick={() => onClearClick()}
          small
        />
      </div>
      <Divider type="horizontal" className="notifImageDivider" />
    </Card>

  );
};

NotifCards.defaultProps = {

};

export default NotifCards;
