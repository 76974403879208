/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import React from 'react';
import './PillsToolbar.css';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';

interface PillsToolbarProps {
  data: any;
  competitorList: any;
  onRemoveCompetitor: (newCompList: any[]) => void;
}

enum FilterType {
  competitor = 'Competitor'
}

const PillsToolbar = ({
  data,
  competitorList,
  onRemoveCompetitor,
}: PillsToolbarProps) => {
  const removeItemFromArray = (arr:any[], value: any) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const removeFilter = (filterVal: any, type: FilterType) => {
    let tempPayload = [...data];
    switch (type) {
      case FilterType.competitor:
        tempPayload = removeItemFromArray(tempPayload, filterVal);
        onRemoveCompetitor(tempPayload);
        break;
      default: break;
    }
  };

  const buildLabel = (filter: any) => `${filter?.label || filter?.text || filter}`;

  const renderPills = (filterList: any[], type: FilterType) => {
    let valueArray = [...filterList];
    switch (type) {
      case FilterType.competitor:
        valueArray = competitorList.filter((comp: any) => filterList.includes(comp.id));
        break;
      default: break;
    }

    return valueArray?.map((filter: any) => (
      <Pill
        key={`${type}${filter?.label || filter?.text || filter}`}
        className={`${styles.pill} ${styles.pillBorder} noselect`}
        label={buildLabel(filter)}
        small
        ssrIcon={IcCross}
        onClick={() => {
          let val = filter;
          if (type === FilterType.competitor) {
            val = filter?.id;
          }
          removeFilter(val, type);
        }}
      />
    ));
  };

  return (
    <div className={styles.pillsContainer}>
      {data?.length > 0 ? (
        renderPills(data, FilterType.competitor)
      ) : null}
    </div>
  );
};

export default PillsToolbar;
