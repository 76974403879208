/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './CsvStatusBar.css';
import SSRIcon from '@ingka/ssr-icon';
import IcCheckMark from '@ingka/ssr-icon/paths/checkmark-small';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import Cross from '@ingka/ssr-icon/paths/cross';
import Text from '@ingka/text';
import Button from '@ingka/button';
import CsvImage from '../../../../../assets/images/ic_csv_stack_small.svg';
import { useTranslate } from '../../../../../common/services/translationService.js';

const CsvStatusBar = (props: any) => {
  const {
    isVisible,
    fileName,
    closeCsvDetailpanel,
    openCsvInvalidSidePanel,
    invalidListCount,
    csvListTotalCount,
    // openCsvUploadModal,
    // openCsvUploadModalReplace,
  } = props;

  const importSuccessText = useTranslate('csv.statusBar.importSuccess');
  const importFailureText = useTranslate('csv.statusBar.importFailure');
  const viewDetailsText = useTranslate('csv.statusBar.viewDetails');
  const noImportText = useTranslate('csv.statusBar.noImport');

  const importFail = () => csvListTotalCount - invalidListCount === 0;
  const importSuccess = () => invalidListCount === 0;

  return (
    isVisible
    && (
    <div className="csvStatusWrapper">
      <div className="csvStatusLeft">
        <div className="csvIconWrapper">
          <img className="icPhoto" src={CsvImage} alt="csvStack" />
        </div>
        <div className="csvstatusFileWrapper">
          <span className="csvStatusFileName">{fileName}</span>
          <div className="csvStatusFileDetails">
            {!importFail() && (
            <div className="csvStatusSuccess">
              <div className="csvStatusIcon csvStatusCheckIcon">
                <SSRIcon paths={IcCheckMark} />
              </div>
              {`${importSuccessText} ${csvListTotalCount - invalidListCount} of ${csvListTotalCount} items.`}
            </div>
            )}
            {importSuccess() === false && (
            <div className="csvStatusFail">
              <div className="csvStatusIcon csvStatusNoticeIcon">
                <SSRIcon paths={IcNotice} />
              </div>
              {!importFail() ? `${invalidListCount} ${importFailureText}` : noImportText}
              <Text className="csvLinkWrapper" onClick={openCsvInvalidSidePanel}>{viewDetailsText}</Text>
            </div>
            )}
          </div>
        </div>
      </div>
      <div className="csvStatusRight">
        {/* <Button
          text="Edit"
          type="primary"
          small
          onClick={openCsvUploadModal}
        />
        <Button
          text="Replace"
          type="secondary"
          small
          onClick={openCsvUploadModalReplace}
        /> */}
        <Button className="btCsvStatusClose" small type="tertiary" iconOnly ssrIcon={Cross} onClick={closeCsvDetailpanel} />
      </div>
    </div>
    )
  );
};

export default CsvStatusBar;
