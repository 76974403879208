/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/toggle/dist/style.css';
import Toggle from '@ingka/toggle';
import ListIcon from '@ingka/ssr-icon/paths/list';
import GridIcon from '@ingka/ssr-icon/paths/square-grid-2x2';
import { ColumnsType } from 'antd/lib/table/interface.js';
import useCallApi from '../../../common/services/apiService.js';
import { useTranslate } from '../../../common/services/translationService.js';
import './Dashboard.css';
import Header from './header/Header';
import {
  isIos, isNullEmptyOrUndefined, isRu, isSu, projectType, userData, userRu,
} from '../../../common/services/commonService.js';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import AllProjects from './allProjects/AllProjects';
import Drafts from './drafts/Drafts';
import Accepted from './accepted/Accepted';
import Sent from './sent/Sent';
import Closed from './closed/Closed';
import NewProjectReceived from './newProjectsReceived/NewProjectReceived';
import ReceivedProjectDetailsModal from '../projectsDesktop/projectDetails/newReceived/ProjectDetails';
import { Item } from '../createProject/items/Items';
import { RetailUnit } from '../createProject/retailUnitsTab/RetailUnits.js';
import { offlineData } from '../../../common/configs/offlineData';
import { appRoutes, projStatus } from '../../../common/constants/constant';
import { fetchLookupEnums, getQualityCheckCount } from '../../../common/services/commonApi.js';
import { HFB } from '../findComparison/filters/Filters.js';
import PillsToolbar, { FilterType } from './pillsToolbar/PillsToolbar';
import CloseModal from '../../../common/components/closeModal/CloseModal';
import { setGridListFlag } from '../../../store/reducers/dashboard-reducer';

export enum ProjectType {
  All='all',
  NEW='new',
  DRAFT='draft',
  ACCEPTED='accepted',
  SENT='sent',
  CLOSED='closed',
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export enum ProjectTypeName {
    accepted = 'Accepted',
    inProgress = 'In progress',
    closed = 'Closed',
    draft = 'Draft',
    received = 'Received',
    sent = 'Sent'
  }

export const fetchProjectType = (projType: ProjectType) => {
  switch (projType) {
    case ProjectType.ACCEPTED:
      return isRu() ? ProjectTypeName.accepted : ProjectTypeName.inProgress;
    case ProjectType.CLOSED:
      return ProjectTypeName.closed;
    case ProjectType.DRAFT:
      return ProjectTypeName.draft;
    case ProjectType.NEW:
      return ProjectTypeName.received;
    case ProjectType.SENT:
      return isRu() ? ProjectTypeName.inProgress : ProjectTypeName.sent;
    default:
      return null;
  }
};

export const commonColumns: ColumnsType<any> = [
  {
    key: 1,
    title: 'Project name',
    dataIndex: 'projectName',
    width: 15,
    className: 'table-header-padding',
    render: (text: any) => <div>{text || '--'}</div>,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  },
  {
    key: 3,
    title: 'Start date',
    dataIndex: 'projectStartDate',
    width: 10,
    render: (text: any) => <div>{text || '--'}</div>,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  },
  {
    key: 4,
    title: 'End date',
    dataIndex: 'projectEndDate',
    width: 10,
    render: (text: any) => <div>{text || '--'}</div>,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  },
  {
    key: 6,
    title: 'HFB',
    dataIndex: 'hfbNos',
    width: 10,
    render: (text: any) => <div>{text || '--'}</div>,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  },
  {
    key: 7,
    title: 'Items',
    dataIndex: 'itemCount',
    width: 6,
    render: (text: any) => <div>{text || '--'}</div>,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  },
];

export const iosEndDate = {
  key: 5,
  title: 'IOS End date',
  dataIndex: 'parentProjectEndDate',
  width: 10,
  render: (text: any) => <div>{text || '--'}</div>,
  shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
};

export const ruSuAccepted = {
  key: 8,
  title: isIos() === true ? "RU's accepted" : "SU's accepted",
  dataIndex: 'accepted',
  width: 7,
  render: (text: any) => <div>{text || '--'}</div>,
  shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
};

export const projectTypeVal = (project: any) => {
  switch (project?.statusNo) {
    case 10:
      return ProjectType.DRAFT;
    case 60:
      return ProjectType.CLOSED;
    case 20:
      return ProjectType.SENT;
    case 40:
      return ProjectType.ACCEPTED;
    case 50:
      if (isRu()) {
        return ProjectType.SENT;
      }
      return ProjectType.ACCEPTED;
    default:
      return ProjectType.NEW;
  }
};

const Dashboard = () => {
  const location: any = useLocation();
  const newProjectsReceivedTabTitle = useTranslate('tab.new_project_received');
  const draftTabTitle = useTranslate('tab.drafts');
  const sentTabTitle = useTranslate('tab.sent');
  const inProgressTabTitle = useTranslate('tab.in_progress');
  const acceptedTabTitle = useTranslate('tab.accepted');
  const closedTabTitle = useTranslate('tab.closed');
  const apiErrorMessage = useTranslate('api.failureMessage');
  const [projectsList, setProjectsList] = useState([]);
  const [newProjectsReceived, setNewProjectsReceived] = useState([]);
  const dispatch = useAppDispatch();
  const [draftProjects, setdraftProjects] = useState([]);
  const [acceptedProjects, setAcceptedProjects] = useState([]);
  const [sentProjects, setSentProjects] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [sticky, setSticky] = useState(false);
  const [currentTab, setCurrentTab] = useState<any>(null);
  const { post, get } = useCallApi();
  const [newProjectDetails, setNewProjectDetails] = useState([]);
  const [openDetailsDialog, setopenDetailsDialog] = useState(false);
  const [unsoldItemExists, setUnsoldItemExists] = useState<boolean>(false);
  const [openProjectNotFoundModal, setOpenProjectNotFoundModal] = useState(false);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [hfbList, setHfbList] = useState<any[]>([]);
  const [selectedHfbs, setSelectedHfbs] = useState<string[]>([]);
  const [highlightDays, setHighlightDays] = useState<number | null>(null);
  const [projectCategory, setProjectCategory] = useState('all');
  const [selectedSort, setSelectedSort] = useState('startdate asc');
  const navigate = useNavigate();
  const isMount = useIsMount();
  const [hasFilter, setHasFilter] = useState(false);
  const dashboardFilters = location.state?.dashboardFilters || false;
  const gridListView = useAppSelector((state) => state?.dashboardReducer?.gridListFlag);

  useEffect(() => {
    if (dashboardFilters === true
      && localStorage.getItem('dbSelectedFilters')) {
      const filters = JSON.parse(localStorage.getItem('dbSelectedFilters') as any);
      setSelectedHfbs(filters?.selectedHfbs);
      setProjectCategory(filters?.projectCategory);
      setSelectedSort(filters?.selectedSort);
    }
  }, []);

  useEffect(() => {
    const filtersToSave = { selectedHfbs, projectCategory, selectedSort };
    localStorage.setItem('dbSelectedFilters', JSON.stringify(filtersToSave));
  }, [selectedHfbs, projectCategory, selectedSort]);

  useEffect(() => {
    setdraftProjects(projectsList.filter((project: any) => project.statusNo === 10));
    if (isSu()) {
      setAcceptedProjects(projectsList.filter((project: any) => project.statusNo === 50));
    } else {
      setAcceptedProjects(projectsList.filter((project: any) => project.statusNo === 40));
    }
    setSentProjects(projectsList.filter(
      (project: any) => project.statusNo === 20 || project.statusNo === 50,
    ));
    setClosedProjects(projectsList.filter((project: any) => project.statusNo === 60));
  }, [projectsList]);

  const resetSticky = () => {
    if (currentTab) {
      currentTab.scrollTo(0, 0);
      setSticky(false);
    }
  };

  const triggerStickyHeader = (e: any) => {
    setCurrentTab(e.currentTarget);
    const { scrollTop } = e.currentTarget;
    if (scrollTop && scrollTop > 450) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const sentTitle = () => {
    if (isIos()) {
      return sentTabTitle;
    }
    if (isRu()) {
      return inProgressTabTitle;
    }
    return sentTabTitle;
  };

  const acceptTitle = () => {
    if (isIos()) {
      return inProgressTabTitle;
    }
    if (isRu()) {
      return acceptedTabTitle;
    }
    return inProgressTabTitle;
  };

  const fetchProjects = (ruCode?:string) => {
    dispatch(setLoader(true));
    const payload = {
      ProjectType: projectType(),
      StartWith: null,
      TotalCount: null,
      StatusNo: [],
      UnitCode: isIos() ? null : ruCode || userRu(),
      HfbNo: selectedHfbs || [],
      UserId: projectCategory === 'my' ? userData()?.userId : null,
      SortFilter: selectedSort || null,
    };
    post('dashboard-projects', payload, (data: any) => {
      if (data.getDashboardProjects.length > 0) {
        setProjectsList(data.getDashboardProjects);
      }
      if (data.receivedProjectOutputs.length > 0) {
        setNewProjectsReceived(data.receivedProjectOutputs);
      } else {
        // If there is no new projects from API
        setNewProjectsReceived([]);
      }
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  useEffect(() => {
    if (!isMount) {
      if (selectedHfbs.length === 0 && projectCategory === 'all' && (isNullEmptyOrUndefined(selectedSort) || selectedSort === 'startdate asc')) {
        setHasFilter(false);
      } else {
        setHasFilter(true);
      }
      fetchProjects(userRu());
    }
  }, [selectedHfbs, projectCategory, selectedSort]);

  const fetchHfbs = () => {
    get('business-structure', (data: []) => {
      const updatedHfbData: any = [];
      data.map((item: HFB, index) => (updatedHfbData.push(
        { key: index, value: item.id, label: item.codeName },
      )));
      setHfbList(updatedHfbData);
    });
  };

  useEffect(() => {
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      fetchHfbs();
      fetchLookupEnums(get);
      setSelectedRU(data || userRu());
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isIos() || !isNullEmptyOrUndefined(selectedRU)) {
        getQualityCheckCount(post);
      }
      if ((!isNullEmptyOrUndefined(selectedRU) || isIos() === true) && dashboardFilters === false) {
        fetchProjects(userRu());
      }
      const daysToHighlight = localStorage.getItem('highlightDays');
      setHighlightDays(daysToHighlight ? parseInt(daysToHighlight, 10) : null);
    }, 1000);
  }, [selectedRU]);

  const getLastSelection = () => {
    if (localStorage.getItem('dashboardLastselection')) {
      const previsouSelection = localStorage.getItem('dashboardLastselection') as any;
      return previsouSelection;
    }
    return 'tab_1';
  };

  const setWarningMessage = (itemsList: any) => {
    const ifUnsoldItem = itemsList?.some((item: Item) => item.isHighLighted === true);
    setUnsoldItemExists(ifUnsoldItem);
  };

  const fetchNewProjectDetails = (projectId: any) => {
    dispatch(setLoader(true));
    let endPoint = '';
    let payload = {};
    if (isRu()) {
      endPoint = 'accept-preview';
      payload = {
        GprojectId: projectId,
        ruCode: userRu(),
        UserId: userData()?.userId,
      };
    }
    if (isSu()) {
      endPoint = 'su-acceptpreview';
      payload = {
        projectId,
        UnitCode: userRu(),
      };
    }
    post(endPoint, payload, (data: any) => {
      setNewProjectDetails(data);
      setWarningMessage(data.projectItemList);
      setopenDetailsDialog(!openDetailsDialog);
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const handleDraftProjectClick = (projectId: any, type: ProjectType) => {
    dispatch(setLoader(true));
    let endPoint = '';
    let payload = {};
    if (isIos()) {
      endPoint = 'get-global-project';
      payload = {
        gProjectId: projectId,
        UserId: userData()?.userId,
        IsCopy: false,
      };
    }
    if (isRu()) {
      endPoint = 'get-ru-project';
      payload = {
        unitCode: userRu(),
        CurrentUserId: userData()?.userId,
        ParentProjectId: type === ProjectType.DRAFT ? null : 0,
        projectId,
        IsEditView: false,
        IsCopy: false,
        IsSuperAdmin: userData()?.isSuperAdmin,
      };
    }
    post(endPoint, payload, (data: any) => {
      dispatch(setLoader(false));
      if (data?.isFound !== false) {
        navigate(appRoutes.newProject, { state: { projData: data, type: projStatus.draft } });
      } else {
        setOpenProjectNotFoundModal(true);
      }
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: apiErrorMessage }));
    });
  };

  const openDetailsModal = (project: any, type: ProjectType) => {
    switch (type) {
      case ProjectType.NEW:
        fetchNewProjectDetails(project?.projectId);
        break;
      case ProjectType.DRAFT:
        handleDraftProjectClick(project?.projectId, type);
        break;
      default:
        navigate(appRoutes.sentProjectDetails, { state: { project, type } });
        break;
    }
  };

  const handleAcceptedProjectClick = (project: any) => {
    dispatch(setLoader(true));
    const endPoint = 'get-ru-project';
    const payload = {
      unitCode: userRu(),
      CurrentUserId: userData()?.userId,
      ParentProjectId: 0,
      projectId: project.projectId,
      IsEditView: false,
      IsCopy: false,
      IsSuperAdmin: userData()?.isSuperAdmin,
    };
    post(endPoint, payload, (data: any) => {
      if (data?.isFound !== false) {
        navigate(appRoutes.newProject, { state: { projData: data, type: projStatus.accepted } });
      } else {
        setOpenProjectNotFoundModal(true);
      }
      dispatch(setLoader(false));
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: apiErrorMessage }));
      dispatch(setLoader(false));
    });
  };

  const closeDetailsModal = () => {
    setopenDetailsDialog(false);
  };

  const acceptRUProject = (projectId: any, isAccept: boolean) => {
    dispatch(setLoader(true));
    const payload = {
      gProjectId: projectId,
      ruCode: userRu(),
      userId: userData()?.userId,
    };
    post('accept-gproject', payload, (data:any) => {
      if (isAccept) {
        fetchProjects();
        closeDetailsModal();
      } else {
        handleAcceptedProjectClick({ projectId: data?.projectId });
      }
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const acceptSUProject = (projectId: any, isAccept: boolean, suProjectId?: string) => {
    dispatch(setLoader(true));
    const payload = {
      retailUnitProjectId: projectId,
      suCode: userRu(),
      UserId: userData()?.userId,
      sellingUnitProjectId: suProjectId,
    };
    post('accept-ru-project', payload, (data: any) => {
      if (isAccept) {
        // Refresh the dashboard
        fetchProjects();
        closeDetailsModal();
      } else {
        const project = { projectId: data.sellingUnitProjectId };
        navigate(appRoutes.sentProjectDetails, {
          state: { project, type: ProjectType.ACCEPTED },
        });
        dispatch(setLoader(false));
      }
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const handleAcceptAndAssign = (projectId: any, isAccept: boolean, suProjectId?: string) => {
    if (isRu()) {
      acceptRUProject(projectId, isAccept);
    }
    if (isSu()) {
      acceptSUProject(projectId, isAccept, suProjectId);
    }
  };

  const tabs = [
    <Tab
      key="tab-1"
      text={`${useTranslate('tab.all_projects')} (${projectsList.length + newProjectsReceived.length})`}
      tabPanelId="tab_1"
    />,
    <Tab
      key="tab-2"
      text={`${newProjectsReceivedTabTitle} (${newProjectsReceived.length})`}
      active
      tabPanelId="tab_2"
      disabled={newProjectsReceived.length === 0}
    />,
    <Tab
      key="tab-3"
      text={`${draftTabTitle} (${draftProjects.length})`}
      tabPanelId="tab_3"
      disabled={draftProjects.length === 0}
    />,
    <Tab
      key="tab-4"
      text={`${acceptTitle()} (${acceptedProjects.length})`}
      tabPanelId="tab_4"
      disabled={acceptedProjects.length === 0}
    />,
    <Tab
      key="tab-5"
      text={`${sentTitle()} (${sentProjects.length})`}
      tabPanelId="tab_5"
      disabled={sentProjects.length === 0}
    />,
    <Tab
      key="tab-6"
      text={`${closedTabTitle} (${closedProjects.length})`}
      tabPanelId="tab_6"
      disabled={closedProjects.length === 0}
    />,
  ];

  const suTabs = [
    <Tab
      key="tab-1"
      text={`${useTranslate('tab.all_projects')} (${projectsList.length + newProjectsReceived.length})`}
      tabPanelId="tab_1"
    />,
    <Tab
      key="tab-2"
      text={`${newProjectsReceivedTabTitle} (${newProjectsReceived.length})`}
      active
      tabPanelId="tab_2"
      disabled={newProjectsReceived.length === 0}
    />,
    <Tab
      key="tab-4"
      text={`${acceptTitle()} (${acceptedProjects.length})`}
      tabPanelId="tab_4"
      disabled={acceptedProjects.length === 0}
    />,
    <Tab
      key="tab-6"
      text={`${closedTabTitle} (${closedProjects.length})`}
      tabPanelId="tab_6"
      disabled={closedProjects.length === 0}
    />,
  ];

  const iosTabs = [
    <Tab
      key="tab-1"
      text={`${useTranslate('tab.all_projects')} (${projectsList.length + newProjectsReceived.length})`}
      tabPanelId="tab_1"
    />,
    <Tab
      key="tab-3"
      text={`${draftTabTitle} (${draftProjects.length})`}
      tabPanelId="tab_3"
      disabled={draftProjects.length === 0}
    />,
    <Tab
      key="tab-4"
      text={`${sentTitle()} (${sentProjects.length})`}
      tabPanelId="tab_5"
      disabled={sentProjects.length === 0}
    />,
    <Tab
      key="tab-5"
      text={`${acceptTitle()} (${acceptedProjects.length})`}
      tabPanelId="tab_4"
      disabled={acceptedProjects.length === 0}
    />,
    <Tab
      key="tab-6"
      text={`${closedTabTitle} (${closedProjects.length})`}
      tabPanelId="tab_6"
      disabled={closedProjects.length === 0}
    />,
  ];

  const getTabs = () => {
    if (isSu()) {
      return suTabs;
    }
    if (isIos()) {
      return iosTabs;
    }
    return tabs;
  };

  const tabPanels = [
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-1"
      tabPanelId="tab_1"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <AllProjects
        newProjectsReceived={newProjectsReceived}
        projectsList={projectsList}
        highlightDays={highlightDays}
        handleProjectClick={openDetailsModal}
        handleAcceptedProjectClick={handleAcceptedProjectClick}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-2"
      tabPanelId="tab_2"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <NewProjectReceived
        newProjectsReceived={newProjectsReceived}
        handleProjectClick={openDetailsModal}
        highlightDays={highlightDays}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-3"
      tabPanelId="tab_3"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Drafts
        draftProjects={draftProjects}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-4"
      tabPanelId="tab_4"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Accepted
        acceptedProjects={acceptedProjects}
        handleAcceptedProjectClick={handleAcceptedProjectClick}
        highlightDays={highlightDays}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-5"
      tabPanelId="tab_5"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Sent
        sentProjects={sentProjects}
        highlightDays={highlightDays}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-6"
      tabPanelId="tab_6"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Closed
        closedProjects={closedProjects}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
  ];

  const iosTabPanels = [
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-1"
      tabPanelId="tab_1"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <AllProjects
        newProjectsReceived={newProjectsReceived}
        projectsList={projectsList}
        highlightDays={null}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-3"
      tabPanelId="tab_3"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Drafts
        draftProjects={draftProjects}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-4"
      tabPanelId="tab_4"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Accepted
        acceptedProjects={acceptedProjects}
        highlightDays={null}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-5"
      tabPanelId="tab_5"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Sent
        sentProjects={sentProjects}
        highlightDays={null}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-6"
      tabPanelId="tab_6"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Closed
        closedProjects={closedProjects}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
  ];

  const suTabPanels = [
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-1"
      tabPanelId="tab_1"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <AllProjects
        newProjectsReceived={newProjectsReceived}
        projectsList={projectsList}
        highlightDays={highlightDays}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-2"
      tabPanelId="tab_2"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <NewProjectReceived
        newProjectsReceived={newProjectsReceived}
        highlightDays={highlightDays}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-4"
      tabPanelId="tab_4"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Accepted
        acceptedProjects={acceptedProjects}
        handleProjectClick={openDetailsModal}
        highlightDays={highlightDays}
      />
    </TabPanel>,
    <TabPanel
      className={sticky ? 'fullHeight' : ''}
      key="tab-6"
      tabPanelId="tab_6"
      onScroll={(e) => triggerStickyHeader(e)}
    >
      <Closed
        closedProjects={closedProjects}
        handleProjectClick={openDetailsModal}
      />
    </TabPanel>,
  ];

  const getTabPanel = () => {
    if (isSu()) {
      return suTabPanels;
    }
    if (isIos()) {
      return iosTabPanels;
    }
    return tabPanels;
  };

  return (
    <div className="dashBoardContainer">
      <Header
        customClass={sticky ? 'displayNone' : 'displayBlock'}
        hfbList={hfbList}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        selectedHfbs={selectedHfbs}
        setSelectedHfbs={setSelectedHfbs}
        projectCategory={projectCategory}
        setProjectCategory={setProjectCategory}
      />
      {hasFilter === true ? (
        <PillsToolbar
          hfbData={selectedHfbs || []}
          sort={selectedSort || null}
          project={projectCategory}
          onRemoveFilter={(newSort, newProject, newHfbData, type) => {
            switch (type) {
              case FilterType.sort:
                setSelectedSort(newSort);
                break;
              case FilterType.project:
                setProjectCategory(newProject);
                break;
              case FilterType.hfb:
                setSelectedHfbs(newHfbData);
                break;
              default: break;
            }
          }}
          onClearAll={() => {
            setSelectedHfbs([]);
            setProjectCategory('all');
            setSelectedSort('startdate asc');
          }}
        />
      ) : null}
      <div className="contentWrapper" style={{ position: 'relative' }}>
        <Tabs
          tabs={getTabs()}
          tabPanels={getTabPanel()}
          defaultActiveTab={getLastSelection()}
          activeTab={activeTab}
          onTabChanged={(id) => {
            localStorage.setItem('dashboardLastselection', id);
            setActiveTab(id);
            resetSticky();
          }}
        />
        <Toggle
          iconOnly
          buttons={[
            {
              ssrIcon: GridIcon,
              text: 'Grid view',
            },
            {
              ssrIcon: ListIcon,
              text: 'List view',
            },
          ]}
          className="toggleButtonDash"
          activeIndex={gridListView === true ? 0 : 1}
          onClick={() => dispatch(setGridListFlag(!gridListView))}
        />
      </div>
      { newProjectDetails && (
      <ReceivedProjectDetailsModal
        isVisible={openDetailsDialog}
        onClose={closeDetailsModal}
        projectDetails={newProjectDetails}
        showWarning={unsoldItemExists}
        handleAcceptAndAssign={handleAcceptAndAssign}
      />
      )}
      <CloseModal
        modelOpen={openProjectNotFoundModal}
        closeModal={() => {
          setOpenProjectNotFoundModal(false);
        }}
        saveValid
        closeText="Ok"
        saveText=""
        title={useTranslate('dashboard.projNotFound.title')}
        subText={useTranslate('dashboard.projNotFound.text')}
      />
    </div>
  );
};

export default Dashboard;
