import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import './loader.css';
import { useAppSelector } from '../../../store/hooks';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loader = ({ manualLoader }: {manualLoader?: boolean }) => {
  const isLoading = useAppSelector((state) => state.createProjReducer.isLoading);
  return (
    (isLoading || manualLoader) && (
    <div className="loader">
      <Loading>
        <LoadingBall />
      </Loading>
    </div>
    )
  );
};

Loader.defaultProps = {
  manualLoader: false,
};

export default Loader;
