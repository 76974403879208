/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import RadioButton from '@ingka/radio-button';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionDescription, SectionTitle } from '../InstantComparison';
import styles from './ItemQualityFunctionality.module.css';

interface ItemQualityFunctionalitySectionProps {
  title: string;
  itemQualityFunctionality: string | undefined;
  setRadioVal: (e: any, type: string) => void;
}

const ItemQualityFunctionalitySection = ({
  title, itemQualityFunctionality, setRadioVal,
}: ItemQualityFunctionalitySectionProps) => (
  <div id={`Item${title}`} className={styles.sectionWrapper}>
    <SectionTitle sectionTitle={`${useTranslate('inst.comp.qualityTitle')} ${title}?`} />
    <SectionDescription sectionDescription={useTranslate('inst.comp.qualityDescription')} />
    <div className={styles.radioButtonWrapper}>
      <RadioButton
        className="radioGap"
        id={`item${title}Better`}
        name={`item${title}radio`}
        key={`item${title}Better`}
        value="better"
        label="Better"
        defaultChecked={itemQualityFunctionality === 'better'}
        onChange={(val) => setRadioVal(val, title)}
      />
      <RadioButton
        id={`item${title}Equal`}
        name={`item${title}radio`}
        key={`item${title}Equal`}
        className="radioGap"
        value="equal"
        label="Equal"
        defaultChecked={itemQualityFunctionality === 'equal'}
        onChange={(val) => setRadioVal(val, title)}
      />
      <RadioButton
        id={`item${title}Worse`}
        name={`item${title}radio`}
        key={`item${title}Worse`}
        value="worse"
        label="Worse"
        defaultChecked={itemQualityFunctionality === 'worse'}
        onChange={(val) => setRadioVal(val, title)}
      />
    </div>
  </div>
);

export default ItemQualityFunctionalitySection;
