/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import RadioButton from '@ingka/radio-button';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import { DatePicker, DatePickerProps } from 'antd';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcCross from '@ingka/ssr-icon/paths/cross';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './TroEndDate.module.css';

interface TroEndDateProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  unknownEndDate: boolean;
  setUnknownEndDate: React.Dispatch<React.SetStateAction<boolean>>;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

const TroEndDate = ({
  startDate, endDate, unknownEndDate,
  setUnknownEndDate, setEndDate,
}: TroEndDateProps) => {
  const onEndDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setEndDate(date);
      setUnknownEndDate(false);
    } else {
      setEndDate(null);
    }
  };

  return (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={useTranslate('inst.comp.restrictedOfferTimeEnd')} />
      <Text
        className={styles.dateLabel}
        headingSize="s"
        tagName="p"
      >
        { useTranslate('tab.details.end_date')}
      </Text>
      <DatePicker
        inputReadOnly
        className={styles.dateInput}
        onChange={onEndDateChange}
        suffixIcon={<SSRIcon paths={IcDownArrow} className={styles.icon} />}
        placeholder={useTranslate('inst.comp.date')}
        value={endDate}
        allowClear
        clearIcon={<SSRIcon paths={IcCross} className={styles.icon} />}
        disabledDate={(d) => !d
          || (startDate !== null && !d.isAfter(dayjs(startDate)))
          || d.isSameOrBefore(dayjs().subtract(1, 'days'))}
        popupClassName="troEndDateDropdown"
      />
      <RadioButton
        className={styles.radioButton}
        id="rb_endDate"
        label={useTranslate('inst.comp.radioButtonText')}
        name="rb_endDate"
        value={useTranslate('inst.comp.radioButtonValue')}
        checked={unknownEndDate}
        disabled={!isNullEmptyOrUndefined(endDate)}
        onClick={() => {
          setEndDate(null);
          setUnknownEndDate(!unknownEndDate);
        }}
      />
    </div>
  );
};

export default TroEndDate;
