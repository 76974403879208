/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import InputField from '@ingka/input-field';
import React, { useEffect, useRef } from 'react';

interface NumberInputProps {
    id: string;
    label: string;
    value: number | undefined;
    className: string;
    charLimit: number;
    disabled?: boolean;
    pieceFocus?: boolean;
    zeroValidate?: boolean;
    unitSuffix?: string;
    onChange: (value: number) => void;
    [x:string]: any;
}

const NumberInput = ({
  id,
  label,
  value,
  className,
  charLimit,
  disabled,
  pieceFocus,
  zeroValidate,
  onChange,
  unitSuffix,
  ...rest
}: NumberInputProps) => {
  const inputRef: any = useRef(null);

  useEffect(() => {
    if (pieceFocus === true) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 600);
    }
  }, [pieceFocus]);

  const onValueChange = (e: any) => {
    const updatedValue = e.target.value.replace(/\D/g, '');
    if (updatedValue.length <= charLimit) {
      onChange(zeroValidate ? (updatedValue > 0) ? updatedValue : '' : updatedValue);
    }
  };

  return (
    <InputField
      className={className}
      id={id}
      label={label}
      autoComplete="off"
      type="number"
      value={value}
      onChange={onValueChange}
      disabled={disabled}
      ref={inputRef}
      suffixLabel={unitSuffix}
      {...rest}
    />
  );
};

NumberInput.defaultProps = {
  disabled: false,
  pieceFocus: false,
  zeroValidate: false,
  unitSuffix: false,
};

export default NumberInput;
