/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal, {
  Sheets, ModalHeader, ModalBody,
} from '@ingka/modal';
import SSRIcon from '@ingka/ssr-icon';
import LinkOut from '@ingka/ssr-icon/paths/link-out';
import Text from '@ingka/text';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@ingka/button';
import { useAuth0 } from '@auth0/auth0-react';
import { QRCode } from 'antd';
import { appRoutes, faqRedirectUrl } from '../../constants/constant';
import { useTranslate } from '../../services/translationService.js';
import {
  env,
  isRu, userData,
} from '../../services/commonService.js';
import { offlineData } from '../../configs/offlineData';

import './SettingsPanel.css';

const SettingsPanel = ({
  isVisible, unit, appVersion, onClose,
}: {
    isVisible: boolean,
    unit: string,
    appVersion: string,
    onClose: () => void,
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const logoutTitle = useTranslate('logout.title');

  const navigateFaq = () => {
    window.open(faqRedirectUrl, '_blank', 'noreferrer');
  };

  const navigateToRuChange = () => {
    navigate(appRoutes.RUselect);
    onClose();
  };

  const signOut = () => {
    localStorage.clear();
    offlineData.clear();
    sessionStorage.clear();
    logout({ returnTo: env().logoutUrl });
  };

  return (
    <div>
      <Modal
        visible={isVisible}
        focusLockProps={{
          disabled: false,
        }}
        handleCloseBtn={onClose}
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          footer={null}
          header={
            <ModalHeader ariaCloseTxt="Close button text" title="Custom settings" />
          }
        >
          <ModalBody className="spModalBody">
            <div className="spContainer">
              <div className="spTopWrapper">
                <div className="spContentWrapper">
                  <Text className="spTitle">{userData()?.userName || 'User Name'}</Text>
                  <Text
                    onClick={() => (isRu() && userData().units.length > 1) && navigateToRuChange()}
                    className={`spSubTitle ${(isRu() && userData().units.length > 1) ? 'spLink' : ''}`}
                  >
                    {unit}
                  </Text>
                </div>
                <div className="spContentWrapper">
                  <Text className="spTitle">For more information and support</Text>
                  <div
                    className="spLinkWrapper"
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => navigateFaq()}
                    onClick={() => navigateFaq()}
                  >
                    <Text className="spSubTitle">Please visit the ToCompete page at IKEA home</Text>
                    <SSRIcon paths={LinkOut} />
                  </div>
                </div>
                <div className="spContentWrapper">
                  <Text className="spTitle">Scan QR code to access ToCompete in the mobile</Text>
                  <div>
                    <Text className="spSubTitle">
                      To scan this code, you can use a QR scanner app on
                      your phone, or some camera apps.
                    </Text>
                  </div>
                  <div className="qrBox">
                    <QRCode
                      size={200}
                      value={window.location.origin}
                    />
                    {' '}

                  </div>
                </div>
              </div>
              <div className="spBottomWrapper">
                <Button
                  href=""
                  text={logoutTitle}
                  type="secondary"
                  className="logoutButton"
                  onClick={() => { signOut(); }}
                  small
                />
                <Text className="spVersion">{`Version ${appVersion} © Inter IKEA System B.V. ${dayjs().year()}`}</Text>
              </div>
            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  );
};

export default SettingsPanel;
