/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import './CustomUnitOverview.css';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import Text from '@ingka/text';
import { Divider } from 'antd';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  convertToBase64, isNullEmptyOrUndefined, projectType, userData,
} from '../../../../common/services/commonService.js';
import ChildItemsTable from '../childItemsTable/ChildItemsTable';
import { offlineData } from '../../../../common/configs/offlineData';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import { appRoutes } from '../../../../common/constants/constant';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';

const RenderImages = ({ images }: any) => {
  const [imgList, setImgList] = useState<any>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchList = await Promise.all(
        images.map(async (image: any) => {
          const val: any = await offlineData.getItem(image);
          return val instanceof Blob ? val : new Blob([val.originFileObj]);
        }),
      );

      const imgPromises = fetchList.map((file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }));

      const imgResults = await Promise.all(imgPromises);
      setImgList(imgResults);
    };

    fetchImages();
  }, [images]);

  return (
    <div className="cuoImgWrapper">
      {imgList.map((img: any, index: number) => (
        <img className="cuoImg" src={img} alt={`Img${index.toString()}`} key={`Img${index.toString()}`} />
      ))}
    </div>
  );
};

interface CustomUnitOverviewProps {
    open: boolean
    onClose: () => void
}

const CustomUnitOverview = ({
  open, onClose,
}:CustomUnitOverviewProps) => {
  const customUnitData = useAppSelector((state) => state?.customUnitReducer?.createUnitData);
  const detailsTab = JSON.parse(JSON.stringify(customUnitData.detailsTab));
  const itemsTab = JSON.parse(JSON.stringify(customUnitData.itemsTab));
  const { post } = useCallApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cuoTitle = useTranslate('customUnit.overview.title');
  const cuoHfb = useTranslate('customUnit.overview.hfb');
  const cuoPra = useTranslate('customUnit.overview.pra');
  const cuoPa = useTranslate('customUnit.overview.pa');
  const cuoPrice = useTranslate('customUnit.overview.price');
  const cuoReferenceUrl = useTranslate('customUnit.overview.referenceUrl');
  const cuoComments = useTranslate('customUnit.overview.comments');
  const cuoRefImg = useTranslate('customUnit.overview.refImg');
  const cuoItems = useTranslate('customUnit.overview.items');
  const cuoSuccessMsg = useTranslate('customUnit.overview.success');
  const cuoFailureMsg = useTranslate('customUnit.overview.failure');

  const totalPrice = () => {
    let total = 0;
    itemsTab?.importedItems?.forEach((item: any) => { total += item.totalPricePerQuantity; });
    return total;
  };

  const saveUnit = async () => {
    dispatch(setLoader(true));
    const formData = new FormData();
    const {
      Hfb, Pra, Pa, Name, PiaLink, Comments,
    } = detailsTab || {};
    const imageList = await Promise.all(
      detailsTab?.Images?.map(async (image: any, index: number) => {
        const val: any = await offlineData.getItem(image);
        let blob;
        if (val instanceof Blob) {
          blob = val;
        } else {
          blob = new Blob([val.originFileObj], { type: val.type });
        }
        // Create a custom object that includes the Blob and additional properties
        return {
          blob,
          name: val.name || `default_name${index}`,
          lastModified: val.lastModified || Date.now(),
          uid: val.uid || `default_uid${index}`,
          type: val.type || `default_type${index}`,
        };
      }),
    );
    const { importedItems } = itemsTab || {};
    const itemsList: any[] = [];
    importedItems?.forEach((item: any) => {
      const itemBuild = {
        itemType: item?.itemType,
        itemNo: item?.itemNo,
        itemQty: item?.quantity,
      };
      itemsList.push(itemBuild);
    });
    const payload = {
      customUnitId: 0,
      customUnitName: Name?.trim(),
      hfbNo: Hfb,
      praNo: Pra,
      paNo: Pa,
      userId: userData()?.userId,
      userType: projectType(),
      unitUrl: PiaLink,
      unitRsp: totalPrice(),
      unitCurrency: 'EUR',
      comment: Comments,
      customUnitItemsList: itemsList,
    };
    const payloadBase64 = convertToBase64(payload)?.toString() || '';
    formData.append('customunit', payloadBase64);
    imageList.forEach((image: any) => {
      const lastIndex = image?.name?.lastIndexOf('.');
      const replaced = `IMAGE${image?.name?.substring(lastIndex)}`;
      formData.append('unitImages', image?.blob, replaced);
    });
    post('save-custom-unit', formData, (data: any) => {
      if (data?.isSuccess === true) {
        dispatch(setToast({ openToast: true, toastLabel: cuoSuccessMsg }));
        navigate(appRoutes.dashboard);
      } else {
        dispatch(setToast({ openToast: true, toastLabel: cuoFailureMsg }));
      }
      dispatch(setLoader(false));
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: cuoFailureMsg }));
      dispatch(setLoader(false));
    });
  };

  const getFullUrl = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <Modal
      visible={open}
      focusLockProps={{
        disabled: true,
      }}
      handleCloseBtn={onClose}
    >
      <Theatre
        aria-label="Accessibility header for a modal"
        header={<ModalHeader ariaCloseTxt="Close button text" title={cuoTitle} />}
      >
        <div className="cuoContainer">
          <Text className="cuoTitle">{detailsTab?.Name}</Text>
          <div className="cuoSectionWrapper cuoTopWrapper">
            <div className="cuoSection">
              <Text className="cuoLabel">{cuoPrice}</Text>
              <Text className="cuoValue">{`${totalPrice()} EUR`}</Text>
            </div>
            <Divider type="vertical" />
            <div className="cuoSection">
              <Text className="cuoLabel">{cuoHfb}</Text>
              <Text className="cuoValue">{detailsTab?.Hfb}</Text>
            </div>
            <Divider type="vertical" />
            <div className="cuoSection">
              <Text className="cuoLabel">{cuoPra}</Text>
              <Text className="cuoValue">{detailsTab?.Pra}</Text>
            </div>
            <Divider type="vertical" />
            <div className="cuoSection">
              <Text className="cuoLabel">{cuoPa}</Text>
              <Text className="cuoValue">{detailsTab?.Pa}</Text>
            </div>
          </div>
          <div className="cuoSectionWrapper cuoMidWrapper">
            {!isNullEmptyOrUndefined(detailsTab?.PiaLink) ? (
              <div className="cuoSection">
                <Text className="cuoLabel">{cuoReferenceUrl}</Text>
                <div className="cuoValue cuoUrl">
                  <a href={getFullUrl(detailsTab?.PiaLink)} target="_blank" rel="noopener noreferrer">
                    {detailsTab?.PiaLink}
                  </a>
                </div>
              </div>
            ) : null}
            <Accordion className="cuoAccordion">
              {!isNullEmptyOrUndefined(detailsTab?.Comments) ? (
                <AccordionItem id="cuoComments" title={cuoComments}>
                  <Text className="cuoValue">{detailsTab?.Comments}</Text>
                </AccordionItem>
              ) : null}
              {detailsTab?.Images?.length > 0 ? (
                <AccordionItem id="cuoImages" title={`${cuoRefImg} (${detailsTab?.Images?.length})`}>
                  <RenderImages images={detailsTab?.Images} />
                </AccordionItem>
              ) : null}
              <AccordionItem
                id="cuoItems"
                open
                title={`${cuoItems} (${itemsTab?.importedItems?.length > 0 ? itemsTab?.importedItems?.length : 0})`}
              >
                <ChildItemsTable
                  data={itemsTab?.importedItems}
                  displayTitleBar={false}
                  displayLabel={false}
                  key="cuoChildItems"
                  enableReplace={false}
                />
              </AccordionItem>
            </Accordion>
          </div>
          <div className="cuoBtnWrapper">
            <Button
              className="cuoBtnSubmit"
              type="emphasised"
              text={useTranslate('customUnit.overview.submit')}
              onClick={saveUnit}
            />
          </div>
        </div>
      </Theatre>
    </Modal>
  );
};

export default CustomUnitOverview;
