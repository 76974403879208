/* eslint-disable @typescript-eslint/no-explicit-any */
import RadioButton from '@ingka/radio-button';
import React from 'react';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './TroRegularPrice.module.css';
import PriceInput from '../../../../common/components/inputs/PriceInput';

interface TroRegularPriceProps {
    regularPrice: string | undefined;
    currency: any;
    unknownRegularPrice: boolean;
    setRegularPrice: React.Dispatch<React.SetStateAction<string | undefined>>;
    setUnknownRegularPrice: React.Dispatch<React.SetStateAction<boolean>>;
}

const TroRegularPrice = ({
  regularPrice, currency, unknownRegularPrice,
  setRegularPrice, setUnknownRegularPrice,
}: TroRegularPriceProps) => (
  <div className={styles.sectionWrapper}>
    <SectionTitle sectionTitle={useTranslate('inst.comp.regularPrice')} />
    <PriceInput
      className={styles.ifNumberOfPieces}
      id="regularPrice"
      label={useTranslate('inst.comp.price')}
      value={regularPrice}
      currency={currency || null}
      charLimit={11}
      onChange={(val) => {
        setRegularPrice(val);
        setUnknownRegularPrice(false);
      }}
      onBlur={() => null}
    />
    <RadioButton
      className={styles.radioButton}
      id="rb_regularPrice"
      label={useTranslate('inst.comp.radioButtonText')}
      name="rb_regularPrice"
      value={useTranslate('inst.comp.radioButtonValue')}
      checked={unknownRegularPrice}
      disabled={!isNullEmptyOrUndefined(regularPrice)}
      onClick={() => {
        setRegularPrice('');
        setUnknownRegularPrice(!unknownRegularPrice);
      }}
    />
  </div>
);

export default TroRegularPrice;
