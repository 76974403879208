/* eslint-disable @typescript-eslint/no-explicit-any */
import Accordion, { AccordionItem } from '@ingka/accordion';
import Text from '@ingka/text';
import React, { useEffect, useState } from 'react';
import { isIos, isRu } from '../../../../../common/services/commonService.js';
import flags from '../../../../../common/services/flags';
import { useTranslate } from '../../../../../common/services/translationService.js';
import { ProjectType } from '../../../dashboard/Dashboard';
import './SentAndClosedProjectDetails.css';

const IncludedUnits = ({
  units, unitCount, activityLog, selectedProjectStatus,
}: {
    units: any[],
    unitCount: number,
    activityLog: any[],
    selectedProjectStatus: ProjectType | undefined,
}) => {
  const acceptedTitle = useTranslate('proj.details.acceptedby');
  const notAcceptedTitle = useTranslate('proj.details.notAcceptedBy');
  const yetToAcceptTitle = useTranslate('proj.details.yetToAccept');
  const allRetailUnitsTitle = useTranslate('proj.details.allRetailUnits');
  const allSellingUnitsTitle = useTranslate('proj.details.allSellingUnits');
  const retailUnitsIncludedTitle = useTranslate('proj.details.retailUnitsIncluded');
  const sellingUnitsIncludedTitle = useTranslate('proj.details.sellingUnitsIncluded');
  const sellingUnitEmptyMessage = useTranslate('create.proj.sellingUnit_emptyMessage');
  const [acceptedUnits, setAcceptedUnits] = useState<any[]>([]);
  const [notAcceptedUnits, setNotAcceptedUnits] = useState<any[]>([]);

  useEffect(() => {
    const activity: any[] = [];
    units.forEach((unit) => {
      const unitCode = isIos() ? unit.RuCode : unit.SuCode;
      const unitName = isIos() ? unit.RuName : unit.SuName;
      activityLog.forEach((accItem) => {
        if (accItem.unitCode === unitCode) {
          activity.push({
            unitCode,
            unitName,
            activityDate: accItem.projectActivityDate,
          });
        }
      });
    });
    setAcceptedUnits(activity.filter((data) => data.activityDate !== null));
    setNotAcceptedUnits(activity.filter((data) => data.activityDate === null));
  }, [units]);

  const getUnitName = (unitCode: string) => {
    const unitName = units.find((unit: any) => unit.SuCode === unitCode).SuName;
    return unitName || '';
  };

  const renderUnitsContent = () => {
    const contentList = isIos() ? units : activityLog;
    if (contentList.length === 0 && isRu()) {
      return (
        <div className="scUnitsWrapper">
          <Text className="scBody">{sellingUnitEmptyMessage}</Text>
        </div>
      );
    }
    if (contentList.length === unitCount) {
      return (
        <div className="scUnitsWrapper">
          <span className="scUnitName">
            {isIos() ? allRetailUnitsTitle : allSellingUnitsTitle}
          </span>
        </div>
      );
    }
    return (
      <div className="scUnitsWrapper">
        {contentList.map((item: any, index: number) => (
          <div key={item.unitCode} className="scUnitItem">
            {isIos() ? (
              <span className="scUnitName">
                <img className="table-image-left" src={flags[item.RuCode]} alt="" />
                {item.RuName}
              </span>
            ) : (
              <span className="scUnitName">
                {`${item.unitCode} - ${getUnitName(item.unitCode)}`}
              </span>
            )}
            {index !== contentList.length - 1 && (
            <hr className="demo-divider__hr-vertical divider_vertical" />
            )}
          </div>
        ))}
      </div>
    );
  };

  const selectedUnitCount = (acceptedUnits.length + notAcceptedUnits.length) || 0;

  const renderActivityContent = () => {
    if (acceptedUnits.length > 0) {
      return (
        <div className="scActivityLogWrapper">
          <div className="scAcceptedWrapper sc-mb-24">
            <Text className="scTitle">{acceptedTitle}</Text>
            <Text className="scLabel sc-mb-24">{`${acceptedUnits.length} out of ${selectedUnitCount}`}</Text>
            <div className="scUnitsWrapper">
              {acceptedUnits.map((acceptedUnit: any, index: number) => (
                <div className="scUnitItem" key={acceptedUnit.unitCode}>
                  <div>
                    <div>
                      {isIos() ? (
                        <span className="scUnitName">
                          <img className="table-image-left" src={flags[acceptedUnit.unitCode]} alt="" />
                          {acceptedUnit.RuName || acceptedUnit.unitName}
                        </span>
                      ) : (
                        <span className="scUnitName">
                          {`${acceptedUnit.unitCode} - ${acceptedUnit.unitName}`}
                        </span>
                      )}
                    </div>
                    <span className="scActivityDate">{`on ${acceptedUnit.activityDate}`}</span>
                  </div>
                  {index !== acceptedUnits.length - 1 && (
                  <hr className="demo-divider__hr-vertical divider_vertical" />
                  )}
                </div>
              ))}
            </div>
          </div>
          {notAcceptedUnits.length > 0 ? (
            <div className="scAcceptedWrapper">
              <Text className="scTitle">{selectedProjectStatus === ProjectType.CLOSED ? notAcceptedTitle : yetToAcceptTitle}</Text>
              <Text className="scLabel sc-mb-24">{`${notAcceptedUnits.length} out of ${selectedUnitCount}`}</Text>
              <div className="scUnitsWrapper">
                {notAcceptedUnits.map((notAcceptedUnit: any, index: number) => (
                  <div className="scUnitItem" key={notAcceptedUnit.unitCode}>
                    {isIos() ? (
                      <span className="scUnitName">
                        <img className="table-image-left" src={flags[notAcceptedUnit.unitCode]} alt="" />
                        {notAcceptedUnit.RuName || notAcceptedUnit.unitName}
                      </span>
                    ) : (
                      <span className="scUnitName">
                        {`${notAcceptedUnit.unitCode} - ${notAcceptedUnit.unitName}`}
                      </span>
                    )}
                    {index !== notAcceptedUnits.length - 1 && (
                    <hr className="demo-divider__hr-vertical divider_vertical" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    const activities = activityLog.filter((data) => data.projectActivityDate !== null);
    if (activities?.length > 0) {
      return renderActivityContent();
    }
    return renderUnitsContent();
  };

  return (
    <Accordion className="scAccordion">
      <AccordionItem
        caption=""
        id="scSellingUnits"
        subtitle=""
        title={`${isIos()
          ? retailUnitsIncludedTitle
          : sellingUnitsIncludedTitle} (${isIos() ? units.length : activityLog.length} out of ${unitCount})`}
      >
        {renderContent()}
      </AccordionItem>
    </Accordion>
  );
};

export default IncludedUnits;
