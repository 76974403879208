import React from 'react';
import Modal, {
  ModalFooter, ModalHeader, Prompt,
} from '@ingka/modal';
import Button from '@ingka/button';
import styles from './RemoveAllPromptModal.module.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import { RemoveItem } from './SummaryPanel';

const RemoveAllPromptModal = ({
  modalOpen,
  numberOfItems,
  itemType,
  onModalClose,
  onRemove,
}:{
    modalOpen: boolean,
    numberOfItems: number,
    itemType: RemoveItem,
    onModalClose: () => void,
    onRemove: () => void
}) => {
  const removeText = () => {
    if (numberOfItems > 1) {
      return itemType === RemoveItem.RU ? 'retail units' : 'items';
    }
    return itemType === RemoveItem.RU ? 'retail unit' : 'item';
  };
  const cancel = useTranslate('common.text.cancel');
  const remove = useTranslate('create.proj.remove');
  const removeConfirmation = useTranslate('create.proj.removeConfirmation');
  const aboutToRemove = useTranslate('create.proj.aboutToRemove');

  return (
    <Modal
      handleCloseBtn={onModalClose}
      visible={modalOpen}
    >
      <Prompt
        aria-label="Accessibility header for a modal"
        className={styles.promptWrapper}
        header={(<ModalHeader />)}
        footer={(
          <ModalFooter className={styles.modalFooter}>
            <Button text={cancel} onClick={onModalClose} className={styles.modalFooterBtn} />
            <Button text={remove} type="primary" onClick={onRemove} className={styles.modalFooterBtn} />
          </ModalFooter>
      )}
        title={removeConfirmation}
      >
        <p className={styles.txtDesc}>
          {`${aboutToRemove} ${numberOfItems} ${removeText()}`}
        </p>
      </Prompt>
    </Modal>
  );
};

export default RemoveAllPromptModal;
