/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Switch from '@ingka/switch';
import Button from '@ingka/button';
import Text from '@ingka/text';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import styles from './Header.module.css';
import { isNullEmptyOrUndefined } from '../../services/commonService.js';

interface HeaderProps {
  isEnable: boolean;
  clickFn?: () => void;
  buttonLabel:string;
  title:string;
  subTitle?: string;
  subLink?: string;
  openKnowMoreModal?: ()=> void;
  switchText?: string;
  switchValue?: any;
  showMyComparisonBtn?: boolean;
  ownComparison?: boolean;
  setOwnComparison?: any;
  setPaginationItemCount?: any;
  totalQcCount?: any;
  setIsSorting?: any;
  setTriggerShowmore?: any;
  getQualityCheckData?: ()=> void
}

const PageHeader = ({
  isEnable, clickFn, buttonLabel, title, subTitle, subLink, openKnowMoreModal,
  switchText, switchValue, showMyComparisonBtn, ownComparison, setOwnComparison,
  setPaginationItemCount, totalQcCount, setTriggerShowmore, setIsSorting,
  getQualityCheckData,
}: HeaderProps) => (
  <div className={styles.fcHeaderContainer}>
    <div className={subTitle && styles.headerTitleMargin}>
      <Text className={styles.fcHeaderRegularFont}>{title}</Text>
      {subTitle && (
        <span>
          <span className={styles.fcHeaderSubFont}>{subTitle}</span>
          <span
            className={styles.fcHeaderSubLink}
            onClick={() => (openKnowMoreModal ? openKnowMoreModal() : null)}
          >
            {subLink}

          </span>
          {totalQcCount > 0 ? (
            <span className={styles.qcTotalCount}>
              {`(${totalQcCount} Quality ${totalQcCount > 1 ? 'issue(s))' : 'issue)'} `}
            </span>
          ) : null}
        </span>
      )}
    </div>
    {!subTitle && !isNullEmptyOrUndefined(buttonLabel)
      && (
      <Button
        text={buttonLabel}
        disabled={isEnable === false}
        type="emphasised"
        onClick={() => clickFn && clickFn()}
        small
      />
      )}
    {showMyComparisonBtn
    && (
    <div className={styles.qcShowComparison}>
      <Switch
        className={styles.qcShowComparisonText}
        checked={ownComparison}
        id="qcOwnComparison"
        label={switchText}
        value={switchValue}
        onChange={() => {
          setOwnComparison(!ownComparison);
          setPaginationItemCount(1);
          setIsSorting(false);
          setTriggerShowmore(false);
          getQualityCheckData && getQualityCheckData();
        }}
      />
    </div>
    )}
  </div>
);

PageHeader.defaultProps = {
  subTitle: '',
  subLink: '',
  clickFn: () => null,
};
export default PageHeader;
