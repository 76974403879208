/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import TextArea from '@ingka/text-area';
import {
  DatePickerProps, DatePicker, Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import IcArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import SSRIcon from '@ingka/ssr-icon';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import styles from './DetailsTab.module.css';
import './DetailsTab.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  createProj, prevProj, setAddtMessageValid, setMessageValid, setNameValid, setSaveValid, setSubmitValid,
} from '../../../../store/reducers/create-proj-reducer';
import useCallApi from '../../../../common/services/apiService.js';
import {
  isIos, isNullEmptyOrUndefined, isRu, projectType, userRu,
} from '../../../../common/services/commonService.js';
import { checkInvalidCharRegex, checkValidCharRegex, projStatus } from '../../../../common/constants/constant';

export interface Details {
  Name: string;
  StartDate: string;
  EndDate: string;
  Message: string;
  AdditionalMsg:string;
}

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: '#0058a3',
    }}
    spin
  />
);

const DetailsTab = (props: any) => {
  dayjs.extend(isSameOrBefore);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [iosEndDate, setIoSEndDate] = useState<Dayjs | null>(null);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErr, setMessageErr] = useState(false);
  const [AddtMsgErr, setAddtMsgErr] = useState(false);
  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');
  const nameValid = useAppSelector((state) => state.createProjReducer.nameValid);

  const [message, setMessage] = useState('');
  const [additionalMessage, setAdditionalMessage] = useState('');

  const E_PROJECT_NAME_REQUIRED = useTranslate('tab.details.name_required');
  const E_PROJECT_NAME_CHAR_LIMIT = useTranslate('tab.details.name_char_limit');
  const E_PROJECT_NAME_UNIQUE = useTranslate('tab.details.name_unique');
  const E_PROJECT_START_DATE = useTranslate('tab.details.start_date_error');
  const E_PROJECT_END_DATE = useTranslate('tab.details.end_date_error');
  const E_PROJECT_ADDITIONAL_MESSAGE = useTranslate('tab.details.additional_msg');
  const E_PROJECT_ADDITIONAL_MSG_HELPER = useTranslate('tab.details.additional_msg_helper');
  const E_PROJECT_ADDITIONAL_MSG_ERR = useTranslate('error.textAreaInvalid');
  const projectStore = useAppSelector((state) => state?.createProjReducer?.createProjData);
  const prevProjectStore = useAppSelector((state) => state?.createProjReducer?.prevProjData);
  const projDetails = props?.projtDetails;
  const acceptedProjEndDate:any = !isNullEmptyOrUndefined(projDetails?.GlobalProjectEndDate)
    ? dayjs(projDetails?.GlobalProjectEndDate) : dayjs(projDetails?.projectEndDate);

  const dispatch = useAppDispatch();
  const { post } = useCallApi();

  const isAcceptedProj = () => (projDetails?.statusName === projStatus.accepted);

  const isDraftProj = () => (projDetails?.statusName === projStatus.draft);

  const isEditProject = props?.isEditProject;
  const isCopy = () => (props.isCopy === true);

  const getProjectId = () => {
    if (isRu() && isDraftProj()) {
      return projDetails?.projectId;
    }
    if (isRu() && (isAcceptedProj() || isEditProject === true)) {
      return projDetails?.RetailUnitProjectId;
    }
    return projDetails?.GProjectId || projectStore?.detailsTab?.ProjectId;
  };

  const getProjectName = () => {
    if ((isIos()) || (isIos() && isEditProject === true)) {
      return projDetails?.GProjectName;
    }
    if (isRu() && (isAcceptedProj() || isEditProject === true)) {
      return projDetails?.RetailUnitProjectName;
    }
    return projDetails?.projectName;
  };

  const updateProject = () => {
    const projData = ({
      ...projectStore,
      detailsTab: {
        Name: name,
        StartDate: startDate.format('YYYY-MM-DD'),
        EndDate: endDate ? endDate?.format('YYYY-MM-DD') : null,
        Message: message || '',
        // eslint-disable-next-line max-len
        AdditionalMsg: (isAcceptedProj() || projDetails?.hasParentProject) ? additionalMessage : '',
        ProjectId: getProjectId() || null,
      },
    });
    dispatch(createProj(projData));
    if (isDraftProj()) {
      const tempPrevProjData = JSON.parse(JSON.stringify(prevProjectStore));
      tempPrevProjData.detailsTab.Name = name;
      dispatch(prevProj(tempPrevProjData));
    }
  };

  useEffect(() => {
    if (((isAcceptedProj() || isDraftProj()) && !isCopy()) || isEditProject === true) {
      const isStartDate: any = !isNullEmptyOrUndefined(projDetails?.projectStartDate)
        ? dayjs(projDetails?.projectStartDate) : null;
      const isEndDate: any = !isNullEmptyOrUndefined(projDetails?.projectEndDate)
        ? dayjs(projDetails?.projectEndDate) : null;
      const globalEndDate: any = !isNullEmptyOrUndefined(projDetails?.GlobalProjectEndDate)
        ? dayjs(projDetails?.GlobalProjectEndDate) : null;
      if (isDraftProj()) {
        // Checking start date validation for draft project
        if (isStartDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
          setStartDateError(false);
        } else if ((dayjs() > isStartDate)) {
          setStartDateError(true);
          setStartDateErrorMessage(E_PROJECT_START_DATE);
        }
        // Checking end date validation for draft project
        if (!isNullEmptyOrUndefined(isEndDate) && (isStartDate > isEndDate)) {
          setEndDateError(true);
          setEndDateErrorMessage(E_PROJECT_END_DATE);
        }
      }
      const oldProjName = getProjectName();
      const projname = isIos() ? oldProjName?.replace('IoS-', '') : oldProjName?.replace(`${userRu()}-`, '');
      const finalProjName = (isDraftProj() || isEditProject === true) ? projname : oldProjName;
      setName(finalProjName);
      setStartDate(isStartDate);
      setEndDate(isEndDate);
      setIoSEndDate(globalEndDate);
      setMessage(projDetails?.message);
      setAdditionalMessage(projDetails?.additionalMessage || '');
    } else if (isCopy()) {
      setMessage(projDetails?.message);
      setAdditionalMessage(projDetails?.additionalMessage || '');
    }
  }, [projDetails]);

  useEffect(() => {
    updateProject();
  }, [name, startDate, endDate, message, additionalMessage]);

  const isNotUnique = (value:any, prevProjStore:any) => {
    if (isDraftProj() || isEditProject === true) {
      const projName = getProjectName();
      const isEqual = isIos() ? projName !== `IoS-${value}` : projName !== `${userRu()}-${value}`;
      if (isEqual === false) {
        dispatch(setNameValid({ status: true, message: '' }));
        dispatch(setSaveValid(true));
      }
      return isEqual;
    }
    return projectStore?.detailsTab?.Name !== prevProjStore?.detailsTab?.Name;
  };

  const checkUniqueName = (value:any, prevProjStore:any) => {
    if (value.length > 0) {
      if (isNotUnique(value, prevProjStore)) {
        const payload = {
          ProjectName: isIos() ? `IoS-${value}` : `${userRu()}-${value}`,
          ProjectType: projectType(),
          ProjectId: null,
        };
        setIsLoading(true);
        post('project-validation', payload, (data:any) => {
          if (data.projectExists === true) {
            dispatch(setNameValid({ status: !data.projectExists, message: E_PROJECT_NAME_UNIQUE }));
          } else {
            dispatch(setNameValid({ status: true, message: '' }));
            dispatch(setSaveValid(true));
          }
          setIsLoading(false);
        }, () => {
          dispatch(setSaveValid(false));
          dispatch(setSubmitValid(false));
          setIsLoading(false);
        });
      }
    }
  };

  const onNameChange = (e: any) => {
    const nameVal = e.target.value;
    nameVal.length <= 20 && setName(nameVal);
    if (nameVal.length > 20) {
      dispatch(setNameValid({ status: false, message: E_PROJECT_NAME_CHAR_LIMIT }));
    } else if (nameVal.length === 0 || (nameVal.trim().length === 0 && nameVal.length > 0)) {
      dispatch(setNameValid({ status: false, message: E_PROJECT_NAME_REQUIRED }));
    } else {
      dispatch(setNameValid({ status: false, message: '' }));
    }
  };

  const onStartDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setStartDate(date);
    }
    if (isDraftProj() || isEditProject === true) {
      if (date && (dayjs(date) >= date)) {
        setStartDateError(false);
        setStartDateErrorMessage('');
      }
    }
    // checking Endate is equal or greater than startDate
    if (endDate && ((dayjs(date) > endDate)
    || dayjs(date).format('YYYY-MM-DD') === dayjs(endDate).format('YYYY-MM-DD'))) {
      setEndDateError(true);
      setEndDateErrorMessage(E_PROJECT_END_DATE);
    } else {
      setEndDateError(false);
      setEndDateErrorMessage('');
    }
  };

  const onEndDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setEndDate(date);
    }
    if (startDate && date && startDate > date) {
      setEndDateError(true);
      setEndDateErrorMessage(E_PROJECT_END_DATE);
    } else {
      setEndDateError(false);
      setEndDateErrorMessage('');
    }
  };

  const onMessageChange = (e: any) => {
    const messageVal = e.target.value;
    if (!isNullEmptyOrUndefined(messageVal)) {
      if (checkInvalidCharRegex.test(messageVal) === false && checkValidCharRegex.test(messageVal) === true) {
        setMessageErr(false);
        dispatch(setMessageValid(true));
      } else {
        setMessageErr(true);
        dispatch(setMessageValid(false));
      }
    } else setMessageErr(false);
    if (messageVal.length <= 600) {
      setMessage(messageVal);
    } else {
      setMessage(messageVal.slice(0, 600));
    }
  };

  const onAdditionalMsgChange = (e: any) => {
    const addtMessageVal = e.target.value;
    if (!isNullEmptyOrUndefined(addtMessageVal)) {
      if (checkInvalidCharRegex.test(addtMessageVal) === false && checkValidCharRegex.test(addtMessageVal) === true) {
        setAddtMsgErr(false);
        dispatch(setAddtMessageValid(true));
      } else {
        setAddtMsgErr(true);
        dispatch(setAddtMessageValid(false));
      }
    } else setAddtMsgErr(false);
    if (addtMessageVal.length <= 600) {
      setAdditionalMessage(addtMessageVal);
    } else {
      setAdditionalMessage(addtMessageVal.slice(0, 600));
    }
  };

  const ruEditSuEndDateFlag = () => (isRu() && isEditProject === true && !isNullEmptyOrUndefined(iosEndDate));

  const disableAcceptedProjectEndDate = () => {
    if (ruEditSuEndDateFlag() && (iosEndDate && (iosEndDate > dayjs() || iosEndDate?.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')))) {
      return false;
    }
    if (endDate?.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
      return false;
    }
    if (endDate && (endDate > dayjs())) {
      return false;
    }
    return true;
  };

  const buildNamePrefix = () => {
    if (isCopy()) {
      if (isIos()) { return 'IoS-'; }
      if (isRu()) {
        const savedRu: any = localStorage.getItem('selectedRetailUnit');
        const ruCode = !isNullEmptyOrUndefined(savedRu) ? JSON.parse(savedRu).ruCode : '';
        return `${ruCode}-`;
      }
    } else {
      if ((isIos() && !isAcceptedProj()) || (isIos() && !projDetails?.hasParentProject)) {
        return 'IoS-';
      }
      if (isRu() && projDetails?.hasParentProject) {
        return '';
      }
      if ((isRu() && !isAcceptedProj()) || (isRu() && !projDetails?.hasParentProject)) {
        const savedRu: any = localStorage.getItem('selectedRetailUnit');
        const ruCode = !isNullEmptyOrUndefined(savedRu) ? JSON.parse(savedRu).ruCode : '';
        return `${ruCode}-`;
      }
    }
    return '';
  };

  const disableEndDate = (d: any) => {
    if (!isAcceptedProj() && !projDetails?.hasParentProject) {
      if ((dayjs() > startDate)) {
        return d.isSameOrBefore(dayjs(startDate).subtract(1, 'days'));
      }
      return d.isSameOrBefore(dayjs(startDate).add(1, 'days'));
    }
    if (isEditProject === true && isIos()) {
      return d.isSameOrBefore(dayjs(startDate).add(0, 'days'));
    }
    return d.isAfter(dayjs(acceptedProjEndDate));
  };

  const disableDetailFields = () => {
    if (isIos() && isAcceptedProj() && !projDetails?.hasParentProject) {
      return true;
    }
    if (isRu() && projDetails?.hasParentProject) {
      return true;
    }
    if (isRu() && !projDetails?.hasParentProject) {
      return false;
    }
    return false;
  };

  return (
    <div className={styles.parent}>
      <Text
        className="tabTitle"
        headingSize="m"
        tagName="h1"
      >
        {useTranslate('tab.details.new_project')}
      </Text>
      <FormField
        shouldValidate={!nameValid.status && nameValid.message.length > 0}
        validation={{
          id: 'error-msg-id',
          msg: nameValid.message,
          type: 'error',
        }}
      >
        <InputField
          id="projectName"
          label={useTranslate('tab.details.name')}
          prefixLabel={buildNamePrefix()}
          type="text"
          value={name}
          autoComplete="off"
          disabled={disableDetailFields() && !isCopy()}
          suffixLabel={isLoading ? (
            <Spin
              indicator={antIcon}
              style={{ marginRight: '5px' }}
            />
          ) : undefined}
          onChange={(e) => onNameChange(e)}
          onBlur={
            (e:any) => checkUniqueName(e.target.value, prevProjectStore)
          }
        />
      </FormField>
      <div className={styles.dateContainer}>
        <div className={styles.dateInputBox}>
          <Text
            className={styles.dateLabel}
          >
            {useTranslate('tab.details.start_date')}
          </Text>
          <DatePicker
            className={`${styles.dateInput} ${startDateError && styles.dateInputError}`}
            onChange={onStartDateChange}
            suffixIcon={<SSRIcon paths={IcArrow} className={styles.arrow} />}
            placeholder=""
            disabled={disableDetailFields() && !isCopy()}
            value={startDate || dayjs()}
            allowClear={false}
            disabledDate={(d) => !d || d.isSameOrBefore(dayjs().subtract(1, 'days'))}
            popupClassName="dtDatePickerDropdown"
          />
          {startDateError && (
          <div className={styles.endDateErrorContianer}>
            <div className={styles.noticeIcon}>
              <SSRIcon paths={IcNotice} className={styles.errorIcon} />
            </div>
            <Text
              className={styles.errorMessage}
              headingSize="xs"
              tagName="p"
              bodySize="s"
            >
              {startDateErrorMessage}
            </Text>
          </div>
          )}
        </div>
        <div className={styles.dateInputBox}>
          <Text
            className={styles.dateLabel}
          >
            {useTranslate('tab.details.end_date')}
          </Text>
          <DatePicker
            className={`${styles.dateInput} ${endDateError && styles.dateInputError}`}
            onChange={onEndDateChange}
            suffixIcon={<SSRIcon paths={IcArrow} className={styles.arrow} />}
            placeholder=""
            disabled={projDetails?.hasParentProject && disableAcceptedProjectEndDate() && !isCopy()}
            value={endDate}
            allowClear={false}
            disabledDate={
              (d) => !d
              || (ruEditSuEndDateFlag() === true ? d.isSameOrBefore(dayjs().subtract(1, 'days')) : d.isSameOrBefore(dayjs()))
              || disableEndDate(d)
            }
            popupClassName="dtDatePickerDropdown"
          />
          {endDateError && (
          <div className={styles.endDateErrorContianer}>
            <div className={styles.noticeIcon}>
              <SSRIcon paths={IcNotice} className={styles.errorIcon} />
            </div>
            <Text
              className={styles.errorMessage}
              headingSize="xs"
              tagName="p"
              bodySize="s"
            >
              {endDateErrorMessage}
            </Text>
          </div>
          )}
        </div>
      </div>
      <FormField
        shouldValidate={!isNullEmptyOrUndefined(message) && messageErr === true}
        className={styles.messageBox}
        characterLimit={600}
        fieldHelper={{
          id: 'helper-msg-id',
          msg: useTranslate('tab.details.message_helper'),
          type: '',
        }}
        validation={{
          id: 'error-msg-comment',
          msg: useTranslate('error.textAreaInvalid'),
          type: 'error',
        }}
      >
        <TextArea
          id="example-id"
          label={useTranslate('tab.details.message')}
          value={message}
          disabled={(projDetails?.hasParentProject) && !isCopy()}
          onChange={onMessageChange}
        />
      </FormField>
      {(projDetails?.hasParentProject && isCopy() === false) && (
      <FormField
        shouldValidate={!isNullEmptyOrUndefined(additionalMessage) && AddtMsgErr === true}
        className={styles.messageBox}
        characterLimit={600}
        fieldHelper={{
          id: 'add-helper-msg-id',
          msg: E_PROJECT_ADDITIONAL_MSG_HELPER,
          type: '',
        }}
        validation={{
          id: 'error-msg-addtMsg',
          msg: E_PROJECT_ADDITIONAL_MSG_ERR,
          type: 'error',
        }}
      >
        <TextArea
          id="example-id"
          label={E_PROJECT_ADDITIONAL_MESSAGE}
          value={additionalMessage}
          onChange={onAdditionalMsgChange}
        />
      </FormField>
      )}
    </div>
  );
};

export default DetailsTab;
