/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  createUnitData: {
    detailsTab: {
      Name: '', Hfb: '', Pra: '', Pa: '', PiaLink: '', Comments: '', Images: <any>[],
    },
    itemsTab: { importedItems: [] },
  },
  unitNameValid: { status: true, message: '' },
  commentsValid: true,
  submitValid: false,

};

export const Reducer = createSlice({
  name: 'customUnitReducer',
  initialState,
  reducers: {
    setUnitSubmitValid: (state, action: PayloadAction<boolean>) => {
      state.submitValid = action.payload;
    },
    setUnitNameValid: (state, action: PayloadAction<object>) => {
      state.unitNameValid = { ...action.payload };
    },
    setCommentsValid: (state, action: PayloadAction<boolean>) => {
      state.commentsValid = action.payload;
    },
    createUnit: (state, action: PayloadAction<[]>) => {
      state.createUnitData = { ...action.payload };
    },
    createUnitFullReset: (state) => {
      state.createUnitData = { ...initialState.createUnitData };
      state.submitValid = false;
    },
  },
});

export const {
  setUnitNameValid,
  setCommentsValid,
  setUnitSubmitValid,
  createUnit,
  createUnitFullReset,
} = Reducer.actions;

export default Reducer.reducer;
