/* eslint-disable @typescript-eslint/no-explicit-any */
import Switch from '@ingka/switch';
import { Dayjs } from 'dayjs';
import React from 'react';
import PriceInput from '../../../../common/components/inputs/PriceInput';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './CompetitorPrice.module.css';

interface CompetitorPriceProps {
    competitorPrice: string | undefined;
    currency: any;
    showTimeRestricted: boolean;
    setShowTimeRestricted: React.Dispatch<React.SetStateAction<boolean>>;
    setCompetitorPrice: React.Dispatch<React.SetStateAction<string | undefined>>;
    setRegularPrice: React.Dispatch<React.SetStateAction<string | undefined>>
    setUnknownRegularPrice: React.Dispatch<React.SetStateAction<boolean>>
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
    setUnknownStartDate: React.Dispatch<React.SetStateAction<boolean>>
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>
    setUnknownEndDate: React.Dispatch<React.SetStateAction<boolean>>
}

const CompetitorPrice = ({
  competitorPrice, currency, showTimeRestricted,
  setCompetitorPrice, setShowTimeRestricted,
  setRegularPrice,
  setUnknownRegularPrice,
  setStartDate,
  setUnknownStartDate,
  setEndDate,
  setUnknownEndDate,
}: CompetitorPriceProps) => (
  <div className={styles.sectionWrapper}>
    <SectionTitle sectionTitle={useTranslate('inst.comp.competitorPrice')} />
    <PriceInput
      className={styles.ifNumberOfPieces}
      id="competitorPrice"
      label={useTranslate('inst.comp.current_price')}
      value={competitorPrice}
      currency={currency || null}
      charLimit={11}
      onChange={setCompetitorPrice}
    />
    <Switch
      className={styles.swTimeRestricted}
      defaultChecked={showTimeRestricted}
      id="swTimeRestricted"
      label={useTranslate('inst.comp.switchText')}
      value={useTranslate('inst.comp.switchValue')}
      onChange={() => {
        setShowTimeRestricted(!showTimeRestricted);
        setRegularPrice('');
        setUnknownRegularPrice(false);
        setStartDate(null);
        setUnknownStartDate(false);
        setEndDate(null);
        setUnknownEndDate(false);
      }}
    />
  </div>
);

export default CompetitorPrice;
