/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import Text from '@ingka/text';
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';
import { isNullEmptyOrUndefined } from '../../../../../common/services/commonService.js';

interface PillsToolbarProps {
  data: any;
  hfbData: any;
  totalComparison: any;
  onRemoveFilter: (newPayload: any, newHfbData: any,) => void;
  onClearAll: () => void;
  setMyCustomNews: (isMyCustomNews: boolean) => void;
}

enum FilterType {
  hfb = 'HFB',
  pra = 'PRA',
  pa = 'PA',
  ru = 'RU',
  myComp = 'My Comparisons',
  CustNews = 'My Custom News'
}

const PillsToolbar = ({
  data,
  hfbData,
  totalComparison,
  onRemoveFilter,
  onClearAll,
  setMyCustomNews,
}: PillsToolbarProps) => {
  const removeItemFromArray = (arr:any[], value: any) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const removeCommon = (first: any, second: any) => {
    const combine = [...first, ...second];
    return combine.filter((el) => !(first.includes(el) && second.includes(el)));
  };

  const removeHfbItemFromArray = (payload: any, hfbDataList: any, filter: any) => {
    const hfbFilterData: any = [];
    if (!isNullEmptyOrUndefined(payload.PraNos) || !isNullEmptyOrUndefined(payload.PaNos)) {
      hfbFilterData.push(hfbDataList.filter((item: any) => filter.includes(item.slice(0, 2))));
      return removeCommon(hfbFilterData[0], hfbDataList);
    }
    return hfbDataList.filter((item: any) => payload.HfbNos.includes(item.slice(0, 2)));
  };

  const removePraItemFromArray = (payload: any, hfbDataList: any, filter: any) => {
    const praFilterData: any = [];
    if (!isNullEmptyOrUndefined(payload.HfbNos) || !isNullEmptyOrUndefined(payload.PaNos)) {
      praFilterData.push(hfbDataList.filter((item: any) => filter.includes(item.slice(0, 3))));
      return removeCommon(praFilterData[0], hfbDataList);
    }
    return hfbDataList.filter((item:any) => payload.PraNos.includes(item.slice(0, 3)));
  };

  const removeFilter = (filterVal: any, type: FilterType) => {
    const tempPayload = { ...data };
    let tempHfbData = [...hfbData];
    switch (type) {
      case FilterType.hfb:
        tempPayload.HfbNos = removeItemFromArray(tempPayload?.HfbNos, filterVal);
        tempHfbData = removeHfbItemFromArray(tempPayload, tempHfbData, filterVal);
        break;
      case FilterType.pra:
        tempPayload.PraNos = removeItemFromArray(tempPayload?.PraNos, filterVal);
        tempHfbData = removePraItemFromArray(tempPayload, tempHfbData, filterVal);
        break;
      case FilterType.pa:
        tempPayload.PaNos = removeItemFromArray(tempPayload?.PaNos, filterVal);
        tempHfbData = removeItemFromArray(tempHfbData, filterVal);
        break;
      case FilterType.CustNews:
        tempPayload.UserId = null;
        setMyCustomNews(false);
        break;
      default: break;
    }
    onRemoveFilter(tempPayload, tempHfbData);
  };

  const buildLabel = (type: FilterType, filter: any) => {
    if ([FilterType.hfb,
      FilterType.pra,
      FilterType.pa,
      FilterType.ru,
      FilterType.myComp,
      FilterType.CustNews].includes(type)) {
      return filter?.label || filter;
    }
    return `${type}: ${filter?.label || filter?.text || filter}`;
  };

  const renderPills = (filterList: any[], type: FilterType) => {
    const valueArray = [...filterList];
    console.log(filterList, valueArray, 'kl');
    return valueArray?.map((filter: any) => (
      !isNullEmptyOrUndefined(filter) && (
      <Pill
        key={`${type}${filter?.label || filter?.text || filter}`}
        className={`${styles.pill} ${styles.pillBorder} noselect`}
        label={buildLabel(type, filter)}
        small
        ssrIcon={IcCross}
        onClick={() => {
          const val = filter;
          removeFilter(val, type);
        }}
      />
      )
    ));
  };

  return (
    <div className={styles.pillsContainer}>
      {data?.HfbNos?.length > 0 ? (
        renderPills(data?.HfbNos, FilterType.hfb)
      ) : null}
      {data?.PraNos?.length > 0 ? (
        renderPills(data?.PraNos, FilterType.pra)
      ) : null}
      {data?.PaNos?.length > 0 ? (
        renderPills(data?.PaNos, FilterType.pa)
      ) : null}
      {!isNullEmptyOrUndefined(data?.UserId) ? (
        renderPills([FilterType.CustNews], FilterType.CustNews)
      ) : null}
      <Pill
        className={`${styles.pill} noselect`}
        label="Clear all"
        small
        onClick={onClearAll}
      />
      {totalComparison > 0 ? (
        <Text className={styles.fcTotalCount}>
          {`(${totalComparison}  ${totalComparison > 1 ? 'Comparisons' : 'Comparison'})`}
        </Text>
      ) : null}
    </div>
  );
};

export default PillsToolbar;
