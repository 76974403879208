import React from 'react';
import SSRIcon from '@ingka/ssr-icon';
import IcClose from '@ingka/ssr-icon/paths/cross';
import Button from '@ingka/button';
import styles from './AppBarIcons.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppBarIcons = (props: any) => {
  const { closeFn } = props;
  return (
    <Button
      className={styles.button}
      iconOnly
      small
      type="tertiary"
      onClick={closeFn}
    >
      <SSRIcon paths={IcClose} />
    </Button>
  );
};

export default AppBarIcons;
