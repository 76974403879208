/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card } from 'antd';
import './CardMobile.css';
import SSRIcon from '@ingka/ssr-icon';
import CardRight from '@ingka/ssr-icon/paths/chevron-right-small';
import dayjs from 'dayjs';
import {
  isIos, isNullEmptyOrUndefined, isRu,
} from '../../services/commonService.js';

const Cards = (props: any) => {
  const {
    name, startDate, endDate, iosEndDate, hfb,
    items, suAccepted, cardKey, cardId,
    customClass, onClick, highlightDays, hasIosParent,
  } = props;

  const dateDifference = (date: string) => {
    const eventdate = dayjs(date);
    const todaysdate = dayjs();
    return eventdate.diff(todaysdate, 'days');
  };

  const shouldHighlight = () => {
    if (!isNullEmptyOrUndefined(highlightDays) && !isIos()) {
      if (isRu()) {
        const date = hasIosParent === true ? iosEndDate : endDate;
        return dateDifference(date) >= 0 && dateDifference(date) < highlightDays;
      }
      return dateDifference(endDate) >= 0 && dateDifference(endDate) < highlightDays;
    }
    return false;
  };

  return (
    <div className="cardParentContainer">
      <Card
        key={cardKey}
        id={cardId}
        className={`cardStyle
         ${customClass}
          ${shouldHighlight() ? 'highlightCard' : null}`}
        onClick={onClick}
      >
        <div className="flexRowSpace">
          <div>
            {name !== false && (
            <div className="defaultFont marginBottomFour">{name}</div>
            )}
            {startDate !== false && endDate !== false && (
            <div className="defaultSubFont">{`${startDate} - ${endDate}`}</div>
            )}
            {((name && name.includes('IoS')) && (isRu() === true && hasIosParent === true)) && (
            <div className={`${hfb !== false ? 'marginBottomTen' : ''} marginTopTen`}>
              <span className="defaultFont">IoS End Date: </span>
              {iosEndDate}
            </div>
            )}
            {hfb !== false && (
            <div className={`${items !== false ? 'marginBottomTen' : ''} ${((iosEndDate === false) || ((name && !name.includes('IoS')) && (isRu() === true && hasIosParent === true))) ? 'marginTopTen' : ''}`}>
              <span className="defaultFont">HFB: </span>
              {hfb}
            </div>
            )}
            {items !== false && (
            <div className={`${suAccepted !== false ? 'marginBottomTen' : ''} ${(hfb === false) ? 'marginTopTen' : ''}`}>
              <span className="defaultFont"> Items: </span>
              {items}
            </div>
            )}
            {suAccepted !== false && (
            <div className={`${(items === false) ? 'marginTopTen' : ''}`}>
              <span className="defaultFont">
                {isIos() ? 'RUs Accepted: ' : 'SUs Accepted: '}
              </span>
              {suAccepted}
            </div>
            )}
          </div>
          <div className="iconWrapper">
            <SSRIcon paths={CardRight} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Cards;
