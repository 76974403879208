/* eslint-disable @typescript-eslint/no-explicit-any */
import Pill from '@ingka/pill';
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcCross from '@ingka/ssr-icon/paths/cross-small';
import styles from './PillsToolbar.module.css';

interface PillsToolbarProps {
  data: any;
  onRemoveFilter: (cluster: any) => void;
  onClearAll: () => void;
}

const PillsToolbar = ({
  data,
  onRemoveFilter,
  onClearAll,
}: PillsToolbarProps) => {
  const removeFilter = (cluster: any) => {
    onRemoveFilter(cluster);
  };

  const renderPills = (filterList: any[]) => filterList?.map((filter: any) => (
    <Pill
      key={`${filter.clusterCode}`}
      className={`${styles.pill} ${styles.pillBorder} noselect`}
      label={filter.clusterName}
      small
      ssrIcon={IcCross}
      onClick={() => removeFilter(filter)}
    />
  ));

  return (
    <div className={styles.pillsContainer}>
      {data?.length > 0 ? (
        renderPills(data)
      ) : null}
      <Pill
        className={`${styles.pill} noselect`}
        label="Clear all"
        small
        onClick={onClearAll}
      />
    </div>
  );
};

export default PillsToolbar;
