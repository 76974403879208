/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/carousel/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/toggle/dist/style.css';
import Cards from '../../../../common/components/card/Card';
import {
  isIos, isNullEmptyOrUndefined,
  isRu,
  isSu,
} from '../../../../common/services/commonService.js';
import {
  ProjectType, commonColumns, fetchProjectType, iosEndDate, projectTypeVal, ruSuAccepted,
} from '../Dashboard';
import { useAppSelector } from '../../../../store/hooks';
import DashboardList from '../../../../common/components/list/List';

interface AllProjectProps {
    newProjectsReceived: any[],
    projectsList: any[],
    highlightDays: number | null,
    handleProjectClick: (value: any, type: ProjectType) => void,
    handleAcceptedProjectClick?:(project:any) => void,
}

const AllProjects = ({
  newProjectsReceived,
  projectsList,
  highlightDays,
  handleProjectClick,
  handleAcceptedProjectClick,
}: AllProjectProps) => {
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const [columnData, setColumnData] = useState<any[]>([]);
  const gridListFlag = useAppSelector((state) => state?.dashboardReducer?.gridListFlag);

  useEffect(() => {
    setAllProjects([...newProjectsReceived, ...projectsList]);
  }, [newProjectsReceived, projectsList]);

  const projStatus = {
    key: 2,
    title: 'Project status',
    dataIndex: 'statusNo',
    width: 10,
    render: (text: any, record: any) => <div>{fetchProjectType(projectTypeVal(record)) || '--'}</div>,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  };

  const buildColumnData = () => {
    const tempColumnData = [...commonColumns];
    tempColumnData.splice(1, 0, projStatus);
    if (isIos() === true || isRu() === true) {
      tempColumnData.push(ruSuAccepted);
      setColumnData(tempColumnData);
    }
    if (isRu() === true) {
      tempColumnData.splice(4, 0, iosEndDate);
      setColumnData(tempColumnData);
    }
    if (isSu()) {
      setColumnData(tempColumnData);
    }
  };

  useEffect(() => {
    buildColumnData();
  }, []);

  const handleClick = (project: any, type: string) => {
    switch (projectTypeVal(project)) {
      case ProjectType.DRAFT:
        handleProjectClick(project, ProjectType.DRAFT);
        break;
      case ProjectType.NEW:
        handleProjectClick(project, ProjectType.NEW);
        break;
      case ProjectType.CLOSED:
        handleProjectClick(project, ProjectType.CLOSED);
        break;
      case ProjectType.SENT:
        handleProjectClick(project, ProjectType.SENT);
        break;
      case ProjectType.ACCEPTED:
        !isRu() ? handleProjectClick(project, ProjectType.ACCEPTED)
          : handleAcceptedProjectClick && handleAcceptedProjectClick(project);
        break;
      default:
        break;
    }
  };

  const buildIosEndDate = (project: any) => {
    if ([ProjectType.DRAFT, ProjectType.NEW].includes(projectTypeVal(project))
      && isIos() !== true) {
      return (isNullEmptyOrUndefined(project?.parentProjectEndDate) === true ? false
        : project?.parentProjectEndDate);
    }
    return false;
  };

  const buildHfb = (project: any) => {
    if (projectTypeVal(project) === ProjectType.DRAFT) {
      return isNullEmptyOrUndefined(project.hfbNos) ? false : project.hfbNos;
    }
    return project.hfbNos;
  };

  const buildSuAccepted = (project: any) => {
    switch (projectTypeVal(project)) {
      case ProjectType.CLOSED || ProjectType.SENT:
        return isNullEmptyOrUndefined(project.accepted) ? false : project.accepted;
      case ProjectType.ACCEPTED:
        if (isRu()) {
          return false;
        }
        if (isNullEmptyOrUndefined(project.accepted)) {
          return false;
        }
        return project.accepted;
      default:
        return false;
    }
  };

  const buildHighlightDays = (project: any, highlight: any) => {
    switch (projectTypeVal(project)) {
      case ProjectType.SENT || ProjectType.ACCEPTED || ProjectType.NEW:
        return highlight;
      default:
        return null;
    }
  };

  return (
    <div>
      {allProjects?.length > 0
        ? gridListFlag === true
          ? (
            <div className="projectsContentWrapper">
              {(allProjects?.map((project) => (
                <Cards
                  key={project.projectId}
                  allProjects
                  cardKey={project.projectId}
                  projectType={projectTypeVal(project)}
                  images={project?.itemImageUrl}
                  name={project.projectName}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  items={project.itemCount}
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  iosEndDate={buildIosEndDate(project)}
                  hfb={buildHfb(project)}
                  suAccepted={buildSuAccepted(project)}
                  highlightDays={buildHighlightDays(project, highlightDays)}
                  onClick={() => handleClick(project, 'all')}
                />
              )))}
            </div>
          ) : (
            <DashboardList
              projectData={allProjects}
              columnData={columnData}
              onRowClick={handleClick}
            />
          ) : null}
    </div>
  );
};

AllProjects.defaultProps = {
  handleAcceptedProjectClick: () => null,
};

export default AllProjects;
