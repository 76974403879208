import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Buffer } from 'buffer';
import './Report.css';
import { models } from 'powerbi-client';
import { useDispatch } from 'react-redux';
import { offlineData } from '../../../common/configs/offlineData';
import { setLoader } from '../../../store/reducers/create-proj-reducer';
import { Reports, Widget } from './Reports';
import { isNullEmptyOrUndefined } from '../../../common/services/commonService.js';

const Report = () => {
  const dispatch = useDispatch();
  const urlParam = new URLSearchParams(window.location.search);
  const reportId: string = urlParam?.get('rId') || '';
  const [embedToken, setToken] = useState<string>();
  const [report, setReport] = useState<Widget | null>(null);
  const eventHandlers = new Map([
    ['loaded', () => {
      // console.log('Report loaded')
    }],
    ['rendered', () => {
      // console.log('Report rendered')
    }],
    ['error', () => {
      // console.log(event.detail)
    }],
  ]);

  useEffect(() => {
    if (!isNullEmptyOrUndefined(reportId)) {
      dispatch(setLoader(true));
      offlineData.getItem('reports').then((reports) => {
        const { token } = reports as Reports;
        const selectedReport = ((reports as Reports).reportsDetails)?.find(
          (rep) => rep.reportId === reportId,
        );
        if (selectedReport && !isNullEmptyOrUndefined(selectedReport)) {
          const tok = Buffer.from(token, 'base64').toString('binary');
          setToken(tok);
          setReport(selectedReport);
          dispatch(setLoader(false));
        }
      }).catch(() => {
        dispatch(setLoader(false));
      });
    }
  }, [reportId]);

  const getEmbeddedComponent = () => {
    // embeddedReport: any recieved in the method
    // You can use the Power BI Client APIs with this instance.
  };

  return (
    <div>
      {!isNullEmptyOrUndefined(report) ? (
        <PowerBIEmbed
          getEmbeddedComponent={getEmbeddedComponent}
          eventHandlers={eventHandlers}
          cssClassName="reportsEmbedContainer"
          embedConfig={{
            type: 'report',
            id: report?.reportId,
            embedUrl: report?.embedUrl,
            accessToken: embedToken, // Keep as empty string, null or undefined
            tokenType: models.TokenType.Embed,
          }}
        />
      ) : null}
    </div>
  );
};

export default Report;
