/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import Modal, {
  ModalBody, ModalHeader, Theatre,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import React from 'react';
import plus from '@ingka/ssr-icon/paths/plus';
import Text from '@ingka/text';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { useNavigate } from 'react-router-dom';
import './ItemDetails.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import { appRoutes } from '../../../../common/constants/constant';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

interface ItemDetailsProps {
  openItemModal: boolean;
  projectId: any;
  selectedItem: any;
  isInstantComparison?: boolean;
  onCloseItemModal: () => void;
  selectedProj?: object;
}

const ItemDetails = ({
  openItemModal, projectId, selectedItem, isInstantComparison,
  onCloseItemModal, selectedProj,
}: ItemDetailsProps) => {
  const deadlineDate = selectedItem?.comparisonItemBasicViewEntity?.deadlineDate;
  const canAddComparison = selectedItem?.comparisonItemBasicViewEntity?.canAddComparison;
  const navigate = useNavigate();

  const carouselProps = {
    id: 'ItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const renderAccordionItems = (title: string, data: string, type?: string) => (
    <div key={`${type}${title}${data}`} className={type ? 'flexColumn itemContWidth' : 'flexRow itemContWidth'}>
      {title && (
      <Text className="itemDetails-measure-title">
        {title}
        {title.includes(':') ? '\u00A0' : ':\u00A0'}
      </Text>
      )}
      <Text className="itemDetails-measure-value">{data}</Text>
    </div>
  );

  return (
    <Modal
      visible={openItemModal}
      handleCloseBtn={() => onCloseItemModal()}
    >
      <Theatre
        aria-labelledby="itemDetailModal"
        className="itemDetails-theatre"
        header={(
          <ModalHeader
            className="defaultFont"
            titleId="itemDetailModal"
            title={useTranslate('inst.comp.itemDetailsTitle')}
            floating={false}
          />
          )}
      >
        <ModalBody>
          <Carousel {...carouselProps} isSlideShow={false}>
            {selectedItem?.comparisonItemImageViewEntity?.map(
              (item:any) => <img key={item?.imagePath} className="itemDetails-item-image" src={item?.imagePath} alt="" />,
            )}
          </Carousel>
          <div className="itemDetailsContentWrapper">
            <div className="leftContainer">
              <Text className="itemDetails-item-title">{selectedItem?.comparisonItemBasicViewEntity?.itemName}</Text>
              <div className="itemDetails-item-subTitle-container">
                <Text className="itemDetails-item-subTitle">
                  {`${selectedItem?.comparisonItemBasicViewEntity?.paNo}  •  ${selectedItem?.comparisonItemBasicViewEntity?.itemNo}${!isNullEmptyOrUndefined(selectedItem?.priceInclTax) ? `  •  ${selectedItem?.comparisonItemBasicViewEntity?.currency} ${selectedItem?.priceInclTax}` : ''}`}
                </Text>
                {selectedItem?.comparisonItemBasicViewEntity?.isBti === true ? (
                  <div style={{ display: 'flex', marginLeft: '8px' }}>
                    {'  •'}
                    <BtiComponent ml={8} />
                  </div>
                ) : null}
              </div>
              <Accordion>
                <AccordionItem
                  id="itemDetailsDimensionMetric"
                  title={useTranslate('itemDetails.dimensionMetric')}
                  open
                >
                  <div className="materialWrapper">
                    {selectedItem?.metricMeasureList?.map(
                      (val:any) => renderAccordionItems(val.typeName, val.value, 'met'),
                    )}
                  </div>
                </AccordionItem>
              </Accordion>
              <Accordion>
                <AccordionItem
                  id="itemDetailsMaterial"
                  title={useTranslate('itemDetails.material')}
                >
                  {selectedItem?.materialMeasureList?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value, 'mat'),
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion>
                <AccordionItem
                  id="itemDetailsDimensionImperial"
                  title={useTranslate('itemDetails.dimensionImperial')}
                >
                  {selectedItem?.imperialMeasureList?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value),
                  )}
                </AccordionItem>
              </Accordion>
            </div>
            <div className="rightContainer">
              {!isInstantComparison ? (
                <Button
                  className="btAddComparison"
                  text="Add Comparison"
                  type="emphasised"
                  small={false}
                  ssrIcon={plus}
                  fluid
                  disabled={canAddComparison === false}
                  onClick={() => {
                    navigate(
                      appRoutes.IC,
                      {
                        state: {
                          selectedProj,
                          isSend: true,
                          isInstantCompare: false,
                          projectItem: selectedItem,
                          projectId,
                        },
                      },
                    );
                    onCloseItemModal();
                  }}
                />
              ) : null}
              <Text className="tDeadline">Deadline</Text>
              <Text className="itemDetails-item-date">
                {deadlineDate}
              </Text>
            </div>
          </div>
        </ModalBody>
      </Theatre>
    </Modal>
  );
};

ItemDetails.defaultProps = {
  isInstantComparison: false,
  selectedProj: {},
};

export default ItemDetails;
