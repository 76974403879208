/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import Pill from '@ingka/pill';
import { Checkbox, Input, Popover } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcUpArrow from '@ingka/ssr-icon/paths/chevron-up';
import './Header.css';
import RadioButton from '@ingka/radio-button';
import { appRoutes } from '../../../../common/constants/constant';

interface DashboardHeaderProps {
  customClass: string;
  hfbList: any[];
  selectedSort: any;
  setSelectedSort: React.Dispatch<React.SetStateAction<string>>;
  selectedHfbs: string[];
  setSelectedHfbs: React.Dispatch<React.SetStateAction<string[]>>;
  projectCategory: string;
  setProjectCategory: React.Dispatch<React.SetStateAction<string>>;
}

const Header = ({
  customClass, hfbList, selectedSort, setSelectedSort, selectedHfbs,
  setSelectedHfbs, projectCategory, setProjectCategory,
}: DashboardHeaderProps) => {
  const navigate = useNavigate();
  const [isSortOpened, setSortOpened] = useState(false);
  const [isHFBOpened, setHFBOpened] = useState(false);
  const [isProjectOpened, setProjectOpened] = useState(false);

  const dropdownClick = (isOpen: boolean, type: string) => {
    switch (type) {
      case 'sort':
        setSortOpened(isOpen);
        break;
      case 'hfb':
        setHFBOpened(isOpen);
        break;
      case 'projects':
        setProjectOpened(isOpen);
        break;
      default:
        break;
    }
  };

  const onCheckboxChange = (selection: [], type: string) => {
    switch (type) {
      case 'hfb':
        setSelectedHfbs(selection);
        break;
      default:
        break;
    }
  };

  const setSortVal = (val:string) => {
    setSelectedSort(val);
  };

  const hfbMenu = (
    <Checkbox.Group options={hfbList} value={selectedHfbs} onChange={(val: any) => onCheckboxChange(val, 'hfb')} />
  );

  const onProjTypeChange = (val:string) => {
    setProjectCategory(val);
  };

  const projectTypeFilter = (
    <div className="fcRadioButtonWrapper">
      <RadioButton
        key="all"
        className="radio_container"
        id="ProjTypeRadioButtons-All"
        value="all"
        label="All Project"
        checked={projectCategory === 'all'}
        name="AllRadioButtons"
        onChange={(val) => onProjTypeChange(val.target.value)}
      />
      <RadioButton
        key="my"
        className="item-gap"
        id="ProjRadioButtons-My"
        value="my"
        checked={projectCategory === 'my'}
        label="My Project"
        name="MyRadioButtons"
        onChange={(val) => onProjTypeChange(val.target.value)}
      />
    </div>
  );

  const sortMenu = (
    <div className="dbRadioButtonWrapper">
      <RadioButton
        key="startdate desc"
        className="radioGap"
        id="startDateNewtoOld"
        label="Start date: New to old"
        value="startdate desc"
        checked={selectedSort === 'startdate desc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="startdate asc"
        className="radioGap"
        id="startDateOldtoNew"
        label="Start date: Old to new"
        value="startdate asc"
        checked={selectedSort === 'startdate asc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="enddate desc"
        className="radioGap"
        id="endDateNewtoOld"
        label="End date: New to old"
        value="enddate desc"
        checked={selectedSort === 'enddate desc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="enddate asc"
        className="radioGap"
        id="endDateOldtoNew"
        label="End date: Old to new"
        value="enddate asc"
        checked={selectedSort === 'enddate asc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="projectname asc"
        className="radioGap"
        id="projNameAtoZ"
        label="Project name: A - Z"
        value="projectname asc"
        checked={selectedSort === 'projectname asc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="projectname desc"
        className="radioGap"
        id="projNameZtoA"
        label="Project name: Z - A"
        value="projectname desc"
        checked={selectedSort === 'projectname desc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="hfbnos asc"
        className="radioGap"
        id="hfb01to99"
        label="HFB: 01 - 99"
        value="hfbnos asc"
        checked={selectedSort === 'hfbnos asc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
      <RadioButton
        key="hfbnos desc"
        className="radioGap"
        id="hfb99to01"
        label="HFB: 99 - 01"
        value="hfbnos desc"
        checked={selectedSort === 'hfbnos desc'}
        name="dbSortRadioButtons"
        onChange={(val) => setSortVal(val.target.value)}
      />
    </div>
  );

  return (
    <div className={`actionContainer ${customClass}`}>
      <div className="filtersWrapper">
        <Popover
          overlayClassName="dbHeaderSortDropdown"
          content={<Input.Group compact>{[sortMenu]}</Input.Group>}
          trigger="click"
          placement="bottomLeft"
          onOpenChange={(val: any) => dropdownClick(val, 'item')}
        >
          <Pill
            iconPosition="trailing"
            label="Sort"
            small
            ssrIcon={isSortOpened ? IcUpArrow : IcDownArrow}
          />
        </Popover>
        <Popover
          overlayClassName="dbHeaderHfbDropdown"
          content={<Input.Group compact>{[hfbMenu]}</Input.Group>}
          trigger="click"
          placement="bottomLeft"
          onOpenChange={(val: any) => dropdownClick(val, 'item')}
        >
          <Pill
            iconPosition="trailing"
            label="HFB"
            small
            ssrIcon={isHFBOpened ? IcUpArrow : IcDownArrow}
          />
        </Popover>
        <Popover
          content={<Input.Group compact>{projectTypeFilter}</Input.Group>}
          trigger="click"
          placement="bottomLeft"
          onOpenChange={(val: any) => dropdownClick(val, 'projects')}
        >
          <Pill
            iconPosition="trailing"
            label="Projects"
            small
            ssrIcon={isProjectOpened ? IcUpArrow : IcDownArrow}
          />
        </Popover>
      </div>
      <Button
        text="Instant Comparison"
        type="emphasised"
        onClick={() => navigate(appRoutes.IC, { state: { isInstantCompare: true } })}
        small
      />
    </div>
  );
};

export default Header;
