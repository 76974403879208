/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, Divider } from 'antd';
import './Card.css';
import Text from '@ingka/text';
import SSRIcon from '@ingka/ssr-icon';
import IcNoImage from '@ingka/ssr-icon/paths/image-strikethrough';
import dayjs from 'dayjs';
import {
  isIos, isNullEmptyOrUndefined, isRu,
} from '../../services/commonService.js';
import { ProjectType, ProjectTypeName, fetchProjectType } from '../../../components/desktop/dashboard/Dashboard';

interface CardsProps {
  images: string[];
  name: string | false;
  startDate: string | false;
  endDate: string | false;
  iosEndDate: string | false;
  hfb: string | false,
  items: string | false;
  suAccepted: string | false;
  cardKey: React.Key | null | undefined;
  onClick: () => void;
  projectType?: ProjectType;
  highlightDays?: number | null;
  hasIosParent?: boolean;
  allProjects?: boolean;
  customClass?: string;
  reduceSize?:boolean;
}

const Cards = ({
  projectType, images, name, startDate, endDate, iosEndDate, hfb,
  items, suAccepted, cardKey, customClass,
  onClick, highlightDays, hasIosParent, allProjects, reduceSize,
}: CardsProps) => {
  const dateDifference = (date: string) => {
    const eventdate = dayjs(date);
    const todaysdate = dayjs();
    return eventdate.diff(todaysdate, 'days');
  };

  const shouldHighlight = () => {
    if (highlightDays && !isNullEmptyOrUndefined(highlightDays) && !isIos() && endDate !== false) {
      if (isRu()) {
        const date = hasIosParent === true ? iosEndDate as string : endDate;
        return dateDifference(date) >= 0 && dateDifference(date) < highlightDays;
      }
      return dateDifference(endDate) >= 0 && dateDifference(endDate) < highlightDays;
    }
    return false;
  };

  const projectTypeColor = (projType: ProjectTypeName | null) => {
    switch (projType) {
      case ProjectTypeName.accepted:
        return '#00BFFF';
      case ProjectTypeName.inProgress:
        return '#0A8A00';
      case ProjectTypeName.closed:
        return '#767676';
      case ProjectTypeName.draft:
        return '#E00751';
      case ProjectTypeName.received:
        return '#0058A3';
      case ProjectTypeName.sent:
        return '#F26A2F';
      default:
        return '';
    }
  };

  return (
    <Card
      key={cardKey}
      className={`dbCardStyle
         ${customClass}
          ${shouldHighlight() ? 'highlightCard' : null}
          ${reduceSize === true ? 'dbCardHeight' : null}`}
      onClick={onClick}
    >
      <div className="dashboardImagesContainer">
        {images?.length > 0 ? (
          <div className="dashboardImagesWrapper">
            { !isNullEmptyOrUndefined(images[0])
              ? <img src={images[0]} alt="dbImgOne" className="dbImage" />
              : (
                <div className="dashboardDefaultImageWrapper">
                  <SSRIcon className="dbImage dbIcNoImage" paths={IcNoImage} />
                </div>
              )}
            <Divider type="vertical" className="dashboardImageDivider" />
            { !isNullEmptyOrUndefined(images[1])
              ? <img src={images[1]} alt="dbImgTwo" className="dbImage" />
              : (
                <div className="dashboardDefaultImageWrapper">
                  <SSRIcon className="dbImage dbIcNoImage" paths={IcNoImage} />
                </div>
              )}
          </div>
        ) : (
          <div className="dashboardDefaultImageWrapper">
            <SSRIcon className="dbImage dbIcNoImage" paths={IcNoImage} />
          </div>
        )}
        {items !== false && (<Text className="dbCardItems dbFourteenBold dbCardSmallRow">{`Items: ${items}`}</Text>)}
      </div>
      {allProjects === true && projectType ? (
        <Text className="dbFourteenBold dbCardSmallRow" style={{ color: projectTypeColor(fetchProjectType(projectType)) }}>
          {fetchProjectType(projectType)}
        </Text>
      ) : null}
      {name !== false && (
        <div className="dbItemName dbCardRow">{name}</div>
      )}
      {startDate !== false && endDate !== false ? (
        <div className="dbItemContent dbCardRow">{`${startDate} - ${endDate}`}</div>
      ) : null}
      {(allProjects !== true && (name && name.includes('IoS')) && (isRu() === true && hasIosParent === true)) ? (
        <div className="dbCardRow">
          <span className="dbItemTitle">IoS End Date: </span>
          <span className="dbItemContent">{iosEndDate}</span>
        </div>
      ) : null}
      {hfb !== false ? (
        <div className="dbCardRow">
          <span className="dbItemTitle">HFB: </span>
          <span className="dbItemContent dbItemContentWidth">{hfb}</span>
        </div>
      ) : null}
      {suAccepted !== false ? (
        <div className="dbCardRow">
          <span className="dbItemTitle">
            {isIos() ? 'RUs Accepted: ' : 'SUs Accepted: '}
          </span>
          <span className="dbItemContent">{suAccepted}</span>
        </div>
      ) : null}
    </Card>
  );
};

Cards.defaultProps = {
  allProjects: false,
  highlightDays: null,
  hasIosParent: false,
  projectType: undefined,
  customClass: null,
  reduceSize: false,
};

export default Cards;
