import { useTranslation } from 'react-i18next';
import i18n from '../configs/i18n';

export const languageSwitch = (event) => {
  i18n.changeLanguage(event.target.value);
  localStorage.setItem('lang', event.target.value);
};

export function useTranslate(item) {
  const { t } = useTranslation();
  return t(item);
}
