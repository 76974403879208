/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import RadioButton from '@ingka/radio-button';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './ItemAssembled.module.css';

interface ItemAssembledSectionProps {
  title: string;
  itemAssembled: boolean | undefined ;
  setitemAssembled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const ItemAssembledSection = ({
  title, itemAssembled, setitemAssembled,
}: ItemAssembledSectionProps) => (
  <div className={styles.sectionWrapper}>
    <SectionTitle sectionTitle={useTranslate('inst.comp.itemAsembleTitle')} />
    <div className={styles.radioButtonWrapper}>
      <RadioButton
        className="radioGap"
        id={`item${title}Yes`}
        name={`item${title}radio`}
        key={`item${title}Yes`}
        value="true"
        label="Yes"
        defaultChecked={itemAssembled === true}
        onChange={(e: any) => setitemAssembled(e.target.value === 'true')}
      />
      <RadioButton
        id={`item${title}No`}
        name={`item${title}radio`}
        key={`item${title}No`}
        value="false"
        label="No"
        defaultChecked={itemAssembled === false}
        onChange={(e: any) => setitemAssembled(e.target.value === 'true')}
      />
    </div>
  </div>
);

export default ItemAssembledSection;
