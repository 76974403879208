/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';
import { Progress } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAuth0 } from '@auth0/auth0-react';
import AppBarMobile from '../../../common/components/appBarMobile/AppBarMobile';
import Cards from '../../../common/components/cardMobile/CardMobile';
import { offlineData } from '../../../common/configs/offlineData';
import {
  appRoutes, justNow, networkId, UserSelectionType,
} from '../../../common/constants/constant';
import useCallApi from '../../../common/services/apiService.js';
import {
  fetchCompetitorData,
  fetchHFBData, fetchLookupEnums,
  fetchMobileProjects, getSellingUnitsData,
} from '../../../common/services/commonApi.js';
import {
  isIos, isNullEmptyOrUndefined, isSu, removeLogoutOfflineData, setLogoutOfflineData, userData,
} from '../../../common/services/commonService.js';
import { useTranslate } from '../../../common/services/translationService.js';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetProgress, setProgress, setSyncFlag } from '../../../store/reducers/dashboard-reducer';
import styles from './DashboardMobile.module.css';
import './DashboardMobile.css';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';

const DashboardMobile = () => {
  dayjs.extend(relativeTime);
  const [allProjects, setAllProjects] = useState<any>([]);
  const [lastUpdated, setLastUpdated] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [highlightDays, setHighlightDays] = useState<number | null>(null);
  const progressPercent = useAppSelector((state) => state?.dashboardReducer?.progressPercent);
  const { get, post } = useCallApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const isEdit = location?.state || false;
  const {
    isAuthenticated, user, loginWithRedirect,
  } = useAuth0();
  const instantComparison = useTranslate('button.instantComparison');

  const setRetailUnitsData = (data:any) => {
    offlineData.setItem('retailUnits', data);
    offlineData.getItem('userDetails').then((userDetails: any) => {
      const userRetailUnit = userDetails?.isOfUserType === 2
        ? userDetails?.units : data?.filter((val:any) => userDetails?.units?.includes(val.ruCode));
      offlineData.setItem('userRetailUnits', userRetailUnit);
    });
  };

  const getUserDetails = (ruList:[]) => {
    const userDetails = {
      userid: user ? user[networkId] : '',
    };
    post('UserContext', userDetails, (userDet: UserSelectionType) => {
      dispatch(setProgress());
      localStorage.setItem('userData', JSON.stringify(userDet[0]));
      offlineData.setItem('userDetails', userDet[0]).then(() => {
        setRetailUnitsData(ruList);
      });
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: 'Failed to fetch the user details' }));
    });
  };

  const fetchRetailUnits = () => {
    get('retail-units', (ruList:[]) => {
      offlineData.setItem('retailUnits', ruList);
      getUserDetails(ruList);
      dispatch(setProgress());
    }, () => isMobile && dispatch(setToast({ openToast: true, toastLabel: 'Failed to fetch the retail units' })));
  };

  const onSyncClick = () => {
    if (!window.navigator.onLine) {
      dispatch(setToast({ openToast: true, toastLabel: 'application.internet.alert' }));
    } else if (isAuthenticated === false) {
      loginWithRedirect();
    } else {
      dispatch(setSyncFlag(true));
      dispatch(resetProgress());
      fetchRetailUnits();
      fetchHFBData(get);
      getSellingUnitsData(get);
      fetchLookupEnums(get);
      fetchCompetitorData(post);
      offlineData.getItem('userDetails').then((userDat: any) => {
        userDat?.isOfUserType !== 0 && fetchMobileProjects(post, setAllProjects);
      });
      offlineData.setItem('syncTime', dayjs().toString());
    }
  };

  useEffect(() => {
    window.addEventListener('storage', () => {
      const daysToHighlight = localStorage.getItem('highlightDays');
      setHighlightDays(daysToHighlight ? parseInt(daysToHighlight, 10) : null);
    });
  });

  const updateSyncTime = () => {
    offlineData.getItem('syncTime').then((val:any) => {
      setLastUpdated(val ? dayjs(val).fromNow() : '');
    });
  };

  useEffect(() => {
    if (window.performance.navigation.type !== performance.navigation.TYPE_RELOAD && isMobile) {
      dispatch(setLoader(true));
      if (user && userData()?.userId !== user[networkId] && localStorage.getItem('loggedInOnce') === 'true') {
        const outboxCount = localStorage?.getItem('outboxCount') || '0';
        removeLogoutOfflineData();
        setTimeout(() => {
          setLogoutOfflineData();
          localStorage.setItem('outboxCount', outboxCount);
          navigate(appRoutes.home);
        }, 500);
      } else if (isAuthenticated === true) {
        dispatch(setLoader(false));
        localStorage.setItem('isSignedOut', 'false');
      } else if (isAuthenticated === false && localStorage.getItem('isSignedOut') === 'true') {
        loginWithRedirect();
      } else dispatch(setLoader(false));
    }
    localStorage.setItem('loggedInOnce', 'true');
    localStorage.setItem('loggedOut', 'false');
    updateSyncTime();
    if (isEdit === true) { onSyncClick(); } else {
      offlineData.getItem('projectData').then((val:any) => {
        if (isNullEmptyOrUndefined(val) && val?.length !== 0) {
          offlineData.getItem('userDetails').then((userDetails: any) => {
            userDetails?.isOfUserType !== 0 && fetchMobileProjects(post, setAllProjects, true);
          });
        } else { setAllProjects(val); }
      });
    }
    if (isIos()) {
      offlineData.getItem('enumData').then((enumList: any) => {
        isNullEmptyOrUndefined(enumList) === true && fetchLookupEnums(get);
      });
      offlineData.getItem('competitorsList').then((compList: any) => {
        isNullEmptyOrUndefined(compList) === true && fetchCompetitorData(post);
      });
    }
    const daysToHighlight = localStorage.getItem('highlightDays');
    setHighlightDays(daysToHighlight ? parseInt(daysToHighlight, 10) : null);
  }, []);

  const cardClick = (selectedProject: any) => {
    offlineData.setItem('selectedProject', selectedProject);
    navigate(appRoutes.projDetails);
  };

  useEffect(() => {
    if (progressPercent > 1 && progressPercent < 100) {
      setShowProgress(true);
    } else if (progressPercent >= 100) {
      dispatch(setSyncFlag(false));
      setTimeout(() => {
        updateSyncTime();
        dispatch(resetProgress());
        setShowProgress(false);
      }, 500);
    }
  }, [progressPercent]);

  useEffect(() => {
    offlineData.getItem('comparisonList').then((compList:any) => {
      if (!isNullEmptyOrUndefined(allProjects) && !isNullEmptyOrUndefined(compList)) {
        allProjects.map((project:any) => {
          compList?.map((list:any) => {
            if (project.projectId === list.ProjectId) {
              const projDetails = isSu() ? project?.suProjectDetails?.sellingUnitProjectItemViewDto
                : project?.projectDetails?.viewCProjectItems;
              projDetails?.map((items:any) => {
                if (items?.itemNo === list?.ItemNo) {
                  if (list?.NoCompetition === 1) {
                    list.item.itemInfos.deadlineDate = items?.itemInfos?.deadlineDate;
                  } else {
                    list.ItemDetails.deadlineDate = items?.itemInfos?.deadlineDate;
                  }
                }
                return null;
              });
            }
            return null;
          });
          return null;
        });
        offlineData.setItem('comparisonList', compList);
      }
    });
  }, [allProjects]);

  const tabs = [
    <Tab
      key="tab-1"
      text={`${useTranslate('tab.in_progress')} (${allProjects.length})`}
      tabPanelId="tab_1"
      className="dbmTabsWrapper"
    />,
  ];

  const tabPanels = [
    <TabPanel
      className={styles.dmTabPanel}
      key="tab-1"
      tabPanelId="tab_1"
    >
      {allProjects?.map((project: any) => (
        <Cards
          key={project.projectId}
          cardKey={project.projectId}
          cardId={project.projectId}
          customClass={styles.dmCard}
          hasIosParent={project?.projectDetails?.viewCProjectDetails?.hasParentProject || false}
          highlightDays={highlightDays}
          name={project.projectName}
          startDate={project.projectStartDate}
          endDate={project.projectEndDate}
          iosEndDate={isIos() ? false : project.parentProjectEndDate}
          hfb={project.hfbNos}
          items={project.itemCount}
          suAccepted={false}
          onClick={() => cardClick(project)}
        />
      ))}
    </TabPanel>,
  ];

  return (
    <div className={styles.dmParentContainer}>
      <AppBarMobile source="dashboard" />
      <div className={styles.dmDataContainer}>
        <div className={styles.dmSyncNowWrapper}>
          <div className={styles.dmSyncContentWrapper}>
            <div className={styles.dmTextWrapper}>
              <Text className={styles.dmTitle}>Last updated:&nbsp;</Text>
              <Text className={styles.dmSubtitle} data-testid="the_time">{lastUpdated?.replace(justNow, 'Just now')}</Text>
            </div>
            <Button
              type="tertiary"
              onClick={onSyncClick}
            >
              Download
            </Button>
          </div>
          {showProgress === true ? (
            <Progress percent={progressPercent} showInfo={false} />
          ) : null}
        </div>
        {!isIos() ? (
          <Tabs
            className={styles.dmTabsContainer}
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab="tab_1"
          />
        ) : null}
        {isIos() ? (
          <>
            <div className={styles.dmHeaderContainer} data-testid="dmHeaderContainer">
              <Text className={styles.dmHeaderText} data-testid="dmHeaderText">
                In-Store Competitor Monitoring Tool
              </Text>
            </div>
            <Button
              type="emphasised"
              className={styles.dbBtnIc}
              fluid
              text={instantComparison}
              onClick={() => navigate(appRoutes.IC, { state: { isInstant: true } })}
              data-testid="instant-comparison-button-mobile"
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardMobile;
