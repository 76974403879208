/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import Toast from '@ingka/toast';
import React from 'react';
import '@ingka/toast/dist/style.css';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setToast } from '../../../store/reducers/create-proj-reducer';
import { useTranslate } from '../../services/translationService.js';

const AppToast = () => {
  const toastData = useAppSelector((state) => state.createProjReducer.toastData);
  const dispatch = useAppDispatch();
  return (
    <Toast
      className="application-toast"
      text={<strong>{useTranslate(toastData.toastLabel)}</strong>}
      isOpen={toastData.openToast}
      onCloseRequest={() => { dispatch(setToast({ openToast: false })); }}
      ariaLabelCloseBtn="Dismiss notification"
    />
  );
};

export default AppToast;
