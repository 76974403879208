/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import SSRIcon from '@ingka/ssr-icon';
import FormField from '@ingka/form-field';
import React, { useEffect, useRef } from 'react';
import Text from '@ingka/text';
import { Select } from 'antd';
import NumberInput from '../../../../common/components/inputs/NumberInput';
import { isNullEmptyUndefinedOrZero } from '../../../../common/services/commonService.js';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionDescription, SectionTitle } from '../InstantComparison';
import QuantityInput from '../../../../common/components/inputs/QuantityInput';
import styles from './NumberOfPieces.module.css';
import { checkZeroRegex } from '../../../../common/constants/constant';

interface NumberOfPiecesProps {
  itemQuantity: number | undefined;
  setItemQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedUnit: string | undefined;
  unitList: any;
  isFoodHfb: boolean | undefined;
  isPriceUnit: boolean | undefined;
  isInstant: boolean | undefined;
  quantityError: boolean | undefined;
  setQuantityError: React.Dispatch<React.SetStateAction<boolean>>;
  onUnitSelect: (value: any, option: any) => void;
  onQuantityChange: (value: any) => void;
  piecesUnit: any;
  ikeaItemMetric: any;
  ikeaItemQuantity: any;
}

const NumberOfPieces = ({
  itemQuantity, setItemQuantity, selectedUnit, quantityError, setQuantityError, unitList,
  isFoodHfb, isPriceUnit, isInstant, onUnitSelect, onQuantityChange, piecesUnit, ikeaItemMetric,
  ikeaItemQuantity,
}: NumberOfPiecesProps) => {
  const scrollRef: any = useRef(null);
  const labelQuantity = useTranslate('inst.comp.quantity');
  const labelUnits = useTranslate('inst.comp.units');
  const onePieceTextLabel = useTranslate('inst.comp.onePieceText');
  const disableDropdownSelection = isInstant ? false : (!isFoodHfb || !isPriceUnit);
  // eslint-disable-next-line max-len
  const ikeaItemMetricText = unitList.filter((option: any) => option.key === ikeaItemMetric)[0]?.value;

  const onOnePieceClick = () => {
    setItemQuantity(1);
    setQuantityError(false);
  };

  const ScrollToBottom = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showIKEANoOfPieces = () => {
    if (!isInstant) {
      if (isFoodHfb && isPriceUnit) {
        return true;
      }
      if (!isFoodHfb) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => { ScrollToBottom(); }, []);

  const disableOnePiece = () => {
    if (itemQuantity && !isNullEmptyUndefinedOrZero(itemQuantity)
      && !checkZeroRegex.test(itemQuantity.toString())) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={useTranslate('inst.comp.numberofPiecesTitle')} />
      <SectionDescription sectionDescription={useTranslate('inst.comp.numberofPiecesDescription')} />
      {(selectedUnit === piecesUnit.key) ? (
        <FormField
          className={styles.ifNumberOfPiecesWrapper}
          shouldValidate={quantityError}
          validation={{
            id: 'item-quantity-error',
            msg: 'Decimals not allowed for pieces unit',
            type: 'error',
          }}
        >
          <NumberInput
            className={styles.ifNumberOfPiecesFood}
            id="numberOfPieces"
            label={labelQuantity}
            value={itemQuantity}
            charLimit={13}
            onChange={onQuantityChange}
            onClick={ScrollToBottom}
          />
        </FormField>
      ) : (
        <QuantityInput
          className={styles.ifNumberOfPiecesFood}
          id="numberOfPieces"
          label={labelQuantity}
          value={itemQuantity}
          charLimit={13}
          onChange={onQuantityChange}
          onClick={ScrollToBottom}
        />
      )}
      <label htmlFor="unitSelectionDropdown" className={styles.icUnitsDropdownTitle}>{labelUnits}</label>
      <Select<number, { value: string; children: any }>
        className={`${styles.icUnitSelect} ${disableDropdownSelection && styles.disabled}`}
        optionFilterProp="children"
        fieldNames={{
          label: 'value',
          value: 'key',
        }}
        id="unitSelectionDropdown"
        suffixIcon={<SSRIcon paths={IcDownArrow} className={`${disableDropdownSelection && styles.icUnitSelectDisabled}`} />}
        value={selectedUnit as any}
        options={unitList}
        disabled={disableDropdownSelection}
        onChange={(val, option) => onUnitSelect(val, option)}
      />
      {(showIKEANoOfPieces() && !isNullEmptyUndefinedOrZero(ikeaItemQuantity)) ? (
        <div className={styles.icIKEAPieces}>
          <Text className={styles.icIKEAPiecesText}>
            {useTranslate('inst.comp.ikeaPiecesText')}
            {' '}
            {ikeaItemQuantity}
            {' '}
            {selectedUnit === piecesUnit.key ? 'piece(s)' : ikeaItemMetricText}
          </Text>
        </div>
      ) : null }
      {!(selectedUnit !== piecesUnit.key) ? (
        <Button
          className={styles.btOnePiece}
          text={onePieceTextLabel}
          type="emphasised"
          onClick={onOnePieceClick}
          disabled={disableOnePiece()}
          small
        />
      ) : null}
      <div ref={scrollRef} />
    </div>
  );
};

export default NumberOfPieces;
