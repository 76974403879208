import Button from '@ingka/button';
import Modal, { Prompt, ModalHeader } from '@ingka/modal';
import { Checkbox } from 'antd';
import Text from '@ingka/text';
import React from 'react';
import './ItemConnectionWarning.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface ItemConnectionWarningProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: () => void;
}

const ItemConnectionWarning = ({
  isVisible, onClose, onSave,
}: ItemConnectionWarningProps) => {
  const onDontShowCheck = (e: CheckboxChangeEvent) => {
    localStorage.setItem('dontShowConnectedItemWarning', e.target.checked.toString());
  };

  return (
    <Modal
      handleCloseBtn={onClose}
      visible={isVisible}
      escapable={false}
    >
      <Prompt
        aria-labelledby="ItemConnectionWarningModal"
        title="Item connection required"
        titleId="ItemConnectionWarningModal Title"
        header={<ModalHeader ariaCloseTxt="Close prompt" />}
        footer={null}
        className="icwPrompt"
      >
        <Text className="icwText">
          Instant comparisons saved from mobile
          must be connected to the relevant IKEA item separately.
          Connection is done in ToCompete web,
          in the function Quality check.
        </Text>
        <Checkbox
          id="cbDontShow"
          name="cbDontShow"
          onChange={onDontShowCheck}
        >
          Got it, don’t show me again
        </Checkbox>
        <Button
          text="Save comparison"
          type="secondary"
          onClick={onSave}
          fluid
        />
      </Prompt>
    </Modal>
  );
};

export default ItemConnectionWarning;
