/* eslint-disable react/no-unescaped-entities */
import Button from '@ingka/button';
import Modal, { Prompt, ModalHeader } from '@ingka/modal';
import { Checkbox } from 'antd';
import Text from '@ingka/text';
import React from 'react';
import './KnowmoreDialog.css';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslate } from '../../../../common/services/translationService.js';

interface KnowMoreDialogProps {
    isVisible: boolean;
    onClose: () => void;
    onContinue: () => void;
}

const KnowMoreDialog = ({
  isVisible, onClose, onContinue,
}: KnowMoreDialogProps) => {
  const dontShowKnowMoreCheckBox = localStorage.getItem('dontShowKnowMoreCheckBox');
  const onDontShowCheck = (e: CheckboxChangeEvent) => {
    localStorage.setItem('dontShowKnowMoreCheckBox', e.target.checked.toString());
  };
  const checkboxContent = useTranslate('qualitycheck.knowMoreCheckboxContent');
  const continueTitle = useTranslate('common.text.continue');
  const gotitTitle = useTranslate('qualitycheck.knowMoreGotit');

  return (
    <Modal
      handleCloseBtn={onClose}
      visible={isVisible}
      escapable={false}
    >
      <Prompt
        aria-labelledby="KnowMoreWarningModal"
        title={useTranslate('qualitycheck.knowMoreTitle')}
        titleId="KnowMoreWarningModal Title"
        header={<ModalHeader ariaCloseTxt="Close prompt" />}
        footer={null}
        className="qcwPrompt"
      >
        <Text className="qcwText">
          {useTranslate('qualitycheck.knowMoreDescription')}
        </Text>
        { !dontShowKnowMoreCheckBox
        && (
        <Checkbox
          id="qcDontShow"
          name="qcDontShow"
          onChange={onDontShowCheck}
        >
          {checkboxContent}
        </Checkbox>
        )}
        <Button
          text={!dontShowKnowMoreCheckBox ? continueTitle : gotitTitle}
          type="secondary"
          onClick={onContinue}
          fluid
        />
      </Prompt>
    </Modal>
  );
};

export default KnowMoreDialog;
