/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import { Upload, UploadFile, UploadProps } from 'antd';
import IcDelete from '@ingka/ssr-icon/paths/trash-can';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import React from 'react';
import Text from '@ingka/text';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionDescription, SectionTitle } from '../InstantComparison';
import styles from './PhotoUpload.module.css';
import './PhotoUpload.css';
import { uploadFileTypes } from '../../../../common/constants/constant';
import { setToast } from '../../../../store/reducers/create-proj-reducer';
import { useAppDispatch } from '../../../../store/hooks';

interface PhotoUploadProps {
    fileList: UploadFile[];
    maliciousFileError: boolean;
    setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
    setMaliciousFileError: React.Dispatch<React.SetStateAction<boolean>>;
}

const PhotoUpload = ({
  fileList, maliciousFileError, setFileList, setMaliciousFileError,
}: PhotoUploadProps) => {
  const maliciousFileMsg = useTranslate('inst.comp.threatFile');
  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    setMaliciousFileError(false);
    const fList = newFileList.filter((val:any) => uploadFileTypes.includes(val.type));
    (fList.length > 3 ? setFileList(fList.slice(0, 3)) : setFileList(fList));
  };
  const fileUploadErr = useTranslate('fileUpload.format.error');
  const dispatch = useAppDispatch();
  const uploadProps: UploadProps = {
    listType: 'picture',
    showUploadList: {
      removeIcon: <SSRIcon className={styles.icDelete} paths={IcDelete} />,
    },
    maxCount: 3,
    className: 'upload-list-inline',
    multiple: true,
    accept: 'image/*',
    onPreview: () => { null; },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isFileSupported = uploadFileTypes.includes(file.type);
      if (!isFileSupported) {
        dispatch(setToast({ openToast: true, toastLabel: fileUploadErr }));
      } else {
        setFileList((fileLists) => [...fileLists, file]);
      }
      return false;
    },
    fileList,
    onChange: handleChange,
  };

  return (
    <div className={`${styles.sectionWrapper} photoParent`}>
      <SectionTitle sectionTitle={useTranslate('inst.comp.photoTitle')} />
      <SectionDescription sectionDescription={useTranslate('inst.comp.photoDescription')} />
      <Upload {...uploadProps} defaultFileList={fileList}>
        <Button
          className={styles.btPhoto}
          text="Add Photo"
          type="emphasised"
          small
          disabled={fileList?.length === 3}
        />
      </Upload>
      {maliciousFileError === true ? (
        <div className={styles.errorObject}>
          <div className={styles.icNotice}>
            <SSRIcon paths={IcNotice} />
          </div>
          <Text className={styles.errorMessage}>
            {maliciousFileMsg}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

export default PhotoUpload;
