/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Comparison } from '../../components/desktop/findComparison/comparisonList/ComparisonList';

// Define the initial state using that type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  compResults: [],
};

export const Reducer = createSlice({
  name: 'findCompReducer',
  initialState,
  reducers: {
    setCompResults: (state, action: PayloadAction<Comparison[]>) => {
      state.compResults = [...action.payload];
    },
  },
});

export const {
  setCompResults,
} = Reducer.actions;

export default Reducer.reducer;
