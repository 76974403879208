import React from 'react';
import './BtiComponent.css';

const BtiComponent = ({ ml }: {ml?: number}) => (
  <div className="icBti" style={{ marginLeft: `${ml}px`, marginTop: `${ml !== 0 ? 2 : 0}px` }}>BTI</div>
);

BtiComponent.defaultProps = {
  ml: 0,
};

export default BtiComponent;
