/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Cards from '../../../../common/components/card/Card';
import { ProjectType, commonColumns } from '../Dashboard';
import './NewProjectReceived.css';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import DashboardList from '../../../../common/components/list/List';
import { useAppSelector } from '../../../../store/hooks';

interface NewProjectReceivedProps {
  newProjectsReceived: any[],
  highlightDays: number | null,
  handleProjectClick: (value: any, type: ProjectType) => void,
}

const NewProjectReceived = ({
  newProjectsReceived,
  highlightDays,
  handleProjectClick,
}: NewProjectReceivedProps) => {
  const gridListFlag = useAppSelector((state) => state?.dashboardReducer?.gridListFlag);
  return (
    <div>
      {newProjectsReceived?.length > 0
        ? gridListFlag === true
          ? (
            <div className="projectsContentWrapper">
              {newProjectsReceived.map((project) => (
                <Cards
                  key={project.projectId}
                  cardKey={project.projectId}
                  name={project.projectName}
                  images={project?.itemImageUrl}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={false}
                  reduceSize
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  suAccepted={false}
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  highlightDays={highlightDays}
                  onClick={() => handleProjectClick(project, ProjectType.NEW)}
                />
              ))}
            </div>
          ) : (
            <DashboardList
              projectData={newProjectsReceived}
              columnData={commonColumns}
              onRowClick={handleProjectClick}
            />
          ) : null}
    </div>
  );
};

export default NewProjectReceived;
