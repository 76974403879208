/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import SSRIcon from '@ingka/ssr-icon';
import './NotificationPopup.css';
import React, { useEffect, useState } from 'react';
import Modal, { ModalBody, Theatre } from '@ingka/modal';
import IcCross from '@ingka/ssr-icon/paths/cross-circle';
import Button from '@ingka/button';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import NotifCards from '../notificationCard/NotificationCard';
import useCallApi from '../../services/apiService.js';
import { notificationUrls } from '../../services/commonService.js';

const NotificationPopup = ({
  isVisible, onCloseModal, data, loading, mediaToken, notifCenter,
}:{
  isVisible: boolean,
  data: any,
  onCloseModal: () => void,
  loading: boolean,
  mediaToken: string,
  notifCenter: () => void,
}) => {
  const { post, get } = useCallApi();
  const [notifData, setNotifData] = useState(data);

  useEffect(() => {
    setNotifData(data);
  }, [data]);

  const clearNotification = (rcNo: number) => {
    post(`${notificationUrls().notificationDismiss}${rcNo}`, null, () => {
      get(notificationUrls().notificationPopup, (res: any) => {
        const notifFilterData = res?.filter(
          (val: any) => !(val.isRepeating === false && val.isPopupRead === true),
        );
        notifFilterData?.length === 0 && onCloseModal();
        setNotifData(notifFilterData);
      });
    });
  };

  return (
    isVisible ? (
      <div className="notificationPopupWrapper">
        <Modal
          handleCloseBtn={onCloseModal}
          visible={isVisible}
          escapable={false}
        >
          <Theatre
            aria-labelledby="notificationModal"
            header={(
              <div className="notificationPopupFlex">
                <h2 className="notifTitle">You have a new notification</h2>
                <div className="notificationPopupClose">
                  <SSRIcon
                    paths={IcCross}
                    className="itemPointer"
                    onClick={() => onCloseModal()}
                  />
                </div>
              </div>
)}
          >
            <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}>

              <ModalBody style={{ overflow: notifData.length <= 1 ? 'none' : 'auto' }}>
                {notifData.length !== 0
                  ? notifData.map((val: any, i: number) => (
                    <NotifCards
                      title={val.title}
                      mediaUrl={val.contentType === 'No attachment' ? '' : val.contentURL}
                      description={val.description}
                      cardKey={i}
                      date={val.validTo}
                      mediaToken={mediaToken}
                      mediaType={val.contentType}
                      onClearClick={() => clearNotification(val.rcNo)}
                    />
                  )) : <div className="notifNoMsgWrap"><h3>No new notifications</h3></div>}
              </ModalBody>
            </Spin>
            <div className="spBottomWrapper">
              <Button
                href=""
                text="Notification history"
                type="secondary"
                className="notifButtonWrapper notifButtonPopupWrapper"
                onClick={() => notifCenter()}
                small
              />
            </div>
          </Theatre>
        </Modal>
      </div>
    ) : null
  );
};
export default NotificationPopup;
