import Button from '@ingka/button';
import Text from '@ingka/text';
import { Progress } from 'antd';
import './Pagination.css';
import React from 'react';
import { useTranslate } from '../../services/translationService.js';

const Pagination = ({
  count, totalCount, isLoading, showMoreContent,
}:{
    count: number;
    totalCount: number;
    isLoading?: boolean;
    showMoreContent: () => void
}) => {
  const percent = (count / totalCount) * 100;
  const paginationShowing = useTranslate('pagination.showing');
  const paginationOf = useTranslate('pagination.of');
  const paginationText = useTranslate('pagination.text');
  return (
    <div className="paginationWrapper">
      <Text className="paginationText">
        {`${paginationShowing} ${count} ${paginationOf} ${totalCount}`}
      </Text>
      <Progress percent={percent} showInfo={false} />
      <Button
        small
        className="btnShowMore"
        type="secondary"
        fluid
        loading={isLoading}
        text={paginationText}
        onClick={showMoreContent}
        disabled={count >= totalCount}
      />
    </div>
  );
};

Pagination.defaultProps = {
  isLoading: false,
};

export default Pagination;
