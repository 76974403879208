/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal, {
  ModalBody, ModalHeader, Sheets,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/accordion/dist/style.css';
import React, { useEffect, useState } from 'react';
import { Select, Space } from 'antd';
import styles from './ConnectCompetitorPanel.module.css';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import { useAppDispatch } from '../../../../../../store/hooks';
import { useTranslate } from '../../../../../../common/services/translationService.js';
import useCallApi from '../../../../../../common/services/apiService.js';
import { setLoader } from '../../../../../../store/reducers/create-proj-reducer';
import {
  isIos, isRu, isSu, projectType, userData, userRu,
} from '../../../../../../common/services/commonService.js';
import { COMP } from '../../../../findComparison/filters/Filters';

const ConnectCompetitorPanel = ({
  isVisible, selectedItemData, selectedCompetitors,
  projectId, selectedRU, selectedProjCompetitors,
  onClose, updateItem, onPageReLoad,
}: {
  isVisible: boolean,
  selectedItemData: any,
  selectedCompetitors: any[],
  projectId: any,
  selectedRU: any,
  selectedProjCompetitors: any[],
  onClose: (row: any) => void,
  updateItem: (itemVal: any, isEnabled: boolean) => void,
  onPageReLoad: () => void,
}) => {
  const dispatch = useAppDispatch();
  const addCompTitle = useTranslate('sendProject.addCompetitor');
  const addCompDesc = useTranslate('sendProject.addCompetitorSubText');
  const { post } = useCallApi();
  const [competitorList, setCompetitorsList] = useState<COMP[]>([]);
  const [competitorFullList, setCompetitorsFullList] = useState<COMP[]>([]);
  const [selectedCompetitorIds, setSelectedCompetitorIds] = useState<any[]>([]);

  const saveNoCompetition = (competitorId: any) => {
    dispatch(setLoader(true));
    const savePayload = {
      ProjectId: projectId,
      ItemNo: selectedItemData.itemNo,
      NoCompetition: 1,
      ProjectType: projectType(),
      UserId: userData()?.userId,
      UpdateUserId: userData()?.userId,
      UnitCode: selectedRU?.ruCode,
      CompetitorId: parseInt(competitorId, 10),
      PaNo: selectedItemData?.paNo,
      IsMobile: 0,
      IsInstantCompare: 0,
    };
    post('save-comparison', savePayload, () => {
      updateItem(selectedItemData, true);
      setSelectedCompetitorIds([...selectedCompetitorIds, competitorId]);
      dispatch(setLoader(false));
    }, () => {
      updateItem(selectedItemData, false);
      dispatch(setLoader(false));
    });
  };

  const fetchFilterValues = () => {
    dispatch(setLoader(true));
    const payload = {
      ProjectType: projectType(),
      UnitCode: isIos() ? null : userRu(),
    };
    post('Comparison-SearchFilter', payload, (data: any) => {
      setCompetitorsList(data.CP);
      setCompetitorsFullList(data.CP);
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const resetCompetitor = () => {
    setSelectedCompetitorIds([]);
    setCompetitorsList(competitorFullList);
  };

  const onPanelClose = (data: any) => {
    resetCompetitor();
    onClose(data);
    if (isRu() || isSu()) {
      onPageReLoad();
    }
  };

  useEffect(() => {
    setSelectedCompetitorIds(selectedCompetitors);
  }, [selectedCompetitors]);

  useEffect(() => {
    if (selectedProjCompetitors.length !== 0) {
      const projCompData = competitorFullList.filter(
        (val) => !selectedProjCompetitors.includes(val.id),
      );
      setCompetitorsList(projCompData);
    } else { setCompetitorsList(competitorFullList); }
  }, [selectedProjCompetitors]);

  useEffect(() => {
    fetchFilterValues();
  }, []);

  const setCompetitor = (name: any, option: any) => {
    const compId: any = [];
    const newComp = option.filter((x: any) => !selectedCompetitorIds.includes(x.id));
    newComp[0]?.id && saveNoCompetition(newComp[0].id);
    option.forEach((competitor: any) => compId.push(competitor.id));
    setSelectedCompetitorIds([...compId]);
  };

  const deleteCompetitor = (competitorId: any) => {
    const deletePayload = {
      ProjectId: projectId,
      ItemNo: selectedItemData.itemNo,
      CompetitorId: parseInt(competitorId, 10),
      CurrentUserId: userData()?.userId,
    };
    post('toggledelete-comparison', deletePayload, () => {
      updateItem(selectedItemData, false);
      dispatch(setLoader(false));
    }, () => {
      updateItem(selectedItemData, true);
      dispatch(setLoader(false));
    });
  };

  const removeCompetitor = (newList: any) => {
    setSelectedCompetitorIds(newList);
    const deletedComp = selectedCompetitorIds.filter((x: any) => !newList.includes(x));
    deleteCompetitor(deletedComp[0]);
  };

  return (
    <div>
      <Modal
        visible={isVisible}
        focusLockProps={{
          disabled: false,
        }}
        handleCloseBtn={() => onPanelClose(selectedItemData)}
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          footer={null}
          header={
            <ModalHeader ariaCloseTxt="Close button text" title={useTranslate('sendProject.noCompetition')} />
          }
        >
          <ModalBody>
            <div className={styles.sectionTitleWrapper}>
              <span className={`${styles.sectionTitle}`}>{addCompTitle}</span>
            </div>
            <div className={styles.sectionDescription}>{`${addCompDesc} ${selectedItemData?.itemName}`}</div>
            <div className="fcCompetitorListWrapper sendCompListWrap">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Select
                  mode="multiple"
                  fieldNames={{
                    label: 'text',
                    value: 'id',
                  }}
                  filterOption
                  optionFilterProp="text"
                  style={{ width: '100%' }}
                  value={selectedCompetitorIds}
                  placeholder={useTranslate('find.comp.searchCompetitor')}
                  onChange={(val: any, option: any) => setCompetitor(val, option)}
                  onDeselect={(val, option) => deleteCompetitor(option.id)}
                  options={competitorList}
                  maxTagCount="responsive"
                />
              </Space>
            </div>
            {selectedCompetitorIds?.length !== 0
              ? (
                <PillsToolbar
                  data={selectedCompetitorIds}
                  competitorList={competitorList}
                  onRemoveCompetitor={(newCompList: any) => {
                    removeCompetitor(newCompList);
                  }}
                />
              ) : null}
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  );
};

export default ConnectCompetitorPanel;
