/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Modal, {
  Sheets, ModalBody, ModalHeader,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/focus/dist/style.css';
import IcHam from '@ingka/ssr-icon/paths/menu';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import { useNavigate } from 'react-router-dom';
import Button from '@ingka/button';
import Text from '@ingka/text';
import SSRIcon from '@ingka/ssr-icon';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import classes from './HamburgerMobile.module.css';
import {
  env, isIos, isRu, isSu, userData,
} from '../../services/commonService.js';
import { offlineData } from '../../configs/offlineData';
import { RetailUnit } from '../../../components/desktop/createProject/retailUnitsTab/RetailUnits';
import { useTranslate } from '../../services/translationService.js';
import CloseModal from '../closeModal/CloseModal';
import { appRoutes } from '../../constants/constant';
import { setToast } from '../../../store/reducers/create-proj-reducer';

const HamburgerMobile = ({ unit }:{
  unit: string
}) => {
  const navigate = useNavigate();
  const [showHamburger, setShowHamburger] = useState(false);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [comparisonList, setComparisonList] = useState<any[]>([]);
  const [popUpEnable, setPopUpEnable] = useState<boolean>(false);
  const [selectedHfb, setSelectedHfb] = useState([]);
  const [selectedPath, setSelectedPath] = useState('');
  const outboxClearTitle = useTranslate('outbox.clear.modal_title');
  const outboxClearSubText = useTranslate('outbox.clear.modal_subtext');
  const outboxClearUpload = useTranslate('outbox.clear.modal_savetext');
  const logoutSubText = useTranslate('logout.comp.subText');
  const logoutContinue = useTranslate('logout.comp.saveText');

  const instantComparison = useTranslate('button.instantComparison');
  const logoutTitle = useTranslate('logout.title');
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const [openCloseSwitchModal, setOpenCloseSwitchModal] = useState<boolean>(false);
  const [openCloseLogoutModal, setOpenCloseLogoutModal] = useState<boolean>(false);

  const checkPopUpDisable = () => {
    let i = 0;
    const comparisons = comparisonList || [];
    if (comparisons?.length !== 0) {
      comparisons.map((val: any, x :number) => {
        if (isSu()) {
          if ((val.ProjectType === 2)) {
            i = ++i;
          }
        }
        if (isIos()) {
          if ((val.ProjectType === 0) && val.UserId === userData().userId) {
            i = ++i;
          }
        }
        if (isRu()) {
          if ((val.ProjectType !== 0)) {
            i = ++i;
          }
        }
        if (x + 1 === comparisons?.length) {
          if (i > 0) { setPopUpEnable(true); return true; }
          setPopUpEnable(false); return false;
        }
      });
    } else { setPopUpEnable(false); return false; }
  };

  useEffect(() => {
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      setSelectedRU(data);
    });
    offlineData.getItem('selectedHfbs').then((data: any) => {
      setSelectedHfb(data);
    });
    offlineData.getItem('comparisonList').then((data: any) => {
      setComparisonList(data);
    });
  }, []);

  const handleCloseModal = (type?: number) => {
    type === 1 && navigate(appRoutes.outbox, { state: selectedPath });
    setOpenCloseSwitchModal(false);
  };

  const handleCloseSwitchModal = (path: string) => {
    if (popUpEnable === true) {
      setOpenCloseSwitchModal(true);
    } else navigate(path || '', { state: { isEdit: true } });
  };

  useEffect(() => {
    offlineData.getItem('comparisonList').then((data: any) => {
      setComparisonList(data);
    });
  }, [showHamburger]);

  useEffect(() => {
    checkPopUpDisable();
  }, [comparisonList, showHamburger]);

  const onLogout = () => {
    sessionStorage.clear();
    localStorage.setItem('isSignedOut', 'true');
    logout({ returnTo: env().logoutUrl });
  };

  const signOut = () => {
    if (navigator.onLine) {
      const outboxCount = localStorage?.getItem('outboxCount') || '0';
      if (parseInt(outboxCount, 10) <= 0) {
        onLogout();
      } else if (popUpEnable === true) {
        setOpenCloseLogoutModal(true);
        setSelectedPath('signout');
      } else {
        onLogout();
      }
    } else dispatch(setToast({ openToast: true, toastLabel: 'application.internet.alert' }));
  };

  const handleCloseLogoutModal = (type?: number) => {
    if (type === 1) {
      onLogout();
    } else if (type === 2) navigate(appRoutes.outbox, { state: selectedPath });
    setOpenCloseLogoutModal(false);
  };

  const ruSelectionClick = () => {
    if (isRu() && userData().units.length > 1) {
      setSelectedPath(appRoutes.RUselect);
      handleCloseSwitchModal(appRoutes.RUselect);
    }
  };

  const hfbSelectionClick = () => {
    navigate(appRoutes.HFBselect, { state: { isEdit: true } });
    setSelectedPath(appRoutes.HFBselect);
  };

  return (
    <>
      {!showHamburger && (
        <Button
          className={classes.hamIcon}
          href=""
          iconOnly
          ssrIcon={IcHam}
          type="tertiary"
          onClick={() => setShowHamburger(!showHamburger)}
        />
      )}
      {showHamburger && (
        <div style={{ zIndex: '3' }}>
          <Modal
            visible={showHamburger}
            handleCloseBtn={() => setShowHamburger(!showHamburger)}
          >
            <Sheets
              className={classes.navSheet}
              alignment="left"
              header={<ModalHeader ariaCloseTxt="Close" title="" />}
              footer={null}
              fullSize
            >
              <ModalBody className={classes.modalBody}>
                {userData()?.userName ? (
                  <div className={classes.userInfoWrapper}>
                    <Text className={classes.tUserName}>{userData()?.userName}</Text>
                    {unit ? <Text className={classes.tUserInfo}>{unit}</Text> : null}
                    {isIos() ? <Text className={classes.tUserInfo}>Ikea of Sweden</Text> : null}
                  </div>
                ) : null}
                { isRu() && (
                <div
                  className={classes.sectionWrapper}
                  onClick={() => ruSelectionClick()}
                  onKeyUp={() => ruSelectionClick()}
                  role="button"
                  tabIndex={1}
                >
                  <div className={classes.textWrapper}>
                    <Text className={classes.tSectionTitle}>Retail Unit</Text>
                    {selectedRU ? (
                      <Text className={classes.tSectionSubtitle}>
                        {selectedRU?.ruName}
                      </Text>
                    ) : null}
                  </div>
                  <SSRIcon paths={IcRightArrow} />
                </div>
                )}
                {!isIos() ? (
                  <div
                    className={classes.sectionWrapper}
                    onClick={() => hfbSelectionClick()}
                    onKeyUp={() => hfbSelectionClick()}
                    role="button"
                    tabIndex={2}
                  >
                    <div className={classes.textWrapper}>
                      <Text className={classes.tSectionTitle}>My preferred HFB</Text>
                      {selectedHfb?.length > 0 ? (
                        <Text className={classes.tSectionSubtitle}>
                          {selectedHfb.join(', ')}
                        </Text>
                      ) : null}
                    </div>
                    <SSRIcon paths={IcRightArrow} />
                  </div>
                ) : null}
                <Button
                  className={classes.btnInstantComparison}
                  type="secondary"
                  fluid
                  text={instantComparison}
                  onClick={() => navigate(appRoutes.IC, { state: { isInstant: true } })}
                />
              </ModalBody>

              <Button
                href=""
                text={logoutTitle}
                type="secondary"
                className={`logoutButton ${classes.logoutBottomWrapper}`}
                onClick={() => { signOut(); }}
                small
              />

            </Sheets>
          </Modal>
          <CloseModal
            modelOpen={openCloseSwitchModal}
            closeModal={handleCloseModal}
            saveValid
            closeText={outboxClearUpload}
            title={outboxClearTitle}
            subText={outboxClearSubText}
          />
          <CloseModal
            modelOpen={openCloseLogoutModal}
            closeModal={handleCloseLogoutModal}
            saveValid
            closeText={logoutTitle}
            saveText={logoutContinue}
            title={outboxClearTitle}
            subText={logoutSubText}
          />
        </div>
      )}
    </>
  );
};

export default HamburgerMobile;
