/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Modal, {
  Sheets, ModalBody,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/focus/dist/style.css';
import './NotificationWidget.css';
import IcCross from '@ingka/ssr-icon/paths/cross-circle';
import SSRIcon from '@ingka/ssr-icon';
import Button from '@ingka/button';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import NotifCards from '../notificationCard/NotificationCard';
import useCallApi from '../../services/apiService.js';
import { notificationUrls } from '../../services/commonService.js';

const NotificationWidget = (props:any) => {
  const {
    openNotification, closeNotification, data, loading, mediaToken, notifCenter,
  } = props;
  const { post, get } = useCallApi();
  const [notifData, setNotifData] = useState(data);

  const clearNotification = (rcNo: number) => {
    post(`${notificationUrls().notificationDismiss}${rcNo}`, null, () => {
      get(notificationUrls().notificationSideBar, (res: any) => {
        setNotifData(res);
      });
    });
  };

  useEffect(() => {
    setNotifData(data);
  }, [data]);

  return (

    openNotification && (
    <div style={{ zIndex: '3' }}>
      <Modal
        visible={openNotification}
        handleCloseBtn={() => closeNotification()}
      >
        <Sheets
          alignment="right"
          header={(
            <div className="notificationWidgetFlex">
              <h2 className="notifTitle">Notifications</h2>
              <div className="notificationWidgetClose">
                <SSRIcon
                  paths={IcCross}
                  className="itemPointer"
                  onClick={() => closeNotification()}
                />
              </div>
            </div>
)}
          footer={(
            <div className="notifBottomWrapper">
              <Button
                href=""
                text="Notification history"
                type="secondary"
                className="logoutButton notifButtonWrapper"
                onClick={() => notifCenter()}
                small
              />
            </div>
)}
          className="notification-wrapper"
        >
          <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}>
            <ModalBody>
              {notifData.length !== 0
                ? notifData.map((val: any, i: number) => (
                  <NotifCards
                    title={val.title}
                    mediaUrl={val.contentType === 'No attachment' ? '' : val.contentURL}
                    description={val.description}
                    cardKey={i}
                    date={val.validTo}
                    mediaToken={mediaToken}
                    mediaType={val.contentType}
                    onClearClick={() => clearNotification(val.rcNo)}
                  />
                )) : <div className="notifNoMsgWrap"><h3>No new notifications</h3></div>}
            </ModalBody>
          </Spin>
        </Sheets>
      </Modal>
    </div>
    )

  );
};

export default NotificationWidget;
