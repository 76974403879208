/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import Button from '@ingka/button';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Modal, {
  ModalBody, ModalFooter, ModalHeader, Sheets,
} from '@ingka/modal';
import RadioButton from '@ingka/radio-button';
import Pill from '@ingka/pill';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcUpArrow from '@ingka/ssr-icon/paths/chevron-up';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import IcFilter from '@ingka/ssr-icon/paths/filters';
import {
  Menu, Checkbox, Popover, Calendar,
  TreeSelect, DatePickerProps, DatePicker, Select, Tree,
  Space, TableProps,
} from 'antd';
import {
  SorterResult, SortOrder,
} from 'antd/lib/table/interface';
import SSRIcon from '@ingka/ssr-icon';
import SearchData from '@ingka/search';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import jsdownload from 'js-file-download';
import { setLoader } from '../../../../store/reducers/create-proj-reducer';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import './Filters.css';
import styles from './Filters.module.css';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  isIos, projectType, userRu, isRu, isSu,
  userData, isNullEmptyOrUndefined, capitalizeFirstLetter,
} from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import { RetailUnit } from '../../createProject/retailUnitsTab/RetailUnits.js';
import {
  Comparison, UnitList, comparisonPayload, fectchUnitCode,
} from '../unitList/UnitList';
import { appRoutes } from '../../../../common/constants/constant';
import { setCompResults } from '../../../../store/reducers/find-comp-reducer';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import PageHeader from '../../../../common/components/header/Header';
import { getQualityCheckCount } from '../../../../common/services/commonApi.js';
import CloseModal from '../../../../common/components/closeModal/CloseModal';

export interface HFB {
  codeName: string,
  section: string,
  id: string,
  text: string,
  isExpanded: boolean,
  isChecked: boolean,
  items?: HFB[];
}

const { SHOW_PARENT } = TreeSelect;

export const HFBtree = ({
  projectData, isHFBOpened, dropdownClick, onCheckboxChange, defaultVal, customClass,
}: {
  projectData: HFB[],
  isHFBOpened: boolean,
  dropdownClick: (isOpen: boolean, type: string) => void,
  onCheckboxChange: (val: [], type: string) => void,
  defaultVal: [],
  customClass?: string,
}) => (
  <div className={`fcHfbFieldContainer ${customClass}`}>
    <TreeSelect
      treeCheckable
      treeData={projectData}
      showCheckedStrategy={SHOW_PARENT}
      fieldNames={{
        label: 'codeName',
        value: 'id',
        children: 'items',
      }}
      style={{
        width: '160px',
      }}
      showArrow
      value={defaultVal}
      suffixIcon={isHFBOpened ? <SSRIcon paths={IcUpArrow} /> : <SSRIcon paths={IcDownArrow} />}
      onDropdownVisibleChange={(val: any) => dropdownClick(val, 'hfb')}
      virtual={false}
      onChange={(val) => onCheckboxChange(val, 'hfb')}
      switcherIcon={<SSRIcon paths={IcRightArrow} />}
      popupClassName="fcHfbTree"
    />
    <span className="fcHfbPlaceholder">{useTranslate('tab.items.hfbPlaceholder')}</span>
  </div>
);

HFBtree.defaultProps = {
  customClass: '',
};

const Filters = () => {
  dayjs.extend(isSameOrBefore);
  const location: any = useLocation();
  const userDetails: any = {
    UnitCode: fectchUnitCode(),
    ProjectType: projectType(),
    CurrentUserId: userData()?.userId,
    IsSuperAdmin: userData()?.isSuperAdmin,
  };
  const searchData = { ...comparisonPayload, ...userDetails };
  const [isHFBOpened, setIsHFBOpened] = useState(false);
  const [isMyCustomNews, setIsMyCustomNews] = useState<boolean>(false);
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [openProjectNotFoundModal, setOpenProjectNotFoundModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Comparison[]>([]);
  const [searchPayload, setSearchPayload] = useState(searchData);
  const [selectedHFBs, setSelectedHFBs] = useState<[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const { post, fileDownload, get } = useCallApi();
  const navigate = useNavigate();
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    sortEntity: string, typeOfSort: SortOrder | undefined
  }>({ sortEntity: 'comparedOn', typeOfSort: 'descend' });
  const didMountRef = useRef(false);
  const [totalComparisonCount, setTotalComparisonCount] = useState<number>(0);
  const [paginationItemCount, setPaginationItemCount] = useState<number>(0);
  const [selectedAllHFBs, setSelectedAllHFBs] = useState<[]>([]);
  const [selectedAllPRAs, setSelectedAllPRAs] = useState<[]>([]);
  const [selectedAllPAs, setSelectedAllPAs] = useState<[]>([]);

  const [isDownload, setIsDownload] = useState(false);
  const [itemNo, setItemNo] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [itemName, setItemName] = useState<any>();
  const isComparisonCountClick = isNullEmptyOrUndefined(window.location?.search);
  const dispatch = useAppDispatch();
  const searchApiData: any = searchPayload;
  const urlParam = new URLSearchParams(window.location.search);
  const itemsTabItemNo: any = urlParam?.get('item');
  const comparisonProjectId: any = urlParam?.get('id');
  const [projectId, setProjectId] = useState<any>('');

  const getHFB = () => {
    // Fetch enumData from IndexedDB using offlineData
    offlineData.getItem('enumData').then((enumData: any) => {
      const hfbEntry = enumData?.find((item: any) => item.key === 'CUS_HFB_LIST');

      if (hfbEntry?.value) {
        const hfbIdsArray = hfbEntry.value.split('|');

        // Set filtered HFB data based on CUS_HFB_LIST
        get('business-structure', (businessStructureData: HFB[]) => {
          const filteredData = businessStructureData.filter((hfb) => hfbIdsArray.includes(hfb.id));
          setHFBdata(filteredData);
        });
      } else {
        // If no CUS_HFB_LIST found or the value is empty, fetch the full HFB data
        get('business-structure', setHFBdata);
      }
    });
  };

  useEffect(() => {
    getHFB();
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      setSelectedRU(data);
    });
  }, []);

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  const setHFBKeys = (filters: any) => {
    if (!isNullEmptyOrUndefined(filters?.HfbNos)) {
      return filters?.HfbNos;
    }
    if (!isNullEmptyOrUndefined(filters?.PraNos)) {
      return filters?.PraNos;
    }
    if (!isNullEmptyOrUndefined(filters?.PaNos)) {
      return filters?.PaNos;
    }
    return [];
  };

  const setSearchInput = (val: any) => {
    const searchInput:any = document?.getElementById('fcSearch');
    if (searchInput) {
      searchInput.value = val;
    }
    const event = new Event('input', { bubbles: true });
    searchInput?.dispatchEvent(event);
  };

  useEffect(() => {
    if (location.state?.isICDetails === true) {
      if (localStorage.getItem('selectedFCFilters')) {
        const filters = JSON.parse(localStorage.getItem('selectedFCFilters') as any);
        const comparsionChecked = !isNullEmptyOrUndefined(filters?.UserId);
        // Page Filters
        setSelectedHFBs(setHFBKeys(filters));
        setIsMyCustomNews(comparsionChecked);
        // All Filters
        setSelectedAllHFBs(filters?.HfbNos);
        setSelectedAllPRAs(filters?.PraNos);
        setSelectedAllPAs(filters?.PaNos);

        setProjectId(filters?.ProjectId);
        setItemNo(filters?.ItemNo);
        setItemName(filters?.ItemName);
        setSearchText(filters?.ItemNo || filters?.ItemName);
        !isNullEmptyOrUndefined(filters?.ItemNo) && setSearchInput(filters?.ItemNo);
        !isNullEmptyOrUndefined(filters?.ItemName) && setSearchInput(filters?.ItemName);
        // eslint-disable-next-line no-unsafe-optional-chaining
        const fcSortEntity = filters?.SortEntity?.charAt(0).toLowerCase() + filters?.SortEntity?.slice(1);
        setSortConfig({
          sortEntity: fcSortEntity,
          typeOfSort: filters?.TypeOfSort === 'desc' ? 'descend' : 'ascend',
        });
        setSearchInput(filters?.ItemNo);
        setSearchInput(filters?.ItemName);
        setSearchPayload(filters);
      }
    }
  }, []);

  // Comparison count click from Items tab and Project details screen
  useEffect(() => {
    if (!isComparisonCountClick) {
      if (!isNullEmptyOrUndefined(itemsTabItemNo) && !isNullEmptyOrUndefined(comparisonProjectId)) {
        const ProjectDetailsPayload = {
          ...searchData,
          ItemNo: itemsTabItemNo,
          ProjectId: comparisonProjectId,
        };
        setItemNo(itemsTabItemNo);
        setProjectId(comparisonProjectId);
        setPaginationItemCount(0);
        setSearchResults([]);
        setSearchPayload(ProjectDetailsPayload);
        setSearchInput(itemsTabItemNo);
        localStorage.removeItem('selectedFCFilters');
        localStorage.setItem('selectedFCFilters', JSON.stringify(ProjectDetailsPayload));
      } else {
        const itemsTabDate = dayjs().subtract(1, 'years').format('YYYY-MM-DD');
        const itemsTabPayload = {
          ...searchData,
          ItemNo: itemsTabItemNo,
          FromDate: itemsTabDate,
        };
        setItemNo(itemsTabItemNo);
        setPaginationItemCount(0);
        setSearchResults([]);
        setSearchPayload(itemsTabPayload);
        setSearchInput(itemsTabItemNo);
        localStorage.removeItem('selectedFCFilters');
        localStorage.setItem('selectedFCFilters', JSON.stringify(itemsTabPayload));
      }
    }
  }, []);

  const getComparisonCount = () => {
    if (JSON.stringify(searchPayload) !== JSON.stringify(searchData)) {
      post(
        'comparison-count',
        searchPayload,
        (response: any) => {
          setTotalComparisonCount(response.comparisonCount);
          toggleLoader(false);
        },
        (error: any) => {
          toggleLoader(false);
        },
      );
    } else {
      toggleLoader(false);
      setTotalComparisonCount(0);
    }
  };

  const setFilterShow = () => {
    const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(searchData);
    return checkFilterExist;
  };

  useEffect(() => {
    if (didMountRef.current) {
      const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(searchData);
      setHasFilter(setFilterShow());
      if (checkFilterExist) {
        // toggleLoader(true);
        // post(
        //   'search-comparison',
        //   searchPayload,
        //   (response: Comparison[]) => {
        //     setSearchResults([...searchResults, ...response]);
        //     dispatch(setCompResults(response));
        //     if (response.length > 0) {
        //       setIsDownload(true);
        //     }
        //     // restricted the count call
        //     if (!triggerShowmore) {
        //       getComparisonCount();
        //     } else {
        //       toggleLoader(false);
        //     }
        //   },
        //   (error: any) => {
        //     setSearchResults([]);
        //     dispatch(setCompResults([]));
        //     toggleLoader(false);
        //   },
        // );
      } else {
        setSearchResults([]);
        dispatch(setCompResults([]));
        toggleLoader(false);
      }
      setTriggerShowmore(false);
    }
    didMountRef.current = true;
  }, [searchPayload]);

  const downloadExcel = () => {
    dispatch(setLoader(true));
    fileDownload('export-comparisons', searchPayload, (response: any) => {
      jsdownload(response, 'Comparison-Details.xlsx');
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const handleTableChange: TableProps<Comparison>['onChange'] = (pagination, filters, sorter, extra) => {
    const sortEntity = ((sorter as SorterResult<Comparison>).field) as string;
    const sortTypeParam = (sorter as SorterResult<Comparison>).order;
    const typeOfSort = (!sortTypeParam || sortTypeParam === 'ascend') ? 'asc' : 'desc';
    setSortConfig({ sortEntity, typeOfSort: (sorter as SorterResult<Comparison>).order || 'ascend' });
    setPaginationItemCount(0);
    setSearchResults([]);
    const fcSortPayload = {
      ...searchPayload,
      SortEntity: capitalizeFirstLetter(sortEntity),
      TypeOfSort: typeOfSort,
      StartWith: 0,
    };
    setSearchPayload(fcSortPayload);
    localStorage.removeItem('selectedFCFilters');
    localStorage.setItem('selectedFCFilters', JSON.stringify(fcSortPayload));
  };

  const onSearchChange = (e: any, data: any) => {
    const searchTerm = data?.value || null;
    if (searchTerm === null) {
      setSearchResults([]);
      setItemNo(null);
      setItemName(null);
      setSearchText(null);
      const searchCriteria = { ItemName: '', ItemNo: '', StartWith: 0 };
      localStorage.removeItem('selectedFCFilters');
      localStorage.setItem('selectedFCFilters', JSON.stringify(searchPayload));
    } else {
      setSearchText(searchTerm);
      let searchCriteria = { ItemName: '', ItemNo: '', StartWith: 0 };
      const enteredInput = searchTerm
        .replace('.', '')
        .replace('.', '')
        .replace('-', '')
        .replace('-', '');

      if (isNaN(enteredInput) === false && enteredInput.length === 8) {
        setItemNo(searchTerm);
        searchCriteria = { ItemName: '', ItemNo: searchTerm.trim(), StartWith: 0 };
      } else {
        setItemName(searchTerm);
        searchCriteria = { ItemName: searchTerm.trim(), ItemNo: '', StartWith: 0 };
      }
      setPaginationItemCount(0);
      setSearchResults([]);
      localStorage.removeItem('selectedFCFilters');
      localStorage.setItem('selectedFCFilters', JSON.stringify(searchPayload));
    }
  };

  // Clear all Filters
  const clearAllFilter = () => {
    // page filter
    setSelectedHFBs([]);
    setIsMyCustomNews(false);
    setItemNo(null);
    setItemName(null);
    // sidebar fileters
    setSelectedAllHFBs([]);
    setSelectedAllPRAs([]);
    setSelectedAllPAs([]);
    setPaginationItemCount(0);
    setTotalComparisonCount(0);
    setSearchResults([]);
    dispatch(setCompResults([]));
    setIsFilterSelected(false);
    setIsDownload(false);
    setSortConfig({ sortEntity: 'comparedOn', typeOfSort: 'descend' });
    setSearchInput('');
    setSearchText('');
    setProjectId('');
    setSearchPayload(searchData);
  };

  const checkAllFilterPrerequistie = () => {
    if (
      !isNullEmptyOrUndefined(selectedHFBs)

      || !isNullEmptyOrUndefined(itemNo)
      || !isNullEmptyOrUndefined(itemName)
      || !(isMyCustomNews === false)
      || !isNullEmptyOrUndefined(selectedAllHFBs)
      || !isNullEmptyOrUndefined(selectedAllPRAs)
      || !isNullEmptyOrUndefined(selectedAllPAs)
    ) {
      return false;
    }
    return true;
  };

  const disableClearAndViewButton = () => {
    if (!isNullEmptyOrUndefined(searchResults)) {
      return false;
    }
    return checkAllFilterPrerequistie();
  };

  const getunitCode = () => {
    if (isRu()) {
      return selectedRU?.ruCode;
    }
    return null;
  };

  // Adding this logic antd Tree component sending all child values to chips
  const removeCommon = (first: any, second: any) => {
    const combine = [...first, ...second];
    return combine.filter((el) => !(first.includes(el) && second.includes(el)));
  };

  const setAllFilterHFBValues = () => {
    const isHfbChecked = selectedHFBs.some((hfb) => selectedAllHFBs.includes(hfb));
    if (!isNullEmptyOrUndefined(selectedAllHFBs) && isHfbChecked) {
      return selectedAllHFBs;
    }
    return null;
  };

  const setAllFilterPRAValues = () => {
    const isHfbChecked = selectedHFBs.some((hfb) => selectedAllHFBs.includes(hfb));
    if (!isNullEmptyOrUndefined(selectedAllHFBs) && isHfbChecked) {
      const praFilterData:any = [];
      selectedAllHFBs.forEach((hfb: any) => {
        HFBdata.forEach((hfbList: any) => {
          hfbList.items.forEach((pra: any) => {
            if (hfb === hfbList.id) {
              praFilterData.push(pra.id);
            }
          });
        });
      });
      const isPraChecked = selectedAllPRAs.some((pra) => praFilterData.includes(pra));
      if (selectedAllPRAs.length === 0) {
        return selectedAllPRAs;
      }
      if (!isPraChecked) {
        return selectedAllPRAs;
      }
      const selectedPraNos:any = removeCommon(selectedAllPRAs, praFilterData);
      setSelectedAllPRAs(selectedPraNos);
      return selectedPraNos;
    }
    return selectedAllPRAs;
  };

  const setAllFilterPAValues = () => {
    const paFilterData:any = [];
    if (!isNullEmptyOrUndefined(selectedAllPRAs)) {
      selectedAllPRAs.forEach((pra: any) => {
        selectedAllPAs.forEach((pa: any) => {
          if (pa.slice(0, 3) === pra) {
            paFilterData.push(pa);
          }
        });
      });
      if (selectedAllPAs.length === 0) {
        return selectedAllPAs;
      }
      const selectedPaNos:any = removeCommon(selectedAllPAs, paFilterData);
      setSelectedAllPAs(selectedPaNos);
      return selectedPaNos;
    }
    if (!isNullEmptyOrUndefined(selectedAllHFBs)) {
      selectedAllHFBs.forEach((hfb: any) => {
        HFBdata.forEach((hfbList: any) => {
          hfbList.items.forEach((pra: any) => {
            pra.items.forEach((pa: any) => {
              if (hfb === hfbList.id) {
                paFilterData.push(pa.id);
              }
            });
          });
        });
      });
      if (selectedAllPAs.length === 0) {
        return selectedAllPAs;
      }
      const selectedPaNos:any = removeCommon(selectedAllPAs, paFilterData);
      setSelectedAllPAs(selectedPaNos);
      return selectedPaNos;
    }
    return selectedAllPAs;
  };

  // Building a payload for search comparison
  const buildAllFilterPayload = (pageFilter: boolean) => {
    const payload = {
      ItemName: itemName || '',
      ItemNo: itemNo || '',
      HfbNos: setAllFilterHFBValues() || [],
      PraNos: setAllFilterPRAValues() || [],
      PaNos: setAllFilterPAValues() || [],
      UserId: isMyCustomNews === true ? userData()?.userId : null,
      UnitCode: getunitCode(),
      ProjectType: projectType(),
      CompetitorUnitCodes: [],
      StartWith: 0,
      TotalCount: 25,
      CurrentUserId: userData()?.userId || null,
      IsMobile: true,
      SortEntity: 'ComparedOn',
      TypeOfSort: 'desc',
      IsSuperAdmin: userData()?.isSuperAdmin,
      ProjectId: projectId,
    };
    setPaginationItemCount(0);
    setIsFilterSelected(false);
    const fcPayload = pageFilter === true ? searchApiData : payload;
    setHasFilter(setFilterShow());
    // if (JSON.stringify(searchPayload) !== JSON.stringify(fcPayload)) {
    setSearchPayload(fcPayload);
    // }
    setSortConfig({ sortEntity: 'comparedOn', typeOfSort: 'descend' });
    localStorage.removeItem('selectedFCFilters');
    localStorage.setItem('selectedFCFilters', JSON.stringify(fcPayload));
  };

  const triggerAllFilterPayload = () => {
    setSearchResults([]);
    if (checkAllFilterPrerequistie()) {
      clearAllFilter();
    } else {
      buildAllFilterPayload(false);
    }
  };

  // Page Filter Selection payload
  const onCheckboxChange = (selection: any, type: string) => {
    let HFBData: any = [];
    let PRAData: any = [];
    let PAData: any = [];
    let isComparison: any;
    searchApiData.UserId = null;

    switch (type) {
      case 'hfb':
        setSelectedHFBs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                HFBData.push(val);
                setSelectedAllHFBs(HFBData);
                break;
              case 3:
                PRAData.push(val);
                setSelectedAllPRAs(PRAData);
                break;
              case 4:
                PAData.push(val);
                setSelectedAllPAs(PAData);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          HFBData = []; PRAData = []; PAData = [];
          setSelectedHFBs([]);
          setSelectedAllHFBs([]);
          setSelectedAllPRAs([]);
          setSelectedAllPAs([]);
        }
        break;
      case 'myCustomNews':
        isComparison = selection;
        setIsMyCustomNews(isComparison);
        break;
      default:
        break;
    }
    searchApiData.UnitCode = getunitCode();
    searchApiData.ProjectType = projectType();
    searchApiData.CurrentUserId = userData()?.userId;
    searchApiData.IsSuperAdmin = userData()?.isSuperAdmin;
    searchApiData.StartWith = 0;
    searchApiData.TotalCount = 25;
    searchApiData.TypeOfSort = 'desc';
    searchApiData.SortEntity = 'ComparedOn';
    searchApiData.ProjectId = projectId;
    setSortConfig({ sortEntity: 'comparedOn', typeOfSort: 'descend' });

    // checking filters selection
    const pageFilterSelected = (!isNullEmptyOrUndefined(searchApiData.HfbNos)
    || !isNullEmptyOrUndefined(searchApiData.PraNos) || !isNullEmptyOrUndefined(searchApiData.PaNos)
    || !isNullEmptyOrUndefined(searchApiData.ItemNo) || !isNullEmptyOrUndefined(searchApiData.ItemName)
    || !isNullEmptyOrUndefined(searchApiData.UserId));
    const unCheckHfb = (!pageFilterSelected || isMyCustomNews);
    const unCheckComparsion = !pageFilterSelected && (!isNullEmptyOrUndefined(selectedHFBs));

    if (unCheckHfb) {
      if ((!unCheckComparsion && !pageFilterSelected) || (unCheckComparsion && !isMyCustomNews)) {
        searchApiData.UserId = null;
      } else if (!pageFilterSelected || unCheckComparsion || isMyCustomNews) {
        searchApiData.UserId = userData()?.userId;
      }
    }
    console.log(pageFilterSelected, unCheckHfb, unCheckComparsion, 'll');
    // check prerequiste
    // if ((!pageFilterSelected
    //   && (!unCheckHfb || !unCheckComparsion))) {
    //   disableClearAndViewButton();
    //   clearAllFilter();
    //   localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
    // } else {
    buildAllFilterPayload(true);
    // }
  };

  const dropdownClick = (isOpen: boolean, type: string) => {
    switch (type) {
      case 'hfb':
        setIsHFBOpened(isOpen);
        break;
      default:
        break;
    }
  };

  const myCustomNewsHandler = () => {
    if (isMyCustomNews === false) {
      setIsMyCustomNews(true);
      onCheckboxChange(true, 'myCustomNews');
    } else {
      setIsMyCustomNews(false);
      onCheckboxChange(false, 'myCustomNews');
    }
  };

  const filterHandler = () => {
    setIsFilterSelected(!isFilterSelected);
  };

  const getItemDetails = (project: any, record: any, comparison: any) => {
    const payload = {
      ProjectId: null,
      UnitCode: isIos() ? record?.ruCode : selectedRU?.ruCode,
      UserId: userData()?.userId,
      ItemNo: record.itemNo,
      ProjectType: projectType(),
    };
    post('comparisonitem-information', payload, (data: any) => {
      navigate(
        appRoutes.IC,
        {
          state: {
            comparisonId: record.comparisonProjectId,
            comparisonDetail: comparison,
            isInstantCompare: false,
            isFindCompare: true,
            projectItem: data,
            projectDetail: project,
            projectId: record.projectId,
            ruCode: record.ruCode,
            competitorName: record.competitorName,
            isEdit: project.isEdit,
            isDelete: project.isDelete,
            showProjDetails: project.isProjectDetailReq,
          },
        },
      );
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const getComarisonDetails = (project: any, record: any) => {
    const payload = {
      comparisonProjectId: record.comparisonProjectId,
    };
    post('view-comparison', payload, (data: any) => {
      if (!isNullEmptyOrUndefined(record.itemNo)) {
        getItemDetails(project, record, data);
      } else {
        navigate(
          appRoutes.IC,
          {
            state: {
              comparisonId: record.comparisonProjectId,
              comparisonDetail: data,
              isInstantCompare: false,
              isFindCompare: true,
              projectItem: data,
              projectDetail: project,
              projectId: record.projectId,
              ruCode: record.ruCode,
              competitorName: record.competitorName,
              isEdit: project.isEdit,
              isDelete: project.isDelete,
              showProjDetails: project.isProjectDetailReq,
            },
          },
        );
        dispatch(setLoader(false));
      }
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const getProjectDetails = (record: any) => {
    dispatch(setLoader(true));
    const payload = {
      IsSuperAdmin: userData()?.isSuperAdmin,
      comparisonId: record?.comparisonProjectId,
      CurrentUserProjectType: projectType(),
      CurrentUserId: userData()?.userId,
      ItemNo: record?.itemNo,
    };
    post('comp-project-detail', payload, (data: any) => {
      if (data?.isFound !== false) {
        getComarisonDetails(data, record);
      } else {
        dispatch(setLoader(false));
        setOpenProjectNotFoundModal(true);
      }
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const onComparisonClick = (record: any) => {
    getProjectDetails(record);
  };

  useEffect(() => {
    if (paginationItemCount > 0) {
      setSearchPayload({ ...searchPayload, StartWith: (paginationItemCount * 25) });
      // searchComparison({ ...searchPayload, StartsWith: (paginationItemCount * 25).toString() });
    }
  }, [paginationItemCount]);

  const showMoreContent = () => {
    setTriggerShowmore(true);
    setPaginationItemCount(paginationItemCount + 1);
  };

  const getHeaderHeight = () => {
    const height = document.getElementById('fcHeaderWrapperDiv')?.offsetHeight || 0;
    return height + 148;
  };

  useEffect(() => {
    const fcTablePagination = document.querySelector<HTMLElement>('.paginationWrapper');
    // table onload pagination hide
    if (fcTablePagination) {
      fcTablePagination.style.display = 'none';
    }
  }, [totalComparisonCount, searchResults]);

  useEffect(() => {
    if (isIos() || !isNullEmptyOrUndefined(userRu())) {
      getQualityCheckCount(post);
    }
  }, []);

  useEffect(() => {
    const fcTable = document.querySelector<HTMLElement>('.ant-table-body');
    if (fcTable) {
      fcTable.addEventListener('scroll', () => {
        // eslint-disable-next-line max-len
        const scrollPercentage = (fcTable.scrollTop / (fcTable.scrollHeight - fcTable.clientHeight)) * 100;
        if (scrollPercentage <= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
        }
        if (scrollPercentage >= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = '';
        }
      });
    }
  }, []);

  return (
    <div>
      <PageHeader
        isEnable={isDownload}
        title={useTranslate('customUnit.find.title')}
        buttonLabel=""
      />
      <div className="fcContentWrapper">
        <div className="fcHeaderWrapper" id="fcHeaderWrapperDiv">
          <div className="flexbox">
            <Space className="filters-container">
              {HFBdata.length > 0 && (
              <HFBtree
                projectData={HFBdata}
                isHFBOpened={isHFBOpened}
                dropdownClick={dropdownClick}
                onCheckboxChange={onCheckboxChange}
                defaultVal={selectedHFBs}
              />
              )}
              <Pill
                iconPosition="trailing"
                label={useTranslate('customUnit.pill.mycustomnews')}
                small
                selected={isMyCustomNews}
                onClick={myCustomNewsHandler}
                className="fcTitle"
              />
            </Space>
            <SearchData
              className="search_pill_fc"
              id="fcSearch"
              placeholder={useTranslate('customUnit.search.placeholder')}
              onSearch={onSearchChange}
              value={searchText}
              onChange={(e, data) => onSearchChange(e, data)}
              onClear={(e, data) => {
                onSearchChange(e, null);
                clearAllFilter();
              }}
            />
            <Button
              className="fcSearchBtn"
              type="primary"
              text="Search"
              small
              onClick={triggerAllFilterPayload}
            />
          </div>
          <hr className="fc-demo-divider__hr-horizontal" />
          {hasFilter === true ? (
            <PillsToolbar
              data={searchPayload}
              hfbData={selectedHFBs}
              setMyCustomNews={(isMyCustomNews: any) => {
                setIsMyCustomNews(isMyCustomNews);
              }}
              totalComparison={totalComparisonCount}
              onRemoveFilter={(newPayload, newHfbData) => {
                const HFBData: any = [];
                const PRAData: any = [];
                const PAData: any = [];
                setSelectedHFBs(newHfbData);
                newHfbData?.map((val: string) => {
                  switch (val.length) {
                    case 2:
                      HFBData.push(val);
                      setSelectedAllHFBs(HFBData);
                      break;
                    case 3:
                      PRAData.push(val);
                      setSelectedAllPRAs(PRAData);
                      break;
                    case 4:
                      PAData.push(val);
                      setSelectedAllPAs(PAData);
                      break;
                    default:
                      break;
                  }
                  return null;
                });
                setHasFilter(setFilterShow());
                setPaginationItemCount(0);
                setSearchResults([]);
                setIsDownload(false);
                setSearchPayload({ ...newPayload, StartWith: 0 });
                localStorage.setItem('selectedFCFilters', JSON.stringify({ ...newPayload, StartWith: 0 }));
                if (JSON.stringify(newPayload) === JSON.stringify(searchData)) {
                  localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
                  clearAllFilter();
                }
              }}
              onClearAll={() => {
                localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
                clearAllFilter();
                setHasFilter(false);
              }}
            />
          ) : null}
        </div>
        {/* <UnitList
          headerHeight={getHeaderHeight()}
          searchResults={searchResults}
          isLoading={isLoading}
          showMoreContent={showMoreContent}
          sortConfig={sortConfig}
          totalComparisonCount={totalComparisonCount}
          paginationItemCount={paginationItemCount}
          handleTableChange={handleTableChange}
          comparisonClick={onComparisonClick}
        /> */}
      </div>
      <CloseModal
        modelOpen={openProjectNotFoundModal}
        closeModal={() => {
          setOpenProjectNotFoundModal(false);
        }}
        saveValid
        closeText="Ok"
        saveText=""
        title={useTranslate('find.comp.compNotFound.title')}
        subText={useTranslate('find.comp.compNotFound.text')}
      />
    </div>
  );
};

export default Filters;
