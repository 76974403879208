/* eslint-disable no-unused-expressions */
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { authConfig } from '../configs/authConfig';
import { setLoader, setToast } from '../../store/reducers/create-proj-reducer';
import { env, isNullEmptyOrUndefined, notificationUrls } from './commonService.js';

function useCallApi() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();

  const fetchToken = (useCallback(async (url) => {
    const token = await getAccessTokenSilently({
      audience: (url.includes('orangeflower') || url.includes('notification')) ? notificationUrls().prodBaseUrl : env().audience,
    });
    return token;
  }, [getAccessTokenSilently]));

  const connect = async (method, apiUrl, payload, okCallback, errCallback) => {
    if (navigator.onLine) {
      axios({
        method,
        headers: {
          Authorization: `Bearer ${await fetchToken(apiUrl)}`,
        },
        baseURL: authConfig.backendBaseUrl,
        url: apiUrl,
        data: payload,
        responseType: 'json',
      }).then((response) => {
        if (response && response.status === 200 && response.data) {
          if (okCallback) okCallback(response.data);
        } else if (response && response.status === 401) {
          loginWithRedirect();
        } else if (errCallback) errCallback(response);
      }).catch((err) => {
        if (err?.response?.status === 401) {
          loginWithRedirect();
        } else if (errCallback) errCallback(err);
      });
    } else {
      dispatch(setLoader(false));
      !isMobile && dispatch(setToast({ openToast: true, toastLabel: 'application.internet.alert' }));
    }
  };

  const get = (apiUrl, okCallback, errCallback) => {
    connect('get', apiUrl, null, okCallback, errCallback);
  };

  const post = (apiUrl, payload, okCallback, errCallback) => {
    connect('post', apiUrl, payload, okCallback, errCallback);
  };

  const put = (apiUrl, payload, okCallback, errCallback) => {
    connect('put', apiUrl, payload, okCallback, errCallback);
  };

  const delet = (apiUrl, okCallback, errCallback) => {
    connect('delete', apiUrl, null, okCallback, errCallback);
  };

  const connectFileDownload = async (method, apiUrl, payload, okCallback, errCallback, token) => {
    axios({
      method,
      headers: {
        Authorization: `Bearer ${!isNullEmptyOrUndefined(token) ? token : await fetchToken(apiUrl)}`,
        'x-ms-version': '2020-10-02',
      },
      baseURL: authConfig.backendBaseUrl,
      url: apiUrl,
      data: payload,
      responseType: 'blob',
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        if (okCallback) okCallback(response.data);
      } else if (errCallback) errCallback(response);
    }).catch((err) => {
      if (errCallback) errCallback(err);
    });
  };

  const fileDownload = (apiUrl, payload, okCallback, errCallback) => {
    connectFileDownload('post', apiUrl, payload, okCallback, errCallback, null);
  };

  const mediaRender = (apiUrl, okCallback, errCallback, token) => {
    connectFileDownload('get', apiUrl, null, okCallback, errCallback, token);
  };

  const connectCompressedFile = async (method, apiUrl, payload, okCallback, errCallback) => {
    axios({
      method,
      headers: {
        Authorization: `Bearer ${await fetchToken(apiUrl)}`,
        ContentType: 'application/zip',
      },
      baseURL: authConfig.backendBaseUrl,
      url: apiUrl,
      data: payload,
      responseType: 'json',
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        if (okCallback) okCallback(response.data);
      } else if (errCallback) errCallback(response);
    }).catch((err) => {
      if (errCallback) errCallback(err);
    });
  };

  const postCompressedFile = (apiUrl, payload, okCallback, errCallback) => {
    connectCompressedFile('post', apiUrl, payload, okCallback, errCallback);
  };

  return {
    fetchToken, get, post, put, delet, fileDownload, mediaRender, postCompressedFile,
  };
}

export default useCallApi;
