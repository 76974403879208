/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { isMobile } from 'react-device-detect';
import * as ServiceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { authConfig } from './common/configs/authConfig';
import { appRoutes } from './common/constants/constant';
import './fonts/NotoIkeaLatin/NotoIKEALatin-Regular.ttf';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const Auth0ProviderWithRedirectCallback = ({ children, ...props }
  :{children: any,
    domain:string,
    clientId: string,
    audience:string,
    redirectUri:string}) => {
  const navigate = useNavigate();
  const onRedirectCallback = () => {
    navigate(appRoutes.home);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={authConfig.authUrl}
        clientId={authConfig.appClientId}
        audience={authConfig.audience}
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();

isMobile ? ServiceWorkerRegistration.register() : ServiceWorkerRegistration.unregister();
