/* eslint-disable @typescript-eslint/no-explicit-any */
import InputField from '@ingka/input-field';
import React, { useEffect, useRef } from 'react';
import { isIOS } from 'react-device-detect';

interface PriceInputProps {
    id: string;
    label: string;
    value: string | undefined;
    className: string;
    charLimit: number;
    currency: string | null;
    disabled?: boolean;
    priceFocus?: boolean;
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const PriceInput = ({
  id,
  label,
  value,
  className,
  charLimit,
  currency,
  disabled,
  priceFocus,
  onChange,
  onBlur,
}: PriceInputProps) => {
  const inputRef: any = useRef(null);

  useEffect(() => {
    if (priceFocus) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 600);
    }
  }, [priceFocus]);

  const onValueChange = (e: any) => {
    const updatedVal = e.target.value.replace(/^[^0-9.,;:]+$/, '');
    if (updatedVal.length <= charLimit) {
      const reDec = /^\d{1,9}[.]\d{0,2}$/;
      const reDec1 = /^[.]\d{0,2}$/;
      const reComma = /^\d{1,9}[,]\d{0,2}$/;
      const reColon = /^\d{1,9}[:]\d{0,2}$/;
      const reSemiColon = /^\d{1,9}[;]\d{0,2}$/;
      const reNumber = /^\d*$/;
      if (reDec.test(updatedVal)
          || reDec1.test(updatedVal)
          || reComma.test(updatedVal)
          || reColon.test(updatedVal)
          || reSemiColon.test(updatedVal)
          || reNumber.test(updatedVal)) {
        onChange(updatedVal);
      }
    }
  };

  return (
    <InputField
      autoFocus
      className={className}
      id={id}
      label={label}
      type={isIOS ? 'text' : 'number'}
      suffixLabel={currency ? (
        <div>
          {currency}
        </div>
      ) : undefined}
      value={value}
      disabled={disabled}
      autoComplete="off"
      onChange={onValueChange}
      onBlur={onBlur}
      ref={inputRef}
    />
  );
};

PriceInput.defaultProps = {
  disabled: false,
  priceFocus: false,
  onBlur: () => null,
};

export default PriceInput;
