import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: 'en', // default
    fallbackLng: 'en', // when specified language translations not present
    debug: false,
    backend: {
      loadPath: '{{ns}}/{{lng}}.json',
    },
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
