/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Button from '@ingka/button';
import IcLinkOut from '@ingka/ssr-icon/paths/link-out';
import IcImage from '@ingka/ssr-icon/paths/image';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import styles from './HoverDialog.module.css';
import { useTranslate } from '../../../../common/services/translationService.js';

const HoverDialog = (props: any) => {
  const { piaDetails, customClass, customHoverClass } = props;
  const hoverDialogTitle = useTranslate('create.proj.hoverdialog_title');

  const navigateToPIA = (link: string) => {
    window.open(link, '_blank');
  };

  const renderCard = (item: any) => (
    <div className={`${styles.card} ${customHoverClass || ''}`}>
      <img
        className={styles.cardImage}
        src={item?.itemImage}
        alt={item?.itemEnglishName || item?.itemName}
      />
      <div className={styles.cardContent}>
        <Text
          className={styles.cardTitle}
          bodySize="m"
          tagName="span"
          headingSize="m"
        >
          {item?.itemEnglishName || item?.itemName}
        </Text>
        <Button
          className={styles.cardButton}
          small
          href=""
          iconPosition="trailing"
          ssrIcon={IcLinkOut}
          onClick={() => navigateToPIA(item?.piaFactsLink)}
          text={hoverDialogTitle}
        />
      </div>
    </div>
  );

  const renderIcons = () => (
    <div className={`${styles.iconWrapper} ${customClass || ''}`}>
      <SSRIcon paths={IcImage} />
      {renderCard(piaDetails)}
    </div>
  );

  return (
    <div>
      {renderIcons()}
    </div>
  );
};

export default HoverDialog;
