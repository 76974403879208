/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import React, { useState } from 'react';
import IcBackArrow from '@ingka/ssr-icon/paths/arrow-left';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down-small';
import styles from './TopNavigation.module.css';
import AppBarIcons from '../../../../common/components/appBarIcons/AppBarIcons';
import ItemDetailsMobile from '../../projectsMobile/itemDetailsMobile/ItemDetailsMobile';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';

interface TopNavigationBarProps {
  isInstantComparison: boolean;
  onPreviousClick: () => void;
  handleCloseModal: () => void;
  selectedItem: any;
}

const TopNavigationBar = ({
  isInstantComparison, onPreviousClick, handleCloseModal, selectedItem,
}: TopNavigationBarProps) => {
  const [openItemModal, setOpenItemModal] = useState(false);
  const onCloseItemModal = () => setOpenItemModal(false);

  return (
    <>
      <header className={styles.headerContainer}>
        <Button
          className={styles.mobileBackButton}
          iconOnly
          small
          type="tertiary"
          onClick={onPreviousClick}
        >
          <SSRIcon paths={IcBackArrow} />
        </Button>
        {(isInstantComparison === false && !isNullEmptyOrUndefined(selectedItem)) ? (
          <Text className={styles.mobileConnect} onClick={() => setOpenItemModal(true)}>
            Show Item Details
            <SSRIcon className="icon-align" paths={IcDownArrow} />
          </Text>
        ) : (
          <Text className={styles.icMobilePageTitle}>
            Instant comparison
          </Text>
        )}
        <div className={styles.iconsWrapper}>
          <AppBarIcons closeFn={handleCloseModal} />
        </div>
      </header>
      <ItemDetailsMobile
        openItemModal={openItemModal}
        isInstantComparison={isInstantComparison}
        selectedItem={selectedItem}
        onCloseItemModal={onCloseItemModal}
      />
    </>

  );
};

export default TopNavigationBar;
