/* eslint-disable @typescript-eslint/no-explicit-any */
import Carousel from '@ingka/carousel';
import Modal, { ModalBody, ModalHeader, Theatre } from '@ingka/modal';
import './ImagePreviewModal.css';
import React, { useState, useEffect } from 'react';
import IcDownload from '@ingka/ssr-icon/paths/arrow-down-to-base';
import Button from '@ingka/button';
import ZoomImage from '@ingka/zoom-image';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';

interface ImagePreviewModalProps {
  previewImage: any;
  imageList: any[];
  imgPreviewOpen: boolean;
  onCloseItemModal: () => void;
}

const ImagePreviewModal = ({
  previewImage, imageList, imgPreviewOpen, onCloseItemModal,
}: ImagePreviewModalProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let index = 0;
    if (imageList.length > 1 && !isNullEmptyOrUndefined(previewImage)) {
      index = imageList.findIndex((item) => item === previewImage);
    }
    setSelectedIndex(index);
  }, [previewImage, imageList]);

  const onArrowClick = (direction: string) => {
    switch (direction) {
      case 'left':
        setSelectedIndex(selectedIndex - 1);
        break;
      case 'right':
        setSelectedIndex(selectedIndex + 1);
        break;
      default: break;
    }
  };

  const downloadImage = () => {
    const image = imageList[selectedIndex];
    if (!isNullEmptyOrUndefined(image)) {
      const a = document.createElement('a');
      a.href = image;
      a.download = `Image_${selectedIndex}.png`;
      a.click();
      setTimeout(() => {
        a?.parentNode?.removeChild(a);
      }, 10);
    }
  };

  return (
    <Modal
      visible={imgPreviewOpen}
      handleCloseBtn={() => onCloseItemModal()}
    >
      <Theatre
        aria-labelledby="icImageDetailsModal"
        className="icImageDetails-theatre"
        header={(
          <ModalHeader
            className="defaultFont"
            titleId="itemDetailModal"
            title="Image preview"
            floating={false}
          />
          )}
      >
        <ModalBody className="icImgPreviewModalBody">
          <Carousel
            id="slideShowInModal"
            slideIndex={selectedIndex}
            isSlideShow
            className="imgPreviewCarousel"
            onBtnLeftClick={() => onArrowClick('left')}
            onBtnRightClick={() => onArrowClick('right')}
          >
            {imageList.map((image: any, index: number) => (

              <ZoomImage
                src={image}
              >
                <img
                  alt={`icImgPreview${index}`}
                  className="icImgPreview"
                  src={image}
                />
              </ZoomImage>
            ))}
          </Carousel>
          <Button
            type="secondary"
            className="icImgPreviewDownload"
            text="Download"
            onClick={downloadImage}
            ssrIcon={IcDownload}
            small
          />
        </ModalBody>
      </Theatre>
    </Modal>
  );
};

export default ImagePreviewModal;
