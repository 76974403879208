/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from 'antd';
import './List.css';
import { ProjectType, projectTypeVal } from '../../../components/desktop/dashboard/Dashboard';

interface DashboardListProps {
  projectData: any[];
  columnData: any[];
  onRowClick: (prj: any, type: ProjectType) => void;
}

const DashboardList = ({
  projectData, columnData, onRowClick,
}: DashboardListProps) => (
  <Table
    className="table-striped-rows dashboardListRow"
    onRow={(record) => ({
      onClick: () => {
        onRowClick(record, projectTypeVal(record));
      },
    })}
    columns={columnData}
    dataSource={projectData}
    pagination={false}
    scroll={{ y: 'calc(100vh - 214px)' }}
    size="middle"
    rowKey={(record: any) => record.projectId}
    showHeader={projectData.length > 0}
  />
);

DashboardList.defaultProps = {
};

export default DashboardList;
