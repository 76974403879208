import React from 'react';
import Modal, {
  ModalFooter, ModalHeader, Prompt,
} from '@ingka/modal';
import Button from '@ingka/button';
import { isMobile } from 'react-device-detect';
import styles from './CloseModal.module.css';

const CloseModal = ({
  modelOpen,
  closeModal,
  saveValid,
  closeText,
  saveText,
  title,
  subText,
}
    :{modelOpen:boolean,
      closeModal: (type?:number) => void,
      saveValid:boolean,
      closeText:string,
      saveText?:string,
      title:string,
      subText?:string}) => (
        <div>
          <Modal
            handleCloseBtn={closeModal}
            visible={modelOpen}
            escapable={false}
          >
            <Prompt
              aria-labelledby="Accessibility header for a modal"
              title={title}
              titleId="Accessibility header for a modal"
              header={<ModalHeader ariaCloseTxt="Close prompt" />}
              footer={(
                <ModalFooter className={isMobile ? styles.mobileModalFooter : styles.modalFooter}>
                  <Button
                    text={closeText}
                    onClick={() => closeModal(1)}
                    className={styles.modalFooterBtn}
                  />
                  {saveText !== '' && (
                  <Button
                    text={saveText}
                    type="primary"
                    disabled={!saveValid}
                    onClick={() => closeModal(2)}
                    className={styles.modalFooterBtn}
                  />
                  )}
                </ModalFooter>
            )}
            >
              <p className={isMobile ? styles.mobileTxtDesc : styles.txtDesc}>
                {subText}
              </p>
            </Prompt>
          </Modal>
        </div>
);

CloseModal.defaultProps = {
  subText: '',
  saveText: '',
};
export default CloseModal;
