/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Item } from '../../components/desktop/createProject/items/Items';
import { RetailUnit } from '../../components/desktop/createProject/retailUnitsTab/RetailUnits';
import { SellingUnit } from '../../components/desktop/createProject/sellingUnitsTab/SellingUnits';

// Define the initial state using that type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  createProjData: {
    detailsTab: {
      Name: '', StartDate: dayjs().format('YYYY-MM-DD'), EndDate: null, Message: '', AdditionalMsg: '', ProjectId: null,
    },
    itemsTab: { selectedItems: [] },
    ruTab: { selectedRu: [] },
    suTab: { selectedSu: [] },
    selectedSuItems: [],
  },
  prevProjData: {
    detailsTab: {
      Name: '', StartDate: dayjs().format('YYYY-MM-DD'), EndDate: null, Message: '', AdditionalMsg: '', ProjectId: null,
    },
    itemsTab: { selectedItems: [] },
    ruTab: { selectedRu: [] },
    suTab: { selectedSu: [] },
    selectedSuItems: [],
  },
  newItemSelected: [],
  sellingUnits: [],
  retailUnits: [],
  isLoading: false,
  toastData: { openToast: false, toastLabel: '' },
  saveValid: false,
  submitValid: false,
  peviewOpen: false,
  messageValid: true,
  addtMessageValid: true,
  nameValid: { status: true, message: '' },
  saveSubmitFlag: false,
  prevItemsData: [],
  currencyList: [],
  metricList: [],
  hfbList: [],
};

export const Reducer = createSlice({
  name: 'createProjReducer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createProj: (state, action: PayloadAction<[]>) => {
      state.createProjData = { ...action.payload };
    },
    prevProj: (state, action: PayloadAction<[]>) => {
      state.prevProjData = { ...action.payload };
    },
    resetProj: (state) => {
      state.createProjData = { ...initialState.createProjData };
      state.prevProjData = { ...initialState.createProjData };
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setToast: (state, action: PayloadAction<object>) => {
      state.toastData = { ...action.payload };
    },
    setSaveValid: (state, action: PayloadAction<boolean>) => {
      state.saveValid = action.payload;
    },
    setSubmitValid: (state, action: PayloadAction<boolean>) => {
      state.submitValid = action.payload;
    },
    setPreviewOpen: (state, action: PayloadAction<boolean>) => {
      state.previewOpen = action.payload;
    },
    setNameValid: (state, action: PayloadAction<object>) => {
      state.nameValid = { ...action.payload };
    },
    setMessageValid: (state, action: PayloadAction<boolean>) => {
      state.messageValid = action.payload;
    },
    setAddtMessageValid: (state, action: PayloadAction<boolean>) => {
      state.addtMessageValid = action.payload;
    },
    prevItems: (state, action: PayloadAction<Item[]>) => {
      state.prevItemsData = [...action.payload];
    },
    setCurrencyList: (state, action: PayloadAction<Item[]>) => {
      state.currencyList = [...action.payload];
    },
    setMetricList: (state, action: PayloadAction<[]>) => {
      state.metricList = [...action.payload];
    },
    setHfbList: (state, action: PayloadAction<Item[]>) => {
      state.hfbList = [...action.payload];
    },
    setNewItemSelected: (state, action: PayloadAction<Item[]>) => {
      state.newItemSelected = [...action.payload];
    },
    createProjFullReset: (state) => {
      state.createProjData = { ...initialState.createProjData };
      state.prevProjData = { ...initialState.createProjData };
      state.nameValid = { ...initialState.nameValid };
      state.previewOpen = false;
      state.saveValid = false;
      state.submitValid = false;
      state.messageValid = true;
      state.addtMessageValid = true;
    },
    setSaveSubmitFlag: (state, action: PayloadAction<boolean>) => {
      state.saveSubmitFlag = action.payload;
    },
    setSU: (state, action: PayloadAction<SellingUnit[]>) => {
      state.sellingUnits = [...action.payload];
    },
    setRU: (state, action: PayloadAction<RetailUnit[]>) => {
      state.retailUnits = [...action.payload];
    },
  },
});

export const {
  createProj,
  resetProj,
  setLoader,
  setToast,
  prevProj,
  setSaveValid,
  setSubmitValid,
  setPreviewOpen,
  setNameValid,
  prevItems,
  setCurrencyList,
  setMetricList,
  createProjFullReset,
  setSaveSubmitFlag,
  setHfbList,
  setSU,
  setRU,
  setMessageValid,
  setAddtMessageValid,
  setNewItemSelected,
} = Reducer.actions;

export default Reducer.reducer;
